import { makeAutoObservable } from "mobx";
import { PaginatedListShelf, LoaderShelf, AttributeShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export class Store {
	public ReportUserList: PaginatedListShelf<api.Report>;
	public loader = new LoaderShelf();
	public order = new AttributeShelf<api.ReportOrder | null>(null);
	public getAllReports = (page: number) => api.getReports(page, this.order.value);
	public commissionTableType = new AttributeShelf<api.CommissionTableType>(api.CommissionTableType.typeTwo);
	public id = new AttributeShelf<string>("");

	constructor() {
		makeAutoObservable(this);
		this.ReportUserList = new PaginatedListShelf(
			this.getAllReports,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}
	public ChangeTableType = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			await api.setUserCommissionTable(this.id.value, this.commissionTableType.value!);
			showSuccessToast(strings.common.successChangeType);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
