import React from "react";
import { observer } from "mobx-react-lite";
import { Button, HStack } from "@chakra-ui/react";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory } from "react-router-dom";

interface IProps{
	approve: (id: string, isApprove: boolean) => Promise<void>;
	refuse: (id: string, isApprove: boolean) => Promise<void>;
	id: string;
	isDisabled?: boolean;
}

export const ApproveOrRefuseButton: React.FC<IProps> = observer((props) => {
	const {
		approve,
		refuse,
		id,
		isDisabled,
	} = props;
	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;
	const history = useHistory();

	const approved = (idClient: string) =>{
		approve(idClient, true);
		dialog.closeDialog();
		history.goBack();
	};
	const refused = (idClient: string) => {
		refuse(idClient, false);
		dialog.closeDialog();
		history.goBack();
	};

	const openDialogApproveOrRefuse = (approveUser: boolean ) => {
		dialog.showDialog({
			title: modal.titleApproveOrRefuse,
			closeOnOverlayClick: true,
			description: modal.descriptionApproveOrRefuseUser(approveUser),
			buttons: [
				{
					title: modal.button.confirm,
					onPress: approveUser ? () => approved(id) : () => refused(id),
					buttonProps: { bg: "green.600" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					buttonProps: { bg: "red.500", _hover: { bgColor: "red.700" } },
				},
			],
		});
	};

	return (
		<HStack
			alignSelf="center"
		>
			<Button
				disabled={isDisabled}
				onClick={() => openDialogApproveOrRefuse(true)}
			>
				{strings.common.modal.button.approve}
			</Button>
			<Button
				onClick={() => openDialogApproveOrRefuse(false)}
				bg="red.500"
				_hover={{
					bgColor: "red.700",
				}}
			>
				{strings.common.modal.button.refuse}
			</Button>
		</HStack>
	);
});
