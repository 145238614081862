import React from "react";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";

import { Table, TableCellWithActionButtons } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";
import { Store } from "./store";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.pendingUsers.table;
	const modal = strings.common.modal;

	const store = useLocalObservable(() => new Store());

	const history = useHistory();
	const { dialog } = useGlobalStore();

	const onGoToDetailsPendingUser = (idPendingUser: string) => history.push(`/dashboard/adminMaster/pendingUsers/details/${idPendingUser}`);

	const onApproveOrRefuse = (id: string, isApprove: boolean) => {
		dialog.closeDialog();
		store.approveOrRefuseUser(id, isApprove);
	};

	const openDialog = (user: API.User) => {
		dialog.showDialog({
			title: modal.titleApproveOrRefuse,
			closeOnOverlayClick: true,
			description: modal.descriptionApproveOrRefuse(user.name),
			buttons: [
				{
					title: modal.button.approve,
					onPress: () => onApproveOrRefuse(user.id, true),
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.refuse,
					onPress: () => onApproveOrRefuse(user.id, false),
					buttonProps: { bg: "red.500", _hover: { bgColor: "red.700" } },
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onView={() => onGoToDetailsPendingUser(item.id)}
							onApprove={() => openDialog(item)}
						/>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<Td>{item.documentNumber}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
