import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	IconButton,
	Text,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";
import { CentralizedCard, DetailsWithdraw } from "~/components";

interface IParams {
	id: string;
}

const ApprovedWithdrawalDetails: React.FC = () => {
	const string = strings.common;
	const buttonBack = strings.actions.back;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoToTheWithdrawalApprovedList = () => history.goBack();

	return (
		<>
			<Flex justifyContent="center" flexDirection="column" alignItems="center">
				{store.fetchModelShelf.model.value && (
					<CentralizedCard
						title={{
							text: string.detailsTitle,
							helper: (
								<Tooltip label={strings.common.edit}>
									<IconButton
										variant="icon"
										aria-label="Voltar"
										size="lg"
									/>
								</Tooltip>
							),
						}}
						isLoading={store.fetchModelShelf.initialLoader.isLoading}
					>
						{store.fetchModelShelf.model.value &&
							<DetailsWithdraw
								withdraw={store.fetchModelShelf.fetchedModel}
							/>}
					</CentralizedCard>
				)}
				<Button
					onClick={onGoToTheWithdrawalApprovedList}
					w={{
						base: "50%",
						md: "15%",
					}}
					mb={5}
					variant="outline"
				>
					<ArrowBackIcon />
					<Text mx="auto">{buttonBack}</Text>
				</Button>
			</Flex>
		</>
	);
};

export default observer(ApprovedWithdrawalDetails);
