import { Flex, Switch } from "@chakra-ui/react";
import React from "react";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { Label } from "../Label";

interface IProps{
	onChangeUserBlockStatus: () => void;
	blockedStatus: boolean;
	user: api.User;
}

export const BlockComponent: React.FC<IProps> = (props) => {
	const { blockedStatus, user, onChangeUserBlockStatus } = props;
	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;

	const openBlockUserDialog = () => {
		dialog.showDialog({
			title: user.blockedAt ? modal.button.enable : modal.button.disable,
			closeOnOverlayClick: true,
			description: modal.descriptionBlock(user.name, user.blockedAt),
			buttons: [
				{
					title: user.blockedAt ? modal.button.enable : modal.button.disable,
					onPress: () => {
						onChangeUserBlockStatus();
						dialog.closeDialog();
					},
					buttonProps: { bg: user.blockedAt ? "red.500" : "green.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex alignItems="center" position="relative">
			<Label
				position="absolute"
				fontWeight="bold"
				top="5px"
				left="-5px"
			>
				{modal.titleBlock(blockedStatus)}
			</Label>
			<Switch
				isChecked={blockedStatus}
				ml="110px"
				onChange={openBlockUserDialog}
				size="lg"
			/>
		</Flex>
	);
};

