import { Flex, FormControl, FormLabel, Select, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import { IBankNameAndCode } from "~/resources/bankCodes";
import strings from "~/resources/strings";
import {
	EnumSelect,
	TextInput,
	Label,
} from "..";
import api from "../../resources/api";

type FieldType = "bankName" | "bankCode" | "agency" | "agencyDv" | "account" | "accountDv" | "pix" | "pixType" | "type" | "socialReason" | "cnpj";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
	type: {
		value: api.BankAccountType;
		setValue: (newValue: api.BankAccountType) => void;
	};
	pixType: {
		value: api.PixType | null;
		setValue: (newValue: api.PixType) => void;
	};
}

interface IProps {
	isLoading: boolean;
	formValues: FormValues;
	toggleBank: (string: string) => void;
	getBankNamesAndCodes: IBankNameAndCode[];
	radioValue: string;
	setRadioValue: (string: string) => void;
}

export const BankForm: React.FC<IProps> = observer((props) => {

	const { isLoading, formValues, toggleBank, getBankNamesAndCodes, radioValue, setRadioValue } = props;

	const commonStrings = strings.common;

	getBankNamesAndCodes.sort((a,b) => {
		const item = a.name.toUpperCase();
		const secondItem = b.name.toUpperCase();

		return item === secondItem ? 0 : item > secondItem ? 1 : -1;
	});

	const defaultIndex = getBankNamesAndCodes.find((element) => element.code === formValues.field("bankCode").value);

	return (
		<>
			<TextInput
				boxProps={{ w: "100%" }}
				labelText={commonStrings.fields.socialReason}
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("socialReason").error}
				{...formValues.field("socialReason")}
			/>
			<TextInput
				boxProps={{ w: "100%" }}
				labelText={commonStrings.fields.cnpj}
				type="text"
				isDisabled={isLoading}
				errorText={formValues.field("cnpj").error}
				{...formValues.field("cnpj")}
			/>
			<EnumSelect
				items={api.allValuesBankAccountType()}
				currentValue={formValues.type.value}
				label={commonStrings.fields.typeAccount}
				onChangeEnum={formValues.type.setValue}
				tranlateEnum={api.translateBankAccountType}
			/>
			<Flex
				flexDirection={{ base: "column", md: "row" }}
				justifyContent="space-between"
			>
				<FormControl isDisabled={isLoading}>
					<FormLabel>{commonStrings.fields.bankName}</FormLabel>
					<Select
						onChange={(e) => toggleBank(e.target.value)}
						defaultValue={formValues.field("bankCode").value || undefined}
						bg="gray.100"
						color="primary.800"
					>
						<option aria-selected="true" value="">{defaultIndex ? `${defaultIndex.code} - ${defaultIndex.name}` : ""}</option>
						{getBankNamesAndCodes.map((bank) => (
							<option aria-selected="true" key={bank.code} value={bank.code}>
								{commonStrings.fields.concatData(bank.code, bank.name)}
							</option>
						))}
					</Select>
				</FormControl>
			</Flex>
			<Flex
				flexDirection={{ base: "column", md: "row" }}
				justifyContent="space-between"
			>
				<TextInput
					boxProps={{ w: "100%" }}
					labelText={commonStrings.fields.account}
					type="text"
					w={{ base: "100%", md: "97%" }}
					mb={{
						base: 8,
						md:0,
					}}
					isDisabled={isLoading}
					errorText={formValues.field("account").error}
					{...formValues.field("account")}
				/>
				<TextInput
					boxProps={{ w: "100%" }}
					labelText={commonStrings.fields.accountDv}
					type="number"
					isDisabled={isLoading}
					errorText={formValues.field("accountDv").error}
					{...formValues.field("accountDv")}
				/>
			</Flex>
			<Flex
				flexDirection={{ base: "column", md: "row" }}
				justifyContent="space-between"
			>
				<TextInput
					boxProps={{ w: "100%" }}
					labelText={commonStrings.fields.agency}
					type="number"
					w={{ base: "100%", md: "97%" }}
					isDisabled={isLoading}
					errorText={formValues.field("agency").error}
					{...formValues.field("agency")}
					mb={{
						base: 8,
						md:0,
					}}
				/>
				<TextInput
					boxProps={{ w: "100%" }}
					labelText={commonStrings.fields.agencyDv}
					type="number"
					isDisabled={isLoading}
					errorText={formValues.field("agencyDv").error}
					{...formValues.field("agencyDv")}
				/>
			</Flex>
			<Label>{commonStrings.fields.pix}</Label>
			<RadioGroup onChange={setRadioValue} value={radioValue}>
				<Stack direction="row">
					<Radio value="yes">{commonStrings.yes}</Radio>
					<Radio value="no">{commonStrings.no}</Radio>
				</Stack>
			</RadioGroup>
			<EnumSelect
				disabled={radioValue === "no" ? true : false}
				items={api.allValuesPixType()}
				currentValue={formValues.pixType.value || ""}
				label={commonStrings.fields.pixType}
				onChangeEnum={formValues.pixType.setValue}
				tranlateEnum={api.translatePixType}
			/>
			<TextInput
				boxProps={{ w: "100%" }}
				labelText={commonStrings.fields.pix}
				type="text"
				isDisabled={radioValue === "no" ? true : false}
				errorText={formValues.field("pix").error}
				{...formValues.field("pix")}
				mb={{
					base: 8,
					md:0,
				}}
			/>
		</>
	);
});
