import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import CreateOrEdit from "./CreateOrEdit";

const AdminDepositAndApprovals: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/adminDepositAndApprovals" component={Table} />
		<Route path="/dashboard/adminMaster/adminDepositAndApprovals/edit/:id" component={CreateOrEdit} />
		<Route path="/dashboard/adminMaster/adminDepositAndApprovals/create" component={CreateOrEdit} />
	</Switch>
);
export default observer(AdminDepositAndApprovals);
