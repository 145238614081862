import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast, showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.UserDeleteRequest>;
	public loader = new LoaderShelf();
	public user = new AttributeShelf("");

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllPendingUserDeleteRequests,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
	public acceptDeleteUser = async(id: string) => {
		this.loader.tryStart();
		try {
			await api.acceptUserDeleteRequest(id);
			showSuccessToast(strings.deletionRequests.successDelete(this.user.value, true));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
	public refuseDeleteUser = async(id: string) => {
		this.loader.tryStart();
		try {
			await api.refuseUserDeleteRequest(id);
			showSuccessToast(strings.deletionRequests.successDelete(this.user.value, false));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
