import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import { onFetchError } from "~/resources/fetchError";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import { Errors } from "~/resources/errors";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.Deposit>;
	public loader = new LoaderShelf();
	public cpf = new AttributeShelf("");

	public getAllPendingDeposits = () => api.getAllPendingDeposits(this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllPendingDeposits,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

	public approveOrRefuseDeposit = async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			if (isApprove) {
				await api.approveDeposit(id, null);
			} else {
				await api.refuseDeposit(id);
			}
			await api.getOneDepositById(id);
			showSuccessToast(strings.pendingDeposits.table.successApproveOrRefuse(isApprove));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.paginetedListShelf.refresh();
			this.loader.end();
		}
	};
}
