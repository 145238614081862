import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";

import Store from "./store";

import { BankForm, CentralizedCard } from "~/components";

import strings from "~/resources/strings";
import { getBankNamesAndCodes } from "~/resources/bankCodes";

interface IParams {
	id: string;
}

const CreateOrEdit: React.FC = () => {

	const pageStrings = strings.bankAccounts.createOrEdit;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id || ""));

	const onSuccess = () => {
		history.goBack();
	};

	const onSubmit = () => {
		store.createOrEditBankAccount(onSuccess);
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title(!!id),
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={onSubmit}
				>
					{strings.common.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<BankForm
				formValues={{
					field: store.formShelf.field,
					type: store.type,
					pixType: store.pixType,
				}}
				isLoading={store.loader.isLoading}
				getBankNamesAndCodes={getBankNamesAndCodes()}
				toggleBank={store.toggleBank}
				radioValue={store.radioValue.value}
				setRadioValue={store.radioValue.setValue}
			/>

		</CentralizedCard>
	);
};

export default observer(CreateOrEdit);
