import React from "react";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex } from "@chakra-ui/react";
import Store from "./store";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { DatePicker } from "~/components/DatePicker";


const Create: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.poupanca.create;

	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;

	const history = useHistory();

	const store = useLocalObservable(() => new Store());

	const onSuccess = () => {
		history.goBack();
	};

	const openDialog = () => {
		dialog.showDialog({
			title: modal.titleCreatePoupanca,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: modal.button.confirm,
					onPress: () => onSubmit(),
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSubmit = () => {
		store.createPoupanca(onSuccess);
		dialog.closeDialog();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={openDialog}
				>
					{strings.common.confirm}
				</Button>
			)}
		>
			<Flex
				justifyContent="center"
				gap={5}
				flexDir="column"
			>
				<TextInput
					labelText={commonStrings.fields.percentage}
					labelProps={{ fontWeight: "bold" }}
					type="number"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("percentage").error}
					{...store.formShelf.field("percentage")}
				/>
				<DatePicker
					maxW={{
						base:"100%",
						sm: "60%",
						md:"40%",
					}}
					label={commonStrings.fields.createAt}
					selectedDate={store.date.value}
					onChangeDate={store.date.setValue}
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(Create);
