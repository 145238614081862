import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { useAuthAdminMasterRoute } from "~/hooks/useAuthAdminMasterRoute";
import AdminWithdraw from "./AdminWithdraw";
import AdminDepositAndApprovals from "./AdminDepositAndApprovals";
import Home from "./Home";
import AdminMasterUsers from "./AdminMasterTable";
import UsersTable from "./UsersTable";
import PendingDeposits from "./PendingDeposits";
import BankAccounts from "./BankAccounts";
import TableApprovedDepositsMaster from "./ApprovedDeposits";
import PendingUsers from "./PendingUsers";
import ApprovedWithdrawal from "./ApprovedWithdrawal";
import PendingWithdraws from "./PendingWithdraws";
import MarginUSD from "./MarginUSD";
import DeletionRequests from "./DeletionRequest";
import Quotas from "./Quotas";
import ProfitTable from "./ProfitTable";
import UserTransfer from "./UserTransfer";
import indicationTexts from "./indicationTexts";
import InvitedUsers from "./InvitedUsers";
import ReportIndicated from "./ReportIndicated";
import Poupanca from "./Poupanca";

const AdminMaster: React.FC = () => {
	useAuthAdminMasterRoute();

	return (
		<Switch>
			<Route exact path="/dashboard/adminMaster" component={Home} />
			<Route path="/dashboard/adminMaster/adminWithdraw" component={AdminWithdraw} />
			<Route path="/dashboard/adminMaster/adminDepositAndApprovals" component={AdminDepositAndApprovals} />
			<Route path="/dashboard/adminMaster/adminMasterUsers" component={AdminMasterUsers} />
			<Route path="/dashboard/adminMaster/users" component={UsersTable} />
			<Route path="/dashboard/adminMaster/pendingDeposits" component={PendingDeposits} />
			<Route path="/dashboard/adminMaster/bankAccounts" component={BankAccounts} />
			<Route path="/dashboard/adminMaster/approvedDeposits" component={TableApprovedDepositsMaster} />
			<Route path="/dashboard/adminMaster/pendingUsers" component={PendingUsers} />
			<Route path="/dashboard/adminMaster/approvedWithdrawal" component={ApprovedWithdrawal} />
			<Route path="/dashboard/adminMaster/pendingWithdraws" component={PendingWithdraws} />
			<Route path="/dashboard/adminMaster/marginUSD" component={MarginUSD} />
			<Route path="/dashboard/adminMaster/profit" component={ProfitTable} />
			<Route path="/dashboard/adminMaster/deletionRequests" component={DeletionRequests} />
			<Route path="/dashboard/adminMaster/quotas" component={Quotas} />
			<Route path="/dashboard/adminMaster/userTransfer" component={UserTransfer} />
			<Route path="/dashboard/adminMaster/indicationTexts" component={indicationTexts} />
			<Route path="/dashboard/adminMaster/invitedUsers" component={InvitedUsers} />
			<Route path="/dashboard/adminMaster/reportIndicated" component={ReportIndicated} />
			<Route path="/dashboard/adminMaster/poupanca" component={Poupanca} />
		</Switch>
	);
};

export default observer(AdminMaster);
