import React from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";

type FieldType = "name" | "email" | "documentNumber" | "password";

interface FormValues {
	field: (field: FieldType) => {
		name: FieldType;
		onChange: (element: {
			target: {
				value: string;
			};
		}) => void;
		value: string;
		error: string | null;
	};
}

interface IProps {
	title: string;
	isLoading: boolean;
	formValues: FormValues;
	hidePasswordField: boolean;
	submit: {
		onClick: () => void;
		text: string;
		isLoading: boolean;
	};
}

export const AdminForm: React.FC<IProps> = observer((props) => {
	const {
		title,
		isLoading,
		formValues,
		submit,
		hidePasswordField,
	} = props;
	const commonStrings = strings.common;

	return (
		<CentralizedCard
			title={{ text: title }}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={submit.isLoading}
					onClick={submit.onClick}
				>
					{submit.text}
				</Button>
			)}
		>
			<TextInput
				labelText={commonStrings.fields.name}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues?.field("name").error}
				{...formValues?.field("name")}
			/>
			<TextInput
				labelText={commonStrings.fields.cpf}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues?.field("documentNumber").error}
				{...formValues?.field("documentNumber")}
				mask="999.999.999-99"
			/>
			<TextInput
				labelText={commonStrings.fields.email}
				labelProps={{ fontWeight: "bold" }}
				type="text"
				isDisabled={isLoading}
				errorText={formValues?.field("email").error}
				{...formValues?.field("email")}
			/>
			{!hidePasswordField && (
				<TextInput
					labelText={commonStrings.fields.password}
					labelProps={{ fontWeight: "bold" }}
					type="password"
					isDisabled={isLoading}
					errorText={formValues?.field("password").error}
					{...formValues?.field("password")}
				/>)}
		</CentralizedCard>
	);
});
