import React from "react";
import { useHistory } from "react-router-dom";
import { Store } from "./store";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import {  Table } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";

const TableView: React.FC = () => {
	const pageStrings = strings.poupanca.table;
	const store = useLocalObservable(() => new Store());

	const history = useHistory();

	const onGoToCreatePoupanca = () => history.push("/dashboard/adminMaster/poupanca/create");

	return (
		<Flex flexDir="column" p={{ base: 2, lg: 16 }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreatePoupanca}
				addButtonText={strings.poupanca.table.addPoupanca}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{format.date(item.date)}</Td>
						<Td>{item.percentage}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
