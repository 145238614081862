import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Store } from "./store";
import API from "~/resources/api";
import { Table, TableCellWithActionButtons } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";


const TableView: React.FC = () => {
	const pageStrings = strings.deletionRequests.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());

	const { dialog } = useGlobalStore();
	const history = useHistory();

	const onGoToUserDetails = (id: string) => history.push(`deletionRequests/details/${id}`);

	const onApprove = (id: string, name: string) => {
		store.user.setValue(name);
		store.acceptDeleteUser(id);
		dialog.closeDialog();
	};
	const onRefuse = (id: string, name: string) => {
		store.user.setValue(name);
		store.refuseDeleteUser(id);
		dialog.closeDialog();
	};

	const openDialog = (request: API.UserDeleteRequest) => {
		dialog.showDialog({
			title: modal.titleApproveOrRefuse,
			closeOnOverlayClick: true,
			description: modal.descriptionRemove(request.user.name),
			buttons: [
				{
					title: modal.button.approve,
					onPress: () => onApprove(request.id, request.user.name),
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.refuse,
					onPress: () => onRefuse(request.id, request.user.name),
					buttonProps: { bg: "red.500", _hover: { bgColor: "red.700" } },
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onDelete={() => openDialog(item)}
							onClick={()=> onGoToUserDetails(item.id)}
						/>
						<Td>{item.user.name}</Td>
						<Td>{item.user.email}</Td>
						<Td>{item.user.documentNumber}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
