import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast} from "~/resources/toast";
import { Errors } from "~/resources/errors";

export class Store {

	public fetchModelShelf: FetchModelShelf<api.DepositDetails>;
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> api.getOneDepositById(id),
			{
				fetchOnConstructor: true,
			},
		) ;
	}
	public onFetchError = (e) => {
		const error = Errors.treatError(e);
		showErrorToast(error.message);
	};
}
