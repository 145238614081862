import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.marginUSD;

export default class Store {
	public confirmedDate = new AttributeShelf<Date>(new Date());

	public formShelf = new FormShelf({
		currentPrice: "",
		buyPrice: "",
		sellPrice: "",
	});

	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public setInitValues = (coin: api.NewCoinMargin) => {
		this.formShelf = new FormShelf({
			currentPrice: coin.currentPrice.toString(),
			buyPrice: coin.buyPrice.toString(),
			sellPrice: coin.sellPrice.toString(),
		});
	};

	public createMarginUSD = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			const data = this.formShelf.getValues();
			await api.createCoinMargin({
				currentPrice: Number(data.currentPrice),
				buyPrice: Number(data.buyPrice),
				sellPrice: Number(data.sellPrice),
				date: this.confirmedDate.value,
			});

			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
