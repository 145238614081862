import React from "react";
import { useHistory } from "react-router-dom";
import { Store } from "./store";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex, Td, Tr } from "@chakra-ui/react";
import { FilterPeriod, Table } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";
import API from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.profitTable.table;
	const store = useLocalObservable(() => new Store());

	const history = useHistory();

	const onGoToCreateProfit = () => history.push("/dashboard/adminMaster/profit/create");
	const onGoToCreateHistoryProfit = () => history.push("/dashboard/adminMaster/profit/createHistory");

	return (
		<Flex flexDir="column" p={{ base: 2, lg: 16 }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateProfit}
				addButtonText={strings.profitTable.table.addProfit}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<FilterPeriod
						maxW={{
							base: "100%",
							sm: "25%",
						}}
						items={API.allValuesPeriodFilter()}
						fetchPage={()=> store.paginetedListShelf.refresh()}
						currentValue={store.periodFilter.value}
						onChangeEnum={store.periodFilter.setValue}
						tranlateEnum={API.translatePeriodFilter}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{format.date(item.createdAt)}</Td>
						<Td>{item.ring}</Td>
						<Td>{item.entranceValue}</Td>
						<Td>{item.profitVariable || 0}</Td>
						<Td>{item.returnedValue}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
			<Button
				w="100%"
				maxW={{
					sm: "100%",
					md: 330,
				}}
				fontSize='1.3rem'
				size="lg"
				mt={10}
				mx="auto"
				isLoading={store.paginetedListShelf.loader.isLoading}
				onClick={() => onGoToCreateHistoryProfit()}
			>
				{strings.profitTable.table.addHistory}
			</Button>
		</Flex>
	);
};

export default observer(TableView);
