import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory, useParams } from "react-router-dom";
import { Button, Text } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	CentralizedCard,
	DetailsWithdraw,
	ApproveOrRefuseButton,
	ImagePicker,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id));

	const goBack = () => history.goBack();

	return (
		<CentralizedCard
			title={{
				text: commonStrings.detailsTitle,
			}}
			button={(
				<Button
					variant="outline"
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.fetchModelShelf.loader.isLoading}
					onClick={goBack}
					leftIcon={
						<ArrowBackIcon />
					}
				>
					{strings.actions.back}
				</Button>
			)}
			isLoading={store.fetchModelShelf.initialLoader.isLoading}
		>
			{
				store.fetchModelShelf.model.value &&
					<>
						<DetailsWithdraw
							withdraw={store.fetchModelShelf.fetchedModel}
							amountAttributeShelf={store.amountToApprove}
							isLoading={store.loader.isLoading}
						/>
						<ImagePicker
							label={commonStrings.setDocumentProof}
							pickImage={store.imagePicker.getPickerFields().pick}
							src={store.imagePicker.src}
						/>
						{!store.imagePicker.src && (
							<Text fontSize="sm" color="gray.600">
								* {strings.error.receiptImageNotAdded}
							</Text>
						)}
						<ApproveOrRefuseButton
							isDisabled={!store.imagePicker.src}
							approve={store.approveOrRefuseWithdraw}
							refuse={store.approveOrRefuseWithdraw}
							id={store.fetchModelShelf.fetchedModel.id}
						/>
					</>
			}
		</CentralizedCard>
	);
};

export default observer(Details);
