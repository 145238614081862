import React from "react";
import { Store } from "./store";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";

const TableView: React.FC = () => {
	const pageStrings = strings.invitedUsersTable.table;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToInvitedUserDetails = (id: string) => history.push(`invitedUsers/details/${id}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onClick={() => onGoToInvitedUserDetails(item.id)}
						/>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<Td>{item.documentNumber}</Td>
						<Td>{item.invitedBy?.name}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
