import { makeAutoObservable } from "mobx";
import {  LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.UserTransfer>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllUsersTransfers,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
}
