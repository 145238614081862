import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";

const PendingUsers: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminDepositAndApprovals/pendingUsers" component={Table} />
		<Route path="/dashboard/adminDepositAndApprovals/pendingUsers/details/:id" component={Details} />
	</Switch>
);

export default observer(PendingUsers);
