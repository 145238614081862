import { Box, Flex } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React from "react";
import API from "~/resources/api";
import strings from "~/resources/strings";
import { DetailsRow } from "../DetailsRow";
import { Label } from "../Label";

interface IProps{
	label?: string;
	bankAccount: API.BankAccount;
}

export const DetailsBank: React.FC<IProps> = observer((props)=> {
	const { label, bankAccount } = props;
	const commonStrings = strings.common.fields;

	return (
		<>
			{label && (
				<Label fontWeight="bold" fontSize="2xl" marginTop={5}>
					{label}
				</Label>
			)}
			<DetailsRow
				label={commonStrings.typeAccount}
				value={API.translateBankAccountType(bankAccount.type)}
			/>
			<DetailsRow
				label={commonStrings.bankName}
				value={bankAccount.bankName}
			/>
			<Flex flexDirection="row" justifyContent="space-between">
				<Box w="48%">
					<DetailsRow
						label={commonStrings.account}
						value={bankAccount.account}
					/>
				</Box>
				<Box w="48%">
					<DetailsRow
						label={commonStrings.accountDv}
						value={bankAccount.accountDv}
					/>
				</Box>
			</Flex>
			<Flex flexDirection="row" justifyContent="space-between">
				<Box w="48%">
					<DetailsRow
						label={commonStrings.agency}
						value={bankAccount.agency}
					/>
				</Box>
				<Box w="48%">
					<DetailsRow
						label={commonStrings.agencyDv}
						value={bankAccount.agencyDv}
					/>
				</Box>
			</Flex>
			{(bankAccount.pixType && bankAccount.pix) && (
				<>
					<DetailsRow
						label={commonStrings.pixType}
						value={API.translatePixType(bankAccount.pixType)}
					/>
					<DetailsRow
						label={commonStrings.pix}
						value={bankAccount.pix}
					/>
				</>
			)}
		</>
	);
});
