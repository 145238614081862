import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { CentralizedCard } from "~/components";

import strings from "~/resources/strings";

import Store from "./store";
import { DetailsUser } from "~/components/DetailsUser";
import { ApproveOrRefuseButton } from "~/components/ApproveOrRefuseButton";

interface IParams {
	id?: string;
}

const Details: React.FC = () => {
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store(id || ""));

	const goBack = () => history.goBack();

	return (
		<>
			<CentralizedCard
				title={{
					text: commonStrings.detailsTitle,
				}}
				button={(
					<Button
						variant="outline"
						minW={{ base: "100%", md: 280 }}
						size="lg"
						mt={10}
						isLoading={store.fetchModelShelf.loader.isLoading}
						onClick={goBack}
						leftIcon={
							<ArrowBackIcon />
						}
					>
						{strings.actions.back}
					</Button>
				)}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
			>
				{
					store.fetchModelShelf.model.value &&
						<>
							<DetailsUser user={store.fetchModelShelf.fetchedModel} />
							<ApproveOrRefuseButton
								approve={store.approveOrRefuseUser}
								refuse={store.approveOrRefuseUser}
								id={store.fetchModelShelf.fetchedModel.id}
							/>
						</>
				}
			</CentralizedCard>
		</>
	);
};

export default observer(Details);
