import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Tr,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
	Table,
	TableCellWithActionButtons,
	CpfFilter,
} from "~/components";
import strings from "~/resources/strings";
import { Store } from "./store";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.pendingWithdraw.table;

	const store = useLocalObservable(() => new Store());

	const history = useHistory();

	const onGoToDetailsWithdraw = (id: string) => history.push(`/dashboard/adminMaster/pendingWithdraws/details/${id}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				headerFilter={
					<CpfFilter
						onClick={() => store.paginetedListShelf.fetchPage(0)}
						setValue={store.cpf.setValue}
						value={store.cpf.value}
						isLoading={store.loader.isLoading}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onView={()=> onGoToDetailsWithdraw(item.id)}
						/>
						<Td>{item.user.name}</Td>
						<Td>{item.user.documentNumber}</Td>
						<Td>{item.amount}</Td>
						<Td>{format.currencyForBR(item.brlPrice)}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
