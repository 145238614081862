import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

export class Store {

	public FetchModelShelf: FetchModelShelf<api.User>;
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.FetchModelShelf = new FetchModelShelf(id, ()=> api.getUser(id),
			{
				fetchOnConstructor: true,
			},
		) ;
	}
	public onFetchError = (e) => {
		const error = Errors.treatError(e);
		showErrorToast(error.message);
	};

	public changeUserBlockStatus = async (id: string, blockedAt: Date | null) => {
		this.loader.tryStart();
		try {
			if (blockedAt) {
				await api.changeUserBlockStatus(id, false);
				showSuccessToast(strings.common.modal.statusUser(false));
			} else {
				await api.changeUserBlockStatus(id, true);
				showSuccessToast(strings.common.modal.statusUser(true));
			}
			this.FetchModelShelf.fetchModel();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
