import { makeAutoObservable } from "mobx";
import { AttributeShelf, FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast, showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { ImagePickerShelf } from "@startapp/mobx-utils/src/web";

export default class Store {
	public amountToApprove = new AttributeShelf("");
	public fetchModelShelf: FetchModelShelf<api.Withdrawal>;
	public loader = new LoaderShelf();
	public imagePicker = new ImagePickerShelf(api.uploadImage);

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(
			id,
			api.getOneWithdrawalById,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public approveOrRefuseWithdraw= async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			if (isApprove){
				if (!this.imagePicker.uploadedImage) {
					showErrorToast(strings.error.receiptImageNotAdded);
					return;
				}

				await api.approveWithdrawal(id, this.imagePicker.uploadedImage ,+this.amountToApprove.value || null);
			} else {
				await api.refuseWithdrawal(id);
			}
			showSuccessToast(strings.pendingWithdraw.table.successApproveOrRefuse(isApprove));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
