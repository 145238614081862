import React from "react";
import { observer } from "mobx-react-lite";
import { Text, Center, Spinner } from "@chakra-ui/react";

interface IQuantityCard {
	title?: string;
	totalCoin?: number;
	quantity?: number;
	loading?: boolean;
}

export const QuantityCard: React.FC<IQuantityCard> = observer((props) => {
	const { title, totalCoin, quantity, loading } = props;

	return (
		<Center
			width={{base:"90%", md:"20vw"}}
			height={{base:"17vh", md:"20vh"}}
			margin="auto"
			border="2px solid"
			borderColor="primary.500"
			p={4}
			flexDirection="column"
			borderRadius={5}
			boxShadow="2xl"
		>
			<Text
				mb={3}
				fontSize={{ base:10, sm: "sm", md: "md", lg:"xl" }}
				color="primary.500"
				fontWeight="bold"
				textAlign="center"
			>
				{title}
			</Text>
			{
				loading
					? <Spinner color="secondary.700" />
					: (
						<Text color="secondary.700" fontSize={{base: 10, sm: "sm", md: "md", lg: "lg"}}>
							{totalCoin !== undefined ? `$${totalCoin.toFixed(2)}` : quantity}
						</Text>
					)
			}
		</Center>
	);
});
