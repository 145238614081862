import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";

const PendingDeposits: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminDepositAndApprovals/pendingDeposits" component={Table} />
		<Route path="/dashboard/adminDepositAndApprovals/pendingDeposits/details/:id" component={Details} />
	</Switch>
);
export default observer(PendingDeposits);
