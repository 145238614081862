import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	PaginatedListShelf,
} from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.Poupanca>;
	public periodFilter = new AttributeShelf<api.PeriodFilter | null>(null);
	public getPoupanca = (page: number) => api.getAllPoupanca(page);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getPoupanca,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}
}
