import { AiOutlineBank, AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiUserX, BiText } from "react-icons/bi";
import { BsFillPiggyBankFill } from "react-icons/bs";
import { RiUserFollowLine, RiUserReceivedLine, RiMoneyDollarBoxLine, RiUserStarLine, RiUserVoiceLine } from "react-icons/ri";
import { MdAttachMoney, MdRequestQuote } from "react-icons/md";
import { GiReceiveMoney, GiPayMoney, GiMoneyStack } from "react-icons/gi";
import { TbUserExclamation } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { VscGraphLine } from "react-icons/vsc";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { IconType } from "react-icons";

interface ILinkRoutes {
	Icon: IconType;
	text: string;
	path: string;
}

const useMainRoutes = (): ILinkRoutes[] => {

	const { authStore } = useGlobalStore();

	const createAdminMasterRoute = (path?: string) => `/dashboard/adminMaster${path ? "/" + path : ""}`;
	const createAdminDepositAndApprovalsRoute = (path?: string) => `/dashboard/adminDepositAndApprovals${path ? "/" + path : ""}`;
	const createAdminWithdrawRoute = (path?: string) => `/dashboard/adminWithdraw${path ? "/" + path : ""}`;

	const adminRoutes = [
		{
			Icon: AiOutlineHome,
			text: strings.nav.home,
			path: createAdminMasterRoute(),
		},
		{
			Icon: RiUserStarLine,
			text: strings.nav.adminMaster,
			path: createAdminMasterRoute("adminMasterUsers"),
		},
		{
			Icon: RiUserReceivedLine,
			text: strings.nav.adminWithdrawUsers,
			path: createAdminMasterRoute("adminWithdraw"),
		},
		{
			Icon: RiUserFollowLine,
			text: strings.nav.adminDepositAndApprovalsUsers,
			path: createAdminMasterRoute("adminDepositAndApprovals"),
		},
		{
			Icon: AiOutlineBank,
			text: strings.nav.bankAccounts,
			path: createAdminMasterRoute("bankAccounts"),
		},
		{
			Icon: TbUserExclamation,
			text: strings.nav.pendingUsers,
			path: createAdminMasterRoute("pendingUsers"),
		},
		{
			Icon: AiOutlineUser,
			text: strings.nav.users,
			path: createAdminMasterRoute("users"),
		},
		{
			Icon: RiUserVoiceLine,
			text: strings.nav.invitedUsers,
			path: createAdminMasterRoute("invitedUsers"),
		},
		{
			Icon: RiMoneyDollarBoxLine,
			text: strings.nav.pendingDeposits,
			path: createAdminMasterRoute("pendingDeposits"),
		},
		{
			Icon: GiReceiveMoney,
			text: strings.nav.deposits,
			path: createAdminMasterRoute("approvedDeposits"),
		},
		{
			Icon: MdAttachMoney,
			text:strings.nav.pendingWithdraws,
			path: createAdminMasterRoute("pendingWithdraws"),
		},
		{
			Icon: GiPayMoney,
			text: strings.nav.approvedWithdrawal,
			path: createAdminMasterRoute("approvedWithdrawal"),
		},
		{
			Icon: VscGraphLine,
			text: strings.nav.marginUSD,
			path: createAdminMasterRoute("marginUSD"),
		},
		{
			Icon: MdRequestQuote,
			text: strings.nav.quotas,
			path: createAdminMasterRoute("quotas"),
		},
		{	Icon: GiMoneyStack,
			text: strings.nav.profit,
			path: createAdminMasterRoute("profit"),
		},
		{
			Icon: BiUserX,
			text: strings.nav.deletionRequests,
			path: createAdminMasterRoute("deletionRequests"),
		},
		{
			Icon: BiText,
			text: strings.nav.indicationTexts,
			path: createAdminMasterRoute("indicationTexts"),
		},
		{
			Icon: HiOutlineDocumentReport,
			text: "Relatorio Indicados",
			path: createAdminMasterRoute("reportIndicated"),
		},
		{
			Icon: BsFillPiggyBankFill,
			text: "Poupança",
			path: createAdminMasterRoute("poupanca"),
		},
		/* {
			Icon: BiTransferAlt,
			text: strings.nav.userTransfer,
			path: createAdminMasterRoute("userTransfer"),
		},*/
	];

	const adminDepositAndApprovalsRoutes = [
		{
			Icon: AiOutlineHome,
			text: strings.nav.home,
			path: createAdminDepositAndApprovalsRoute(),
		},
		{
			Icon: RiMoneyDollarBoxLine,
			text: strings.nav.pendingDeposits,
			path: createAdminDepositAndApprovalsRoute("pendingDeposits"),
		},
		{
			Icon: GiReceiveMoney,
			text: strings.nav.deposits,
			path: createAdminDepositAndApprovalsRoute("approvedDeposits"),
		},
		{
			Icon: TbUserExclamation,
			text: strings.nav.pendingUsers,
			path: createAdminDepositAndApprovalsRoute("pendingUsers"),
		},
		{
			Icon: BiUserX,
			text: strings.nav.deletionRequests,
			path: createAdminDepositAndApprovalsRoute("deletionRequests"),
		},
	];

	const adminWithdrawRoutes = [
		{
			Icon: AiOutlineHome,
			text: strings.nav.home,
			path: createAdminWithdrawRoute(),
		},
		{
			Icon: MdAttachMoney,
			text:strings.nav.pendingWithdraws,
			path: createAdminWithdrawRoute("pendingWithdraws"),
		},
		{
			Icon: GiPayMoney,
			text: strings.nav.approvedWithdrawal,
			path: createAdminWithdrawRoute("approvedWithdrawal"),
		},
	];

	if (!authStore.currentAdminUser) {
		return [];
	}

	switch (authStore.currentAdminUser.adminType) {
		case api.AdminType.master:
			return adminRoutes;
		case api.AdminType.depositAndApprovals:
			return adminDepositAndApprovalsRoutes;
		case api.AdminType.withdraw:
			return adminWithdrawRoutes;
	}

};

export default useMainRoutes;
