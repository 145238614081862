import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { CardComissionInfo, QuantityCard, Table } from "~/components";
import strings from "~/resources/strings";
import { Store } from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.quotasTable;
	const store = useLocalObservable(() => new Store());
	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Flex
				justifyContent="space-between"
				maxW={{ base:"100%", md: "75%" }}
				flexDir={{ base:"column", md:"row" }}
				margin="auto"
				gap={16}
				mb={25}
			>
				<QuantityCard
					title={pageStrings.cards.totalCoins}
					quantity={store.DSCCoinsStaticsModel.model.value?.totalCoins}
					loading={store.DSCCoinsStaticsModel.loader.isLoading}

				/>
				<QuantityCard
					title={pageStrings.cards.totalUsers}
					quantity={store.DSCCoinsStaticsModel.model.value?.totalUsers}
					loading={store.DSCCoinsStaticsModel.loader.isLoading}
				/>
			</Flex>
			<Table
				headerComponent={
					<CardComissionInfo
						quota={store.DSCWallet.model.value?.quota || "0"}
						balance={store.DSCWallet.model.value?.balance || "0"}
					/>
				}
				data={store.walletList.items}
				loading={store.walletList.loader.isLoading}
				tableTitle={pageStrings.table.title}
				headers={pageStrings.table.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{item.user.name}</Td>
						<Td>{item.balance}</Td>
						<Td>{strings.format.formatQuota(item.quota.toString())}</Td>
					</Tr>
				)}
				currentPage={store.walletList.page}
				prevPage={store.walletList.previousPage}
				nextPage={store.walletList.nextPage}
				hasNextPage={store.walletList.hasNextPage}
				emptyMessage={strings.common.noResutls}
			/>
		</Flex>
	);
};

export default observer(TableView);
