import React from "react";
import { Store } from "./store";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { CpfFilter, Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.pendingDeposits.table;
	const modal = strings.common.modal;

	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();
	const history = useHistory();

	const onGoToPendingDepositDetails = (id: string) => history.push(`pendingDeposits/details/${id}`);

	const openDialog = (deposit: api.Deposit) => {
		dialog.showDialog({
			title: modal.titleApproveOrRefuse,
			closeOnOverlayClick: true,
			description: modal.descriptionApproveOrRefuse(deposit.user.name),
			buttons: [
				{
					title: modal.button.approve,
					onPress: () => store.approveOrRefuseDeposit(deposit.id, true),
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.refuse,
					onPress: () => store.approveOrRefuseDeposit(deposit.id, false),
					buttonProps: { bg: "red.500", _hover: { bgColor: "red.700" } },
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<CpfFilter
						onClick={() => store.paginetedListShelf.fetchPage(0)}
						setValue={store.cpf.setValue}
						value={store.cpf.value}
						isLoading={store.loader.isLoading}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onView={() => onGoToPendingDepositDetails(item.id)}
							onApprove={() => openDialog(item)}
						/>
						<Td>{item.user.name}</Td>
						<Td>{item.user.documentNumber}</Td>
						<Td>{item.amount}</Td>
						<Td>{strings.format.currencyForBR(item.brlPrice)}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
