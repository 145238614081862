import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { ModalComponent, Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { Store } from "./store";
import api from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.reportTable;
	const store = useLocalObservable(() => new Store());
	const [isOpen, setIsOpen] = React.useState(false);
	const [orderArrow, setOrderArrow] = React.useState({
		field: "",
		order: "ASC",
	});

	const orderUsers = (columnName) => {
		if (columnName === "Indicados com Cadastro"){
			return store.order.value?.order !== api.Order.ASC ?
				store.order.setValue({field: api.ReportOrderField.countOfInvitedUsers ,order: api.Order.ASC}) :
				store.order.setValue({field: api.ReportOrderField.countOfInvitedUsers ,order: api.Order.DESC});
		}
		if (columnName === "Indicados que Depositaram"){
			return store.order.value?.order !== api.Order.ASC ?
				store.order.setValue({field: api.ReportOrderField.invitedDepositUsers ,order: api.Order.ASC}) :
				store.order.setValue({field: api.ReportOrderField.invitedDepositUsers ,order: api.Order.DESC});
		}
	};

	const orderFields = (column) => {
		orderUsers(column);
		if (column === "Indicados que Depositaram" || column === "Indicados com Cadastro") {
			setOrderArrow({field: column, order: store.order.value?.order || "ASC"});
			store.ReportUserList.refresh();
		}
	};

	const OpenModal = (tableType, id) => {
		store.id.setValue(id);
		store.commissionTableType.setValue(tableType);
		setIsOpen(true);
	};

	const ChangeTypeCommision = () => {
		store.ChangeTableType(store.ReportUserList.refresh);
		setIsOpen(false);
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			{store.ReportUserList.items &&
				<Table
					data={store.ReportUserList.items}
					loading={store.ReportUserList.loader.isLoading}
					tableTitle={pageStrings.table.title}
					headers={pageStrings.table.header}
					onClickHeader={orderFields}
					orderArrow={orderArrow}
					renderRow={(item, index) => (
						<Tr key={index}>
							<Td>{item.code}</Td>
							<Td>{item.name}</Td>
							<Td>{item.state}</Td>
							<Td>{item.countOfInvitedUsers}</Td>
							<Td>{item.invitedDepositUsers}</Td>
							<Td>{item.commissionEarnedAmount}</Td>
							<Td>{api.translateCommissionTableType(item.commissionTable)}</Td>
							<TableCellWithActionButtons
								onEdit={() => OpenModal(item.commissionTable, item.userId)}
							/>
							<ModalComponent
								isOpen={isOpen}
								title={pageStrings.table.titleModal}
								onClose={() => setIsOpen(false)}
								tableType={store.commissionTableType}
								onClick={ChangeTypeCommision}
								buttonText={strings.actions.save}
							/>
						</Tr>
					)}
					currentPage={store.ReportUserList.page}
					prevPage={store.ReportUserList.previousPage}
					nextPage={store.ReportUserList.nextPage}
					hasNextPage={store.ReportUserList.hasNextPage}
					emptyMessage={strings.common.noResutls}
				/>}
		</Flex>
	);
};

export default observer(TableView);
