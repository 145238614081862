import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.Deposit>;
	public loader = new LoaderShelf();
	public cpf = new AttributeShelf("");

	public getAllApprovedDeposits = () => api.getAllApprovedDeposits(this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllApprovedDeposits,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

	public cpfFilter = async() => {
		this.loader.tryStart();
		try {
			this.paginetedListShelf.items = await api.getAllApprovedDeposits(this.cpf.value);
			showSuccessToast(strings.usersTable.table.successCpfFilter);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
