import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

const pageStrings = strings.adminUserTable.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		name: "",
		email: "",
		documentNumber: "",
		password: "",
	});

	public loader = new LoaderShelf();

	public id = new AttributeShelf("");

	constructor(id?: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getAdminUser(id);
		}
	}

	public getAdminUser = async (id: string) => {
		this.loader.start();
		try {
			const admin = await api.getAdminUser(id);
			this.setInitValues(admin);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (admin: api.EditAdminUser) => {
		this.formShelf = new FormShelf({
			name: admin.name,
			email: admin.email,
			documentNumber: admin.documentNumber,
			password: "",
		});
	};

	public createOrEditAdmin = async (onSuccess: () => void) => {
		this.loader.start();
		try {

			const data = this.formShelf.getValues();
			if (this.id.value) {
				await api.editAdminUser(this.id.value, {
					name: data.name,
					documentNumber: data.documentNumber,
					email: data.email,
				});
			} else {
				await api.createAdminUserMaster({
					name: data.name,
					email: data.email,
					documentNumber: data.documentNumber,
					password: data.password,
				});
			}

			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
