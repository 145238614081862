import React from "react";
import { Store } from "./store";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons} from "~/components";
import strings from "~/resources/strings";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.userTransfer;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToUserTransferDetails = (id: string) => history.push(`userTransfer/details/${id}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.table.title}
				headers={pageStrings.table.header}
				renderRow={(item) => (
					<Tr key={item.id}>
						<TableCellWithActionButtons
							onClick={() => onGoToUserTransferDetails(item.id)}
						/>
						<Td>{item.coinQuantity}</Td>
						<Td>{format.currencyForBR(item.valueInUsd)}</Td>
						<Td>{item.sender.name}</Td>
						<Td>{item.receiver.name}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
