import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";

const PendingUsers: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/pendingUsers" component={Table} />
		<Route path="/dashboard/adminMaster/pendingUsers/details/:id" component={Details} />
	</Switch>
);
export default observer(PendingUsers);
