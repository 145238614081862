import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { useAuthAdminDepositAndApprovalsRoute } from "~/hooks/useAuthAdminDepositAndApprovalsRoute";
import TableDeposits from "./Table";
import DepositDetailsTable from "./Details";

const TableApprovedDeposits: React.FC = () => {
	useAuthAdminDepositAndApprovalsRoute();

	return (
		<Switch>
			<Route exact path="/dashboard/adminDepositAndApprovals/approvedDeposits" component={TableDeposits} />
			<Route exact path="/dashboard/adminDepositAndApprovals/approvedDeposits/details/:id" component={DepositDetailsTable} />
		</Switch>
	);
};
export default observer(TableApprovedDeposits);
