import React from "react";
import { Store } from "./store";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const pageStrings = strings.IndicationTexts.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToIndicationTextDetails = (id: string) => history.push(`indicationTexts/details/${id}`);
	const onGoToCreateIndicationText = () => history.push("indicationTexts/create");
	const { dialog } = useGlobalStore();

	const onDeleteIndicationText = (id: string) => {
		store.deleteIndicationText(id);
		dialog.closeDialog();
	};

	const openDialogDelete = (text: api.IndicationText) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(text.id),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteIndicationText(text.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				onAdd={onGoToCreateIndicationText}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onClick={() => onGoToIndicationTextDetails(item.id)}
							onDelete={() => openDialogDelete(item)}
						/>
						<Td>{item.id}</Td>
						<Td>{format.date(item.createdAt)}</Td>
						<Td>{item.text}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
