import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

export default class Store {

	public formShelf = new FormShelf({
		indicationText: "",
	});
	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public createIndicationText = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.formShelf.getValues();
			await api.createIndicationText({ text: data.indicationText });
			showSuccessToast(strings.IndicationTexts.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
