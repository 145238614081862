import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import { useAuthAdminWithdrawRoute } from "~/hooks/useAuthAdminWithdrawRoute";
import PendingWithdraws from "./PendingWithdraws";
import ApprovedWithdrawal from "./ApprovedWithdrawal";

const AdminWithdraw: React.FC = () => {
	useAuthAdminWithdrawRoute();

	return (
		<Switch>
			<Route exact path="/dashboard/adminWithdraw" component={Home} />
			<Route path="/dashboard/adminWithdraw/approvedWithdrawal" component={ApprovedWithdrawal} />
			<Route path="/dashboard/adminWithdraw/pendingWithdraws" component={PendingWithdraws} />
		</Switch>
	);
};
export default observer(AdminWithdraw);
