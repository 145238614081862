import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import UserDetails from "./UserDetails";

const UsersTable: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/users" component={Table} />
		<Route path="/dashboard/adminMaster/users/userDetails/:id" component={UserDetails} />
	</Switch>
);
export default observer(UsersTable);
