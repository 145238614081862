import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex } from "@chakra-ui/react";

import Store from "./store";

import { CentralizedCard, TextInput } from "~/components";

import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { DatePicker } from "~/components/DatePicker";

interface IParams {
	id: string;
}

const Create: React.FC = () => {

	const commonStrings = strings.common;
	const pageStrings = strings.marginUSD.create;

	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store());

	const onSuccess = () => {
		history.goBack();
	};

	const openDialog = () => {
		dialog.showDialog({
			title: modal.titleUpdateMarginUSD,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: modal.button.confirm,
					onPress: () => onSubmit(),
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSubmit = () => {
		store.createMarginUSD(onSuccess);
		dialog.closeDialog();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={openDialog}
				>
					{strings.common.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<Flex justifyContent="center" gap={2} flexDir={{base: "column", md:"row"}}>
				<TextInput
					labelText={commonStrings.fields.currentPrice}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("currentPrice").error}
					{...store.formShelf.field("currentPrice")}
				/>
				<TextInput
					labelText={commonStrings.fields.buyPrice}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("buyPrice").error}
					{...store.formShelf.field("buyPrice")}
				/>
				<TextInput
					labelText={commonStrings.fields.sellPrice}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("sellPrice").error}
					{...store.formShelf.field("sellPrice")}
				/>
				<DatePicker
					width="50%"
					label={commonStrings.fields.createAt}
					selectedDate={store.confirmedDate.value}
					onChangeDate={store.confirmedDate.setValue}
					isDisabled={store.loader.isLoading}
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(Create);
