import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";

import {
	AdminForm,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.adminWithdraw.createOrEdit;
	const commonStrings = strings.common;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const onSuccess = () => {
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id || ""));

	const onSubmit = () => {
		store.createOrEditUser(onSuccess);
	};

	return (
		<AdminForm
			title={pageStrings.title(!!id)}
			formValues={{ field: store.formShelf.field }}
			isLoading={store.loader.isLoading}
			hidePasswordField={!!id}
			submit={{
				onClick: onSubmit,
				text: commonStrings.buttons.confirmButton(!!id),
				isLoading: store.loader.isLoading,
			}}
		/>
	);
};

export default observer(CreateOrEdit);
