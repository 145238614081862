import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { useAuthAdminDepositAndApprovalsRoute } from "~/hooks/useAuthAdminDepositAndApprovalsRoute";
import Home from "./Home";
import PendingDeposits from "./PendingDeposits";
import TableApprovedDeposits from "./ApprovedDeposits";
import DeletionRequests from "./DeletionRequests";

import PendingUsers from "./PendingUsers";

const AdminDepositAndApprovals: React.FC = () => {
	useAuthAdminDepositAndApprovalsRoute();

	return (
		<Switch>
			<Route exact path="/dashboard/adminDepositAndApprovals" component={Home} />
			<Route path="/dashboard/adminDepositAndApprovals/pendingDeposits" component={PendingDeposits} />
			<Route path="/dashboard/adminDepositAndApprovals/approvedDeposits" component={TableApprovedDeposits} />
			<Route path="/dashboard/adminDepositAndApprovals/pendingUsers" component={PendingUsers} />
			<Route path="/dashboard/adminDepositAndApprovals/deletionRequests" component={DeletionRequests} />
		</Switch>
	);
};
export default observer(AdminDepositAndApprovals);
