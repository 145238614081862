import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { getBankNamesAndCodes } from "~/resources/bankCodes";
import { PixType } from "@startapp/dscpay-admin-api";

const pageStrings = strings.bankAccounts.createOrEdit;

export default class Store {

	public formShelf = new FormShelf({
		bankCode: "",
		bankName: "",
		agency: "",
		agencyDv: "",
		account: "",
		accountDv: "",
		pix: "",
		type: "",
		pixType: "",
		socialReason: "",
		cnpj: "",
	});

	public loader = new LoaderShelf();
	public radioValue = new AttributeShelf("no");
	public type = new AttributeShelf(api.BankAccountType.contaCorrente);
	public pixType: AttributeShelf<PixType | null> = new AttributeShelf(null);

	public id = new AttributeShelf("");

	public bankCode = "";
	public bankName = "";

	public selectedBankAccountType: api.BankAccountType | null = null;

	public selectBankAccountType = (bankAccountType: api.BankAccountType) => {
		this.selectedBankAccountType = bankAccountType;
	};

	constructor(id?: string){
		makeAutoObservable(this);

		if (id) {
			this.id.setValue(id);
			this.getBankUser(id);
		}
	}

	public toggleBank = (bankCode: string) => {
		const bankNameAndCode = getBankNamesAndCodes().find((bank) => bank.code === bankCode);
		if (bankNameAndCode) {
			this.bankName = bankNameAndCode.name;
			this.bankCode = bankNameAndCode.code;
		}
	};
	public getBankUser = async (id: string) => {
		this.loader.start();
		try {
			const bank = await api.getOneBankAccountById(id);
			this.setInitValues(bank);
		} catch (e) {
			const error = Errors.handleError(e);
			showErrorToast(error);
		} finally {
			this.loader.end();
		}
	};

	public createNewBankAccountType = (): api.NewBankAccount => {
		const data = this.formShelf.getValues();
		return 	{
			account: data.account,
			accountDv: data.accountDv,
			agency: data.agency,
			agencyDv: data.agencyDv,
			bankCode: this.bankCode,
			bankName: this.bankName,
			pix: data.pix,
			type: this.type.value,
			pixType: this.pixType.value,
			socialReason: data.socialReason,
			cnpj: data.cnpj,
		};
	};

	public setInitValues = (bank: api.BankAccount) => {
		this.formShelf = new FormShelf({
			account: bank.account,
			accountDv: bank.accountDv || "",
			agency: bank.agency,
			agencyDv: bank.agencyDv || "",
			socialReason: bank.socialReason || "",
			cnpj: bank.cnpj || "",
			bankCode: bank.bankCode,
			bankName: bank.bankName,
			pix: this.radioValue.value === "yes" ? bank.pix || "" : "",
			type: "",
			pixType: "",
		});
		if (bank.type) {
			this.type.setValue(bank.type);
		}
		if (bank.pixType && this.radioValue.value === "yes") {
			this.pixType.setValue(bank.pixType);
		}
	};

	public createOrEditBankAccount = async (onSuccess: () => void) => {
		this.loader.start();
		const data = this.createNewBankAccountType();
		try {
			if (this.id.value) {
				await api.editAdminBankAccount(this.id.value, data);
			} else {
				await api.createAdminBankAccount(data);
			}
			showSuccessToast(pageStrings.success(!!this.id.value));
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
