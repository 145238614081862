import React from "react";
import { observer } from "mobx-react-lite";
import {
	Select,
	SelectProps,
	FormControl,
	FormControlProps,
	Button,
	Flex,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

interface IProps<EnumType> extends SelectProps {
	label?: string;
	tranlateEnum: (item: EnumType) => string;
	onChangeEnum: (value: EnumType | null) => void;
	currentValue: EnumType | null;
	items: EnumType[];
	formProps?: FormControlProps;
	disabled?: boolean;
	fetchPage: () => void;
}

export const FilterPeriod = observer(<EnumType extends string>(props: IProps<EnumType>) => {
	const {
		tranlateEnum,
		onChangeEnum,
		currentValue,
		formProps,
		items,
		disabled,
		fetchPage,
		...rest
	} = props;
	React.useEffect(() =>{}, [currentValue]);
	const clearFilter = () => {
		onChangeEnum(null);
		fetchPage();
	};

	return (
		<FormControl {...formProps}>
			<Flex
				direction={{
					base: "column",
					sm: "row",
				}}
				gap={3}
			>
				<Select
					isDisabled={disabled}
					bg="white"
					color="black"
					{...rest}
					value={currentValue || ""}
					onChange={(e) => {
						const value = e.target.value as EnumType;
						onChangeEnum(value);
					}}
				>
					<option
						value="0"
					/>
					{items.map((item, index) => (
						<option
							key={`${item}-${index}`}
							value={item}
						>
							{tranlateEnum(item)}
						</option>
					))}

				</Select>
				<Button onClick={fetchPage}>{strings.components.cpfFilter.searchButton}</Button>
				<Button onClick={clearFilter}>{strings.components.cpfFilter.clearFilter}</Button>
			</Flex>

		</FormControl>
	);
});
