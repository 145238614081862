import React from "react";
import { Store } from "./store";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table } from "~/components";
import strings from "~/resources/strings";
import format from "~/resources/format";
import { useHistory } from "react-router-dom";

const MarginUSD: React.FC = () => {
	const pageStrings = strings.marginUSD;
	const store = useLocalObservable(() => new Store());

	const history = useHistory();

	const onGoToCreateMarginUSD = () => history.push("/dashboard/adminMaster/marginUSD/create");

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateMarginUSD}
				tableTitle={pageStrings.table.title}
				headers={pageStrings.table.headers}
				renderRow={(item, index) => (
					<Tr key={index}>
						<Td>{format.currencyForBR(item.currentPrice)}</Td>
						<Td>{format.currencyForBR(item.buyPrice)}</Td>
						<Td>{format.currencyForBR(item.sellPrice)}</Td>
						<Td>{format.date(item.date)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(MarginUSD);
