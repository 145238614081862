import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { CentralizedCard } from "~/components";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { DepositDetails } from "~/components/DepositDetails";
import strings from "~/resources/strings";

interface IParams {
	id: string;
}

const DepositDetailsTable: React.FC = () => {
	const string = strings.common;
	const buttonBack = strings.actions.back;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoBack = () => history.goBack();

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<>
				{store.fetchModelShelf.model.value && (
					<CentralizedCard
						title={{
							text: string.detailsTitle,
							helper: (
								<Tooltip label={strings.common.edit}>
									<IconButton
										variant="icon"
										aria-label="Voltar"
										size="lg"
									/>
								</Tooltip>
							),
						}}
						isLoading={store.fetchModelShelf.initialLoader.isLoading}
					>
						{
							store.fetchModelShelf.model.value &&
								<>
									<DepositDetails deposit={store.fetchModelShelf.fetchedModel} />
								</>
						}
					</CentralizedCard>
				)}
				<Button
					onClick={() => onGoBack()}
					w={{
						base: "50%",
						md: "15%",
					}}
					mb={5}
					variant="outline"
					leftIcon={
						<ArrowBackIcon />
					}
				>
					{buttonBack}
				</Button>
			</>
		</Flex>
	);
};

export default observer(DepositDetailsTable);
