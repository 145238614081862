import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableDeposits from "./Table";
import DepositsDetailsTable from "./Details";

const TableApprovedDepositsMaster: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/approvedDeposits" component={TableDeposits} />
		<Route path="/dashboard/adminMaster/approvedDeposits/details/:id" component={DepositsDetailsTable} />
	</Switch>
);
export default observer(TableApprovedDepositsMaster);
