import * as React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Image,
	Flex,
} from "@chakra-ui/react";
import { AttributeShelf } from "@startapp/mobx-utils";
import API from "~/resources/api";
import strings from "~/resources/strings";
import imagePlaceholder from "../../../static/pick_image.svg";
import {
	DetailsRow,
	Label,
	ModalImage,
	TextInput,
} from "..";
import format from "../../resources/format";
import { DetailsBank } from "../DetailsBank";
import { DetailsUser } from "../DetailsUser";

interface IProps {
	deposit: API.DepositDetails;
	amountAttributeShelf?: AttributeShelf<string>;
	isLoading?: boolean;
}

export const DepositDetails: React.FC<IProps> = observer((props) => {
	const {
		deposit,
		amountAttributeShelf,
		isLoading,
	} = props;
	const commonStrings = strings.common;

	return (
		<>
			<Label fontWeight="bold" fontSize="2xl" marginTop={5}>
				{commonStrings.infoDeposit}
			</Label>
			<DetailsRow
				label={commonStrings.fields.labelDepositAmount(deposit.acceptBy)}
				value={format.currencyForBR(deposit.brlPrice)}
			/>
			<DetailsRow
				label={commonStrings.fields.labelDSCAmount(!!deposit.acceptBy)}
				value={format.currencyForUSD(deposit.priceDscCoin)}
			/>
			{
				!amountAttributeShelf && (
					<DetailsRow
						label={strings.common.fields.amountApproved}
						value={deposit.amountApproved}
					/>
				)
			}
			{
				amountAttributeShelf && (
					<DetailsRow
						label={strings.common.fields.amount}
						value={deposit.amount}
					/>
				)
			}
			{
				amountAttributeShelf && (
					<TextInput
						labelText={strings.common.fields.customAmount}
						labelProps={{ fontWeight: "bold" }}
						inputProps={{ padding: 6 }}
						type="text"
						isDisabled={isLoading}
						value={amountAttributeShelf.value}
						onChange={(e) => amountAttributeShelf.setValue(e.target.value)}
					/>
				)
			}

			{deposit.acceptBy && (
				<>
					<DetailsRow
						label={commonStrings.fields.approvedBy}
						value={deposit.acceptBy.name}
					/>
					<DetailsRow
						label={commonStrings.fields.approvedAt}
						value={format.date(deposit.approvedAt)}
					/>
					<DetailsRow
						label={commonStrings.fields.hour}
						value={format.hour(deposit.approvedAt)}
					/>
				</>
			)}
			<Box>
				<Flex gap={2} alignItems="center">
					<Label fontWeight="bold" marginBottom={1}>
						{commonStrings.fields.receiptImage}
					</Label>
					<ModalImage modalTitle={strings.common.documentProof} imageSrc={deposit.receiptImage.url} />
				</Flex>
				<Image
					width={120}
					height={120}
					backgroundColor="white"
					p={0}
					mt={2}
					src={deposit.receiptImage ? deposit.receiptImage.thumb.url : imagePlaceholder}
					rounded="lg"
				/>
			</Box>
			<DetailsRow
				label={commonStrings.fields.status}
				value={API.translateDepositStatus(deposit.depositStatus)}
			/>
			<DetailsRow
				label={commonStrings.fields.createAt}
				value={format.date(deposit.createdAt)}
			/>
			<DetailsUser
				label={commonStrings.infoUser}
				user={deposit.user}
			/>
			<DetailsBank
				label={strings.common.originBankAccount}
				bankAccount={deposit.userBankAccount}
			/>
			<DetailsBank
				label={strings.common.destinyBankAccount}
				bankAccount={deposit.adminUserBankAccount}
			/>
		</>
	);
});
