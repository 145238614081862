import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Details from "./Details";
import Create from "./Create";

const IndicationTexts: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/indicationTexts" component={Table} />
		<Route exact path="/dashboard/adminMaster/indicationTexts/create" component={Create} />
		<Route exact path="/dashboard/adminMaster/indicationTexts/details/:id" component={Details} />
	</Switch>
);
export default observer(IndicationTexts);
