import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.User>;
	public loader = new LoaderShelf();
	public cpf = new AttributeShelf("");

	public getAllUsers = (page: number) => api.getAllUsers(page, this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllUsers,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const deleteUser = await api.deleteUser(id);
			showSuccessToast(strings.usersTable.table.delete(deleteUser.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};


	public changeUserBlockStatus = async (id: string, blockedAt: Date | null) => {
		this.loader.tryStart();
		try {
			if (blockedAt) {
				await api.changeUserBlockStatus(id, false);
				showSuccessToast(strings.common.modal.statusUser(false));
			} else {
				await api.changeUserBlockStatus(id, true);
				showSuccessToast(strings.common.modal.statusUser(true));
			}
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
