import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface CryptoResponse {
    coins: CryptoData[];
}

export interface CryptoData {
    currency: string;
    currencyRateFromUSD: number;
    coinName: string;
    coin: string;
    regularMarketChange: number;
    regularMarketPrice: string;
    regularMarketChangePercent: number;
    regularMarketDayLow: number;
    regularMarketDayHigh: number;
    regularMarketDayRange: string;
    regularMarketVolume: number;
    marketCap: number;
    regularMarketTime: Date;
    coinImageUrl: string;
}

export interface CurrencyResponse {
    currency: CurrencyData[];
}

export interface CurrencyData {
    fromCurrency: string;
    toCurrency: string;
    name: string;
    high: string;
    low: string;
    bidVariation: string;
    percentageChange: string;
    bidPrice: string;
    askPrice: string;
    updatedAtTimestamp: Date;
    updatedAtDate: Date;
}

export interface TickerResponse {
    results: TickerData[];
}

export interface TickerData {
    symbol: string;
    shortName: string;
    longName: string;
    currency: string;
    regularMarketPrice: string;
    regularMarketDayHigh: number;
    regularMarketDayLow: number;
    regularMarketDayRange: string;
    regularMarketChange: number;
    regularMarketChangePercent: number;
    regularMarketTime: Date;
    marketCap: number;
    regularMarketVolume: number;
    regularMarketPreviousClose: number;
    regularMarketOpen: number;
    averageDailyVolume10Day: number;
    averageDailyVolume3Month: number;
    fiftyTwoWeekLowChange: number;
    fiftyTwoWeekRange: string;
    fiftyTwoWeekHighChange: number;
    fiftyTwoWeekHighChangePercent: number;
    fiftyTwoWeekLow: number;
    fiftyTwoWeekHigh: number;
    twoHundredDayAverage: number;
    twoHundredDayAverageChange: number;
    woHundredDayAverageChangePercent: number;
}

export interface Phone {
    ddd: string;
    number: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface ReportOrder {
    field: ReportOrderField;
    order: Order;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    bytes: Buffer | null;
    url: string | null;
}

export interface AdminUser {
    id: string;
    createdAt: Date;
    adminType: AdminType;
    name: string;
    email: string;
    documentNumber: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
    documentNumber: string;
}

export interface NewAdminUser {
    password: string;
    name: string;
    email: string;
    documentNumber: string;
}

export interface NewBankAccount {
    bankCode: string;
    agency: string;
    bankName: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    pix: string | null;
    pixType: PixType | null;
    socialReason: string | null;
    cnpj: string | null;
}

export interface BankAccount {
    id: string;
    bankCode: string;
    agency: string;
    bankName: string;
    agencyDv: string | null;
    account: string;
    accountDv: string | null;
    type: BankAccountType;
    pix: string | null;
    pixType: PixType | null;
    socialReason: string | null;
    cnpj: string | null;
}

export interface CoinActive {
    id: string;
    bitcoin: number | null;
    usdt: number | null;
    brl: number | null;
    savings: number | null;
    ibovespa: number | null;
    createdAt: Date | null;
}

export interface NewCoinMargin {
    currentPrice: number;
    sellPrice: number;
    buyPrice: number;
    date: Date;
}

export interface CoinMargin {
    id: string;
    currentPrice: number;
    sellPrice: number;
    buyPrice: number;
    date: Date;
}

export interface NewCoinProfit {
    returnedValue: number;
}

export interface NewCoinProfitHistory {
    returnedValue: number;
    entranceValue: number;
    coinCreatedDate: Date;
}

export interface CoinProfit {
    id: string;
    createdAt: Date;
    entranceValue: number;
    profitVariable: number;
    ring: string;
    returnedValue: number;
}

export interface Contact {
    name: string;
    message: string;
    phone: string;
    email: string;
}

export interface User {
    id: string;
    email: string;
    name: string;
    documentNumber: string | null;
    phone: string;
    documentImage: Image;
    proofOfAddress: Image;
    zipcode: string;
    city: string;
    complementary: string | null;
    countryCode: string;
    neighborhood: string;
    state: StateUF;
    street: string;
    streetNumber: string;
    invitedBy: UserInviter | null;
    invitationCode: number;
    acceptedAt: Date | null;
    blockedAt: Date | null;
    createdAt: Date;
}

export interface UserInviter {
    id: string;
    email: string;
    name: string;
}

export interface NewDeposit {
    user: User;
    amount: number;
    approvedAt: Date | null;
    receiptImage: UncertainImage;
    depositStatus: DepositStatus;
}

export interface Deposit {
    id: string;
    acceptBy: AdminUser | null;
    receiptImage: Image;
    priceDscCoin: number;
    brlPrice: number;
    createdAt: Date;
    amountApproved: number;
    user: User;
    amount: number;
    approvedAt: Date | null;
    depositStatus: DepositStatus;
}

export interface DepositDetails {
    userBankAccount: BankAccount;
    adminUserBankAccount: BankAccount;
    id: string;
    acceptBy: AdminUser | null;
    receiptImage: Image;
    priceDscCoin: number;
    brlPrice: number;
    createdAt: Date;
    amountApproved: number;
    user: User;
    amount: number;
    approvedAt: Date | null;
    depositStatus: DepositStatus;
}

export interface IndicationText {
    id: string;
    createdAt: Date;
    text: string;
}

export interface EditIndicationText {
    text: string;
}

export interface NewIndicationText {
    text: string;
}

export interface Poupanca {
    id: string;
    percentage: number;
    date: Date;
}

export interface NewPoupanca {
    percentage: number;
    date: Date;
}

export interface Report {
    userId: string;
    code: number;
    name: string;
    state: StateUF;
    countOfInvitedUsers: string;
    invitedDepositUsers: string;
    commissionEarnedAmount: string;
    commissionTable: CommissionTableType;
}

export interface TotalDscCoins {
    id: string;
    totalCoins: number;
    totalUsers: number;
    createdAt: Date;
}

export interface DSCCoinsStatics {
    totalCoins: number;
    totalUsers: number;
}

export interface UserDeleteRequest {
    id: string;
    user: User;
    acceptedAt: Date | null;
    createdAt: Date;
    deletedAt: Date | null;
}

export interface UserTransfer {
    id: string;
    sender: User;
    receiver: User;
    valueInUsd: number;
    coinQuantity: number;
}

export interface WalletMovement {
    id: string;
    movementType: MovementType;
    operation: Operation;
    value: number;
    description: string | null;
    processedAt: Date | null;
    createdAt: Date;
}

export interface Wallet {
    id: string;
    createdAt: Date;
    balance: number;
    quota: number;
    user: User;
}

export interface DSCWallet {
    balance: string;
    quota: string;
}

export interface Withdrawal {
    id: string;
    user: User;
    approvedAt: Date | null;
    acceptBy: AdminUser | null;
    status: WithdrawalStatus;
    bankAccount: BankAccount;
    createdAt: Date;
    amount: number;
    priceDscCoin: number;
    brlPrice: number;
    brlAmountApproved: number;
}

export enum AdminType {
    master = "master",
    withdraw = "withdraw",
    depositAndApprovals = "depositAndApprovals",
}

export function translateAdminType(enumAdminType: AdminType): string {
    switch (enumAdminType) {
        case AdminType.master: {
            return strings ? strings["enum"]["AdminType"]["master"] || AdminType.master : AdminType.master;
        }
        case AdminType.withdraw: {
            return strings ? strings["enum"]["AdminType"]["withdraw"] || AdminType.withdraw : AdminType.withdraw;
        }
        case AdminType.depositAndApprovals: {
            return strings ? strings["enum"]["AdminType"]["depositAndApprovals"] || AdminType.depositAndApprovals : AdminType.depositAndApprovals;
        }
    }
    return "";
}

export function allValuesAdminType(): AdminType[] {
    return [
        AdminType.master,
        AdminType.withdraw,
        AdminType.depositAndApprovals,
    ];
}

export function allTranslatedValuesAdminType(): string[] {
    return [
        translateAdminType(AdminType.master),
        translateAdminType(AdminType.withdraw),
        translateAdminType(AdminType.depositAndApprovals),
    ];
}

export function allDisplayableValuesAdminType(): string[] {
    return allTranslatedValuesAdminType().sort();
}

export function valueFromTranslationAdminType(translation: string): AdminType {
    const index = allTranslatedValuesAdminType().indexOf(translation);
    return allValuesAdminType()[index] || AdminType.master;
}

export enum MovementType {
    withdraw = "withdraw",
    deposit = "deposit",
    indication = "indication",
    mining = "mining",
    transfer = "transfer",
}

export function translateMovementType(enumMovementType: MovementType): string {
    switch (enumMovementType) {
        case MovementType.withdraw: {
            return strings ? strings["enum"]["MovementType"]["withdraw"] || MovementType.withdraw : MovementType.withdraw;
        }
        case MovementType.deposit: {
            return strings ? strings["enum"]["MovementType"]["deposit"] || MovementType.deposit : MovementType.deposit;
        }
        case MovementType.indication: {
            return strings ? strings["enum"]["MovementType"]["indication"] || MovementType.indication : MovementType.indication;
        }
        case MovementType.mining: {
            return strings ? strings["enum"]["MovementType"]["mining"] || MovementType.mining : MovementType.mining;
        }
        case MovementType.transfer: {
            return strings ? strings["enum"]["MovementType"]["transfer"] || MovementType.transfer : MovementType.transfer;
        }
    }
    return "";
}

export function allValuesMovementType(): MovementType[] {
    return [
        MovementType.withdraw,
        MovementType.deposit,
        MovementType.indication,
        MovementType.mining,
        MovementType.transfer,
    ];
}

export function allTranslatedValuesMovementType(): string[] {
    return [
        translateMovementType(MovementType.withdraw),
        translateMovementType(MovementType.deposit),
        translateMovementType(MovementType.indication),
        translateMovementType(MovementType.mining),
        translateMovementType(MovementType.transfer),
    ];
}

export function allDisplayableValuesMovementType(): string[] {
    return allTranslatedValuesMovementType().sort();
}

export function valueFromTranslationMovementType(translation: string): MovementType {
    const index = allTranslatedValuesMovementType().indexOf(translation);
    return allValuesMovementType()[index] || MovementType.withdraw;
}

export enum Operation {
    entrance = "entrance",
    exit = "exit",
}

export function translateOperation(enumOperation: Operation): string {
    switch (enumOperation) {
        case Operation.entrance: {
            return strings ? strings["enum"]["Operation"]["entrance"] || Operation.entrance : Operation.entrance;
        }
        case Operation.exit: {
            return strings ? strings["enum"]["Operation"]["exit"] || Operation.exit : Operation.exit;
        }
    }
    return "";
}

export function allValuesOperation(): Operation[] {
    return [
        Operation.entrance,
        Operation.exit,
    ];
}

export function allTranslatedValuesOperation(): string[] {
    return [
        translateOperation(Operation.entrance),
        translateOperation(Operation.exit),
    ];
}

export function allDisplayableValuesOperation(): string[] {
    return allTranslatedValuesOperation().sort();
}

export function valueFromTranslationOperation(translation: string): Operation {
    const index = allTranslatedValuesOperation().indexOf(translation);
    return allValuesOperation()[index] || Operation.entrance;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum PixType {
    Phone = "Phone",
    email = "email",
    cpf = "cpf",
    random = "random",
}

export function translatePixType(enumPixType: PixType): string {
    switch (enumPixType) {
        case PixType.Phone: {
            return strings ? strings["enum"]["PixType"]["Phone"] || PixType.Phone : PixType.Phone;
        }
        case PixType.email: {
            return strings ? strings["enum"]["PixType"]["email"] || PixType.email : PixType.email;
        }
        case PixType.cpf: {
            return strings ? strings["enum"]["PixType"]["cpf"] || PixType.cpf : PixType.cpf;
        }
        case PixType.random: {
            return strings ? strings["enum"]["PixType"]["random"] || PixType.random : PixType.random;
        }
    }
    return "";
}

export function allValuesPixType(): PixType[] {
    return [
        PixType.Phone,
        PixType.email,
        PixType.cpf,
        PixType.random,
    ];
}

export function allTranslatedValuesPixType(): string[] {
    return [
        translatePixType(PixType.Phone),
        translatePixType(PixType.email),
        translatePixType(PixType.cpf),
        translatePixType(PixType.random),
    ];
}

export function allDisplayableValuesPixType(): string[] {
    return allTranslatedValuesPixType().sort();
}

export function valueFromTranslationPixType(translation: string): PixType {
    const index = allTranslatedValuesPixType().indexOf(translation);
    return allValuesPixType()[index] || PixType.Phone;
}

export enum DepositStatus {
    approved = "approved",
    pending = "pending",
    refused = "refused",
}

export function translateDepositStatus(enumDepositStatus: DepositStatus): string {
    switch (enumDepositStatus) {
        case DepositStatus.approved: {
            return strings ? strings["enum"]["DepositStatus"]["approved"] || DepositStatus.approved : DepositStatus.approved;
        }
        case DepositStatus.pending: {
            return strings ? strings["enum"]["DepositStatus"]["pending"] || DepositStatus.pending : DepositStatus.pending;
        }
        case DepositStatus.refused: {
            return strings ? strings["enum"]["DepositStatus"]["refused"] || DepositStatus.refused : DepositStatus.refused;
        }
    }
    return "";
}

export function allValuesDepositStatus(): DepositStatus[] {
    return [
        DepositStatus.approved,
        DepositStatus.pending,
        DepositStatus.refused,
    ];
}

export function allTranslatedValuesDepositStatus(): string[] {
    return [
        translateDepositStatus(DepositStatus.approved),
        translateDepositStatus(DepositStatus.pending),
        translateDepositStatus(DepositStatus.refused),
    ];
}

export function allDisplayableValuesDepositStatus(): string[] {
    return allTranslatedValuesDepositStatus().sort();
}

export function valueFromTranslationDepositStatus(translation: string): DepositStatus {
    const index = allTranslatedValuesDepositStatus().indexOf(translation);
    return allValuesDepositStatus()[index] || DepositStatus.approved;
}

export enum WithdrawalStatus {
    approved = "approved",
    pending = "pending",
    refused = "refused",
}

export function translateWithdrawalStatus(enumWithdrawalStatus: WithdrawalStatus): string {
    switch (enumWithdrawalStatus) {
        case WithdrawalStatus.approved: {
            return strings ? strings["enum"]["WithdrawalStatus"]["approved"] || WithdrawalStatus.approved : WithdrawalStatus.approved;
        }
        case WithdrawalStatus.pending: {
            return strings ? strings["enum"]["WithdrawalStatus"]["pending"] || WithdrawalStatus.pending : WithdrawalStatus.pending;
        }
        case WithdrawalStatus.refused: {
            return strings ? strings["enum"]["WithdrawalStatus"]["refused"] || WithdrawalStatus.refused : WithdrawalStatus.refused;
        }
    }
    return "";
}

export function allValuesWithdrawalStatus(): WithdrawalStatus[] {
    return [
        WithdrawalStatus.approved,
        WithdrawalStatus.pending,
        WithdrawalStatus.refused,
    ];
}

export function allTranslatedValuesWithdrawalStatus(): string[] {
    return [
        translateWithdrawalStatus(WithdrawalStatus.approved),
        translateWithdrawalStatus(WithdrawalStatus.pending),
        translateWithdrawalStatus(WithdrawalStatus.refused),
    ];
}

export function allDisplayableValuesWithdrawalStatus(): string[] {
    return allTranslatedValuesWithdrawalStatus().sort();
}

export function valueFromTranslationWithdrawalStatus(translation: string): WithdrawalStatus {
    const index = allTranslatedValuesWithdrawalStatus().indexOf(translation);
    return allValuesWithdrawalStatus()[index] || WithdrawalStatus.approved;
}

export enum PeriodFilter {
    oneMonth = "oneMonth",
    sixMonths = "sixMonths",
    oneYear = "oneYear",
    twoYears = "twoYears",
    fiveYears = "fiveYears",
}

export function translatePeriodFilter(enumPeriodFilter: PeriodFilter): string {
    switch (enumPeriodFilter) {
        case PeriodFilter.oneMonth: {
            return strings ? strings["enum"]["PeriodFilter"]["oneMonth"] || PeriodFilter.oneMonth : PeriodFilter.oneMonth;
        }
        case PeriodFilter.sixMonths: {
            return strings ? strings["enum"]["PeriodFilter"]["sixMonths"] || PeriodFilter.sixMonths : PeriodFilter.sixMonths;
        }
        case PeriodFilter.oneYear: {
            return strings ? strings["enum"]["PeriodFilter"]["oneYear"] || PeriodFilter.oneYear : PeriodFilter.oneYear;
        }
        case PeriodFilter.twoYears: {
            return strings ? strings["enum"]["PeriodFilter"]["twoYears"] || PeriodFilter.twoYears : PeriodFilter.twoYears;
        }
        case PeriodFilter.fiveYears: {
            return strings ? strings["enum"]["PeriodFilter"]["fiveYears"] || PeriodFilter.fiveYears : PeriodFilter.fiveYears;
        }
    }
    return "";
}

export function allValuesPeriodFilter(): PeriodFilter[] {
    return [
        PeriodFilter.oneMonth,
        PeriodFilter.sixMonths,
        PeriodFilter.oneYear,
        PeriodFilter.twoYears,
        PeriodFilter.fiveYears,
    ];
}

export function allTranslatedValuesPeriodFilter(): string[] {
    return [
        translatePeriodFilter(PeriodFilter.oneMonth),
        translatePeriodFilter(PeriodFilter.sixMonths),
        translatePeriodFilter(PeriodFilter.oneYear),
        translatePeriodFilter(PeriodFilter.twoYears),
        translatePeriodFilter(PeriodFilter.fiveYears),
    ];
}

export function allDisplayableValuesPeriodFilter(): string[] {
    return allTranslatedValuesPeriodFilter().sort();
}

export function valueFromTranslationPeriodFilter(translation: string): PeriodFilter {
    const index = allTranslatedValuesPeriodFilter().indexOf(translation);
    return allValuesPeriodFilter()[index] || PeriodFilter.oneMonth;
}

export enum CommissionTableType {
    typeOne = "typeOne",
    typeTwo = "typeTwo",
    typeThree = "typeThree",
}

export function translateCommissionTableType(enumCommissionTableType: CommissionTableType): string {
    switch (enumCommissionTableType) {
        case CommissionTableType.typeOne: {
            return strings ? strings["enum"]["CommissionTableType"]["typeOne"] || CommissionTableType.typeOne : CommissionTableType.typeOne;
        }
        case CommissionTableType.typeTwo: {
            return strings ? strings["enum"]["CommissionTableType"]["typeTwo"] || CommissionTableType.typeTwo : CommissionTableType.typeTwo;
        }
        case CommissionTableType.typeThree: {
            return strings ? strings["enum"]["CommissionTableType"]["typeThree"] || CommissionTableType.typeThree : CommissionTableType.typeThree;
        }
    }
    return "";
}

export function allValuesCommissionTableType(): CommissionTableType[] {
    return [
        CommissionTableType.typeOne,
        CommissionTableType.typeTwo,
        CommissionTableType.typeThree,
    ];
}

export function allTranslatedValuesCommissionTableType(): string[] {
    return [
        translateCommissionTableType(CommissionTableType.typeOne),
        translateCommissionTableType(CommissionTableType.typeTwo),
        translateCommissionTableType(CommissionTableType.typeThree),
    ];
}

export function allDisplayableValuesCommissionTableType(): string[] {
    return allTranslatedValuesCommissionTableType().sort();
}

export function valueFromTranslationCommissionTableType(translation: string): CommissionTableType {
    const index = allTranslatedValuesCommissionTableType().indexOf(translation);
    return allValuesCommissionTableType()[index] || CommissionTableType.typeOne;
}

export enum Order {
    ASC = "ASC",
    DESC = "DESC",
}

export function translateOrder(enumOrder: Order): string {
    switch (enumOrder) {
        case Order.ASC: {
            return strings ? strings["enum"]["Order"]["ASC"] || Order.ASC : Order.ASC;
        }
        case Order.DESC: {
            return strings ? strings["enum"]["Order"]["DESC"] || Order.DESC : Order.DESC;
        }
    }
    return "";
}

export function allValuesOrder(): Order[] {
    return [
        Order.ASC,
        Order.DESC,
    ];
}

export function allTranslatedValuesOrder(): string[] {
    return [
        translateOrder(Order.ASC),
        translateOrder(Order.DESC),
    ];
}

export function allDisplayableValuesOrder(): string[] {
    return allTranslatedValuesOrder().sort();
}

export function valueFromTranslationOrder(translation: string): Order {
    const index = allTranslatedValuesOrder().indexOf(translation);
    return allValuesOrder()[index] || Order.ASC;
}

export enum ReportOrderField {
    countOfInvitedUsers = "countOfInvitedUsers",
    invitedDepositUsers = "invitedDepositUsers",
}

export function translateReportOrderField(enumReportOrderField: ReportOrderField): string {
    switch (enumReportOrderField) {
        case ReportOrderField.countOfInvitedUsers: {
            return strings ? strings["enum"]["ReportOrderField"]["countOfInvitedUsers"] || ReportOrderField.countOfInvitedUsers : ReportOrderField.countOfInvitedUsers;
        }
        case ReportOrderField.invitedDepositUsers: {
            return strings ? strings["enum"]["ReportOrderField"]["invitedDepositUsers"] || ReportOrderField.invitedDepositUsers : ReportOrderField.invitedDepositUsers;
        }
    }
    return "";
}

export function allValuesReportOrderField(): ReportOrderField[] {
    return [
        ReportOrderField.countOfInvitedUsers,
        ReportOrderField.invitedDepositUsers,
    ];
}

export function allTranslatedValuesReportOrderField(): string[] {
    return [
        translateReportOrderField(ReportOrderField.countOfInvitedUsers),
        translateReportOrderField(ReportOrderField.invitedDepositUsers),
    ];
}

export function allDisplayableValuesReportOrderField(): string[] {
    return allTranslatedValuesReportOrderField().sort();
}

export function valueFromTranslationReportOrderField(translation: string): ReportOrderField {
    const index = allTranslatedValuesReportOrderField().indexOf(translation);
    return allValuesReportOrderField()[index] || ReportOrderField.countOfInvitedUsers;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum FileFormat {
    pdf = "pdf",
}

export function translateFileFormat(enumFileFormat: FileFormat): string {
    switch (enumFileFormat) {
        case FileFormat.pdf: {
            return strings ? strings["enum"]["FileFormat"]["pdf"] || FileFormat.pdf : FileFormat.pdf;
        }
    }
    return "";
}

export function allValuesFileFormat(): FileFormat[] {
    return [
        FileFormat.pdf,
    ];
}

export function allTranslatedValuesFileFormat(): string[] {
    return [
        translateFileFormat(FileFormat.pdf),
    ];
}

export function allDisplayableValuesFileFormat(): string[] {
    return allTranslatedValuesFileFormat().sort();
}

export function valueFromTranslationFileFormat(translation: string): FileFormat {
    const index = allTranslatedValuesFileFormat().indexOf(translation);
    return allValuesFileFormat()[index] || FileFormat.pdf;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    WaitingApproval = "WaitingApproval",
    RequestDenied = "RequestDenied",
    NecessaryAmount = "NecessaryAmount",
    NotRegistered = "NotRegistered",
    InsufficientBalance = "InsufficientBalance",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    CNPJAlreadyRegistered = "CNPJAlreadyRegistered",
    DepositAlreadyApproved = "DepositAlreadyApproved",
    NotLoggedIn = "NotLoggedIn",
    InvalidAccessToken = "InvalidAccessToken",
    AlreadyBlocked = "AlreadyBlocked",
    AlreadyUnblocked = "AlreadyUnblocked",
    AlreadyRequested = "AlreadyRequested",
    LimitHasBeenReached = "LimitHasBeenReached",
    TotalCoinsNotUpdated = "TotalCoinsNotUpdated",
    UnprocessedWalletMovementsExists = "UnprocessedWalletMovementsExists",
    DynamicLinkError = "DynamicLinkError",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.WaitingApproval: {
            return strings ? strings["enum"]["ErrorType"]["WaitingApproval"] || ErrorType.WaitingApproval : ErrorType.WaitingApproval;
        }
        case ErrorType.RequestDenied: {
            return strings ? strings["enum"]["ErrorType"]["RequestDenied"] || ErrorType.RequestDenied : ErrorType.RequestDenied;
        }
        case ErrorType.NecessaryAmount: {
            return strings ? strings["enum"]["ErrorType"]["NecessaryAmount"] || ErrorType.NecessaryAmount : ErrorType.NecessaryAmount;
        }
        case ErrorType.NotRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NotRegistered"] || ErrorType.NotRegistered : ErrorType.NotRegistered;
        }
        case ErrorType.InsufficientBalance: {
            return strings ? strings["enum"]["ErrorType"]["InsufficientBalance"] || ErrorType.InsufficientBalance : ErrorType.InsufficientBalance;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.CNPJAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CNPJAlreadyRegistered"] || ErrorType.CNPJAlreadyRegistered : ErrorType.CNPJAlreadyRegistered;
        }
        case ErrorType.DepositAlreadyApproved: {
            return strings ? strings["enum"]["ErrorType"]["DepositAlreadyApproved"] || ErrorType.DepositAlreadyApproved : ErrorType.DepositAlreadyApproved;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.InvalidAccessToken: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccessToken"] || ErrorType.InvalidAccessToken : ErrorType.InvalidAccessToken;
        }
        case ErrorType.AlreadyBlocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyBlocked"] || ErrorType.AlreadyBlocked : ErrorType.AlreadyBlocked;
        }
        case ErrorType.AlreadyUnblocked: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyUnblocked"] || ErrorType.AlreadyUnblocked : ErrorType.AlreadyUnblocked;
        }
        case ErrorType.AlreadyRequested: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRequested"] || ErrorType.AlreadyRequested : ErrorType.AlreadyRequested;
        }
        case ErrorType.LimitHasBeenReached: {
            return strings ? strings["enum"]["ErrorType"]["LimitHasBeenReached"] || ErrorType.LimitHasBeenReached : ErrorType.LimitHasBeenReached;
        }
        case ErrorType.TotalCoinsNotUpdated: {
            return strings ? strings["enum"]["ErrorType"]["TotalCoinsNotUpdated"] || ErrorType.TotalCoinsNotUpdated : ErrorType.TotalCoinsNotUpdated;
        }
        case ErrorType.UnprocessedWalletMovementsExists: {
            return strings ? strings["enum"]["ErrorType"]["UnprocessedWalletMovementsExists"] || ErrorType.UnprocessedWalletMovementsExists : ErrorType.UnprocessedWalletMovementsExists;
        }
        case ErrorType.DynamicLinkError: {
            return strings ? strings["enum"]["ErrorType"]["DynamicLinkError"] || ErrorType.DynamicLinkError : ErrorType.DynamicLinkError;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.WaitingApproval,
        ErrorType.RequestDenied,
        ErrorType.NecessaryAmount,
        ErrorType.NotRegistered,
        ErrorType.InsufficientBalance,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.CNPJAlreadyRegistered,
        ErrorType.DepositAlreadyApproved,
        ErrorType.NotLoggedIn,
        ErrorType.InvalidAccessToken,
        ErrorType.AlreadyBlocked,
        ErrorType.AlreadyUnblocked,
        ErrorType.AlreadyRequested,
        ErrorType.LimitHasBeenReached,
        ErrorType.TotalCoinsNotUpdated,
        ErrorType.UnprocessedWalletMovementsExists,
        ErrorType.DynamicLinkError,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.WaitingApproval),
        translateErrorType(ErrorType.RequestDenied),
        translateErrorType(ErrorType.NecessaryAmount),
        translateErrorType(ErrorType.NotRegistered),
        translateErrorType(ErrorType.InsufficientBalance),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.CNPJAlreadyRegistered),
        translateErrorType(ErrorType.DepositAlreadyApproved),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.InvalidAccessToken),
        translateErrorType(ErrorType.AlreadyBlocked),
        translateErrorType(ErrorType.AlreadyUnblocked),
        translateErrorType(ErrorType.AlreadyRequested),
        translateErrorType(ErrorType.LimitHasBeenReached),
        translateErrorType(ErrorType.TotalCoinsNotUpdated),
        translateErrorType(ErrorType.UnprocessedWalletMovementsExists),
        translateErrorType(ErrorType.DynamicLinkError),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadUncertainFile(file: UncertainFile, fileFormat: FileFormat | null, progress?: (progress: number) => void): Promise<string> {
    const args = {
        file: {
            bytes: file.bytes === null || file.bytes === undefined ? null : file.bytes.toString("base64"),
            url: file.url === null || file.url === undefined ? null : file.url,
        },
        fileFormat: fileFormat === null || fileFormat === undefined ? null : fileFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return ret;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function getAllAdminUsersMasters(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsersMasters", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        adminType: e.adminType,
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function getAllAdminUsersWithdrawals(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsersWithdrawals", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        adminType: e.adminType,
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function getAllAdminUsersDepositAndApproval(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllAdminUsersDepositAndApproval", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        adminType: e.adminType,
        name: e.name,
        email: e.email,
        documentNumber: e.documentNumber,
    }));
}

export async function createAdminUserMaster(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            documentNumber: newAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createAdminUserMaster", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function createAdminUserWithdraw(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            documentNumber: newAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createAdminUserWithdraw", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function createAdminUserDepositAndApproval(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            name: newAdminUser.name,
            email: newAdminUser.email,
            documentNumber: newAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createAdminUserDepositAndApproval", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            documentNumber: editAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function deleteAdminUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminUser", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        adminType: ret.adminType,
        name: ret.name,
        email: ret.email,
        documentNumber: ret.documentNumber,
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function createAdminBankAccount(newBankAccount: NewBankAccount, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        newBankAccount: {
            bankCode: newBankAccount.bankCode,
            agency: newBankAccount.agency,
            bankName: newBankAccount.bankName,
            agencyDv: newBankAccount.agencyDv === null || newBankAccount.agencyDv === undefined ? null : newBankAccount.agencyDv,
            account: newBankAccount.account,
            accountDv: newBankAccount.accountDv === null || newBankAccount.accountDv === undefined ? null : newBankAccount.accountDv,
            type: newBankAccount.type,
            pix: newBankAccount.pix === null || newBankAccount.pix === undefined ? null : newBankAccount.pix,
            pixType: newBankAccount.pixType === null || newBankAccount.pixType === undefined ? null : newBankAccount.pixType,
            socialReason: newBankAccount.socialReason === null || newBankAccount.socialReason === undefined ? null : newBankAccount.socialReason,
            cnpj: newBankAccount.cnpj === null || newBankAccount.cnpj === undefined ? null : newBankAccount.cnpj,
        },
    };
    const ret = await makeRequest({name: "createAdminBankAccount", args, progress});
    return {
        id: ret.id,
        bankCode: ret.bankCode,
        agency: ret.agency,
        bankName: ret.bankName,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        pix: ret.pix === null || ret.pix === undefined ? null : ret.pix,
        pixType: ret.pixType === null || ret.pixType === undefined ? null : ret.pixType,
        socialReason: ret.socialReason === null || ret.socialReason === undefined ? null : ret.socialReason,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
    };
}

export async function deleteBankAccount(bankAccountId: string, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        bankAccountId: bankAccountId,
    };
    const ret = await makeRequest({name: "deleteBankAccount", args, progress});
    return {
        id: ret.id,
        bankCode: ret.bankCode,
        agency: ret.agency,
        bankName: ret.bankName,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        pix: ret.pix === null || ret.pix === undefined ? null : ret.pix,
        pixType: ret.pixType === null || ret.pixType === undefined ? null : ret.pixType,
        socialReason: ret.socialReason === null || ret.socialReason === undefined ? null : ret.socialReason,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
    };
}

export async function editAdminBankAccount(id: string, editBankAccount: NewBankAccount, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        id: id,
        editBankAccount: {
            bankCode: editBankAccount.bankCode,
            agency: editBankAccount.agency,
            bankName: editBankAccount.bankName,
            agencyDv: editBankAccount.agencyDv === null || editBankAccount.agencyDv === undefined ? null : editBankAccount.agencyDv,
            account: editBankAccount.account,
            accountDv: editBankAccount.accountDv === null || editBankAccount.accountDv === undefined ? null : editBankAccount.accountDv,
            type: editBankAccount.type,
            pix: editBankAccount.pix === null || editBankAccount.pix === undefined ? null : editBankAccount.pix,
            pixType: editBankAccount.pixType === null || editBankAccount.pixType === undefined ? null : editBankAccount.pixType,
            socialReason: editBankAccount.socialReason === null || editBankAccount.socialReason === undefined ? null : editBankAccount.socialReason,
            cnpj: editBankAccount.cnpj === null || editBankAccount.cnpj === undefined ? null : editBankAccount.cnpj,
        },
    };
    const ret = await makeRequest({name: "editAdminBankAccount", args, progress});
    return {
        id: ret.id,
        bankCode: ret.bankCode,
        agency: ret.agency,
        bankName: ret.bankName,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        pix: ret.pix === null || ret.pix === undefined ? null : ret.pix,
        pixType: ret.pixType === null || ret.pixType === undefined ? null : ret.pixType,
        socialReason: ret.socialReason === null || ret.socialReason === undefined ? null : ret.socialReason,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
    };
}

export async function getAllAdminBankAccounts(progress?: (progress: number) => void): Promise<BankAccount[]> {
    const ret = await makeRequest({name: "getAllAdminBankAccounts", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        bankCode: e.bankCode,
        agency: e.agency,
        bankName: e.bankName,
        agencyDv: e.agencyDv === null || e.agencyDv === undefined ? null : e.agencyDv,
        account: e.account,
        accountDv: e.accountDv === null || e.accountDv === undefined ? null : e.accountDv,
        type: e.type,
        pix: e.pix === null || e.pix === undefined ? null : e.pix,
        pixType: e.pixType === null || e.pixType === undefined ? null : e.pixType,
        socialReason: e.socialReason === null || e.socialReason === undefined ? null : e.socialReason,
        cnpj: e.cnpj === null || e.cnpj === undefined ? null : e.cnpj,
    }));
}

export async function getOneBankAccountById(bankAccountId: string, progress?: (progress: number) => void): Promise<BankAccount> {
    const args = {
        bankAccountId: bankAccountId,
    };
    const ret = await makeRequest({name: "getOneBankAccountById", args, progress});
    return {
        id: ret.id,
        bankCode: ret.bankCode,
        agency: ret.agency,
        bankName: ret.bankName,
        agencyDv: ret.agencyDv === null || ret.agencyDv === undefined ? null : ret.agencyDv,
        account: ret.account,
        accountDv: ret.accountDv === null || ret.accountDv === undefined ? null : ret.accountDv,
        type: ret.type,
        pix: ret.pix === null || ret.pix === undefined ? null : ret.pix,
        pixType: ret.pixType === null || ret.pixType === undefined ? null : ret.pixType,
        socialReason: ret.socialReason === null || ret.socialReason === undefined ? null : ret.socialReason,
        cnpj: ret.cnpj === null || ret.cnpj === undefined ? null : ret.cnpj,
    };
}

export async function getAllCoinMarginHistory(pageOffSet: number, progress?: (progress: number) => void): Promise<CoinMargin[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllCoinMarginHistory", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        currentPrice: e.currentPrice,
        sellPrice: e.sellPrice,
        buyPrice: e.buyPrice,
        date: new Date(e.date + "Z"),
    }));
}

export async function getEarliestCoinMargin(progress?: (progress: number) => void): Promise<CoinMargin> {
    const ret = await makeRequest({name: "getEarliestCoinMargin", args: {}, progress});
    return {
        id: ret.id,
        currentPrice: ret.currentPrice,
        sellPrice: ret.sellPrice,
        buyPrice: ret.buyPrice,
        date: new Date(ret.date + "Z"),
    };
}

export async function createCoinMargin(newCoinMargin: NewCoinMargin, progress?: (progress: number) => void): Promise<CoinMargin> {
    const args = {
        newCoinMargin: {
            currentPrice: newCoinMargin.currentPrice,
            sellPrice: newCoinMargin.sellPrice,
            buyPrice: newCoinMargin.buyPrice,
            date: (typeof newCoinMargin.date === "string" && (newCoinMargin.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (newCoinMargin.date as any).replace("Z", "") : newCoinMargin.date.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "createCoinMargin", args, progress});
    return {
        id: ret.id,
        currentPrice: ret.currentPrice,
        sellPrice: ret.sellPrice,
        buyPrice: ret.buyPrice,
        date: new Date(ret.date + "Z"),
    };
}

export async function createCoinProfit(NewCoinProfit: NewCoinProfit, progress?: (progress: number) => void): Promise<CoinProfit> {
    const args = {
        NewCoinProfit: {
            returnedValue: NewCoinProfit.returnedValue,
        },
    };
    const ret = await makeRequest({name: "createCoinProfit", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        entranceValue: ret.entranceValue,
        profitVariable: ret.profitVariable,
        ring: ret.ring,
        returnedValue: ret.returnedValue,
    };
}

export async function createCoinProfitHistory(NewCoinProfit: NewCoinProfitHistory, progress?: (progress: number) => void): Promise<CoinProfit> {
    const args = {
        NewCoinProfit: {
            returnedValue: NewCoinProfit.returnedValue,
            entranceValue: NewCoinProfit.entranceValue,
            coinCreatedDate: (typeof NewCoinProfit.coinCreatedDate === "string" && (NewCoinProfit.coinCreatedDate as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (NewCoinProfit.coinCreatedDate as any).replace("Z", "") : NewCoinProfit.coinCreatedDate.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "createCoinProfitHistory", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        entranceValue: ret.entranceValue,
        profitVariable: ret.profitVariable,
        ring: ret.ring,
        returnedValue: ret.returnedValue,
    };
}

export async function getAllCoinProfit(pageOffSet: number, periodFilter: PeriodFilter | null, progress?: (progress: number) => void): Promise<CoinProfit[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        periodFilter: periodFilter === null || periodFilter === undefined ? null : periodFilter,
    };
    const ret = await makeRequest({name: "getAllCoinProfit", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        entranceValue: e.entranceValue,
        profitVariable: e.profitVariable,
        ring: e.ring,
        returnedValue: e.returnedValue,
    }));
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        documentImage: {
            thumb: {
                width: ret.documentImage.thumb.width || 0,
                height: ret.documentImage.thumb.height || 0,
                url: ret.documentImage.thumb.url,
            },
            width: ret.documentImage.width || 0,
            height: ret.documentImage.height || 0,
            url: ret.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: ret.proofOfAddress.thumb.width || 0,
                height: ret.proofOfAddress.thumb.height || 0,
                url: ret.proofOfAddress.thumb.url,
            },
            width: ret.proofOfAddress.width || 0,
            height: ret.proofOfAddress.height || 0,
            url: ret.proofOfAddress.url,
        },
        zipcode: ret.zipcode,
        city: ret.city,
        complementary: ret.complementary === null || ret.complementary === undefined ? null : ret.complementary,
        countryCode: ret.countryCode,
        neighborhood: ret.neighborhood,
        state: ret.state,
        street: ret.street,
        streetNumber: ret.streetNumber,
        invitedBy: ret.invitedBy === null || ret.invitedBy === undefined ? null : {
            id: ret.invitedBy.id,
            email: ret.invitedBy.email,
            name: ret.invitedBy.name,
        },
        invitationCode: ret.invitationCode || 0,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function changeUserBlockStatus(id: string, willBeBlocked: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        willBeBlocked: !!willBeBlocked,
    };
    const ret = await makeRequest({name: "changeUserBlockStatus", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        documentImage: {
            thumb: {
                width: ret.documentImage.thumb.width || 0,
                height: ret.documentImage.thumb.height || 0,
                url: ret.documentImage.thumb.url,
            },
            width: ret.documentImage.width || 0,
            height: ret.documentImage.height || 0,
            url: ret.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: ret.proofOfAddress.thumb.width || 0,
                height: ret.proofOfAddress.thumb.height || 0,
                url: ret.proofOfAddress.thumb.url,
            },
            width: ret.proofOfAddress.width || 0,
            height: ret.proofOfAddress.height || 0,
            url: ret.proofOfAddress.url,
        },
        zipcode: ret.zipcode,
        city: ret.city,
        complementary: ret.complementary === null || ret.complementary === undefined ? null : ret.complementary,
        countryCode: ret.countryCode,
        neighborhood: ret.neighborhood,
        state: ret.state,
        street: ret.street,
        streetNumber: ret.streetNumber,
        invitedBy: ret.invitedBy === null || ret.invitedBy === undefined ? null : {
            id: ret.invitedBy.id,
            email: ret.invitedBy.email,
            name: ret.invitedBy.name,
        },
        invitationCode: ret.invitationCode || 0,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function approveOrRefuseUser(id: string, willBeApproved: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        willBeApproved: !!willBeApproved,
    };
    const ret = await makeRequest({name: "approveOrRefuseUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        documentImage: {
            thumb: {
                width: ret.documentImage.thumb.width || 0,
                height: ret.documentImage.thumb.height || 0,
                url: ret.documentImage.thumb.url,
            },
            width: ret.documentImage.width || 0,
            height: ret.documentImage.height || 0,
            url: ret.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: ret.proofOfAddress.thumb.width || 0,
                height: ret.proofOfAddress.thumb.height || 0,
                url: ret.proofOfAddress.thumb.url,
            },
            width: ret.proofOfAddress.width || 0,
            height: ret.proofOfAddress.height || 0,
            url: ret.proofOfAddress.url,
        },
        zipcode: ret.zipcode,
        city: ret.city,
        complementary: ret.complementary === null || ret.complementary === undefined ? null : ret.complementary,
        countryCode: ret.countryCode,
        neighborhood: ret.neighborhood,
        state: ret.state,
        street: ret.street,
        streetNumber: ret.streetNumber,
        invitedBy: ret.invitedBy === null || ret.invitedBy === undefined ? null : {
            id: ret.invitedBy.id,
            email: ret.invitedBy.email,
            name: ret.invitedBy.name,
        },
        invitationCode: ret.invitationCode || 0,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllPendingUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllPendingUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        phone: e.phone,
        documentImage: {
            thumb: {
                width: e.documentImage.thumb.width || 0,
                height: e.documentImage.thumb.height || 0,
                url: e.documentImage.thumb.url,
            },
            width: e.documentImage.width || 0,
            height: e.documentImage.height || 0,
            url: e.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: e.proofOfAddress.thumb.width || 0,
                height: e.proofOfAddress.thumb.height || 0,
                url: e.proofOfAddress.thumb.url,
            },
            width: e.proofOfAddress.width || 0,
            height: e.proofOfAddress.height || 0,
            url: e.proofOfAddress.url,
        },
        zipcode: e.zipcode,
        city: e.city,
        complementary: e.complementary === null || e.complementary === undefined ? null : e.complementary,
        countryCode: e.countryCode,
        neighborhood: e.neighborhood,
        state: e.state,
        street: e.street,
        streetNumber: e.streetNumber,
        invitedBy: e.invitedBy === null || e.invitedBy === undefined ? null : {
            id: e.invitedBy.id,
            email: e.invitedBy.email,
            name: e.invitedBy.name,
        },
        invitationCode: e.invitationCode || 0,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(e.blockedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getTotalUsersWithBalance(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalUsersWithBalance", args: {}, progress});
    return ret || 0;
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        documentImage: {
            thumb: {
                width: ret.documentImage.thumb.width || 0,
                height: ret.documentImage.thumb.height || 0,
                url: ret.documentImage.thumb.url,
            },
            width: ret.documentImage.width || 0,
            height: ret.documentImage.height || 0,
            url: ret.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: ret.proofOfAddress.thumb.width || 0,
                height: ret.proofOfAddress.thumb.height || 0,
                url: ret.proofOfAddress.thumb.url,
            },
            width: ret.proofOfAddress.width || 0,
            height: ret.proofOfAddress.height || 0,
            url: ret.proofOfAddress.url,
        },
        zipcode: ret.zipcode,
        city: ret.city,
        complementary: ret.complementary === null || ret.complementary === undefined ? null : ret.complementary,
        countryCode: ret.countryCode,
        neighborhood: ret.neighborhood,
        state: ret.state,
        street: ret.street,
        streetNumber: ret.streetNumber,
        invitedBy: ret.invitedBy === null || ret.invitedBy === undefined ? null : {
            id: ret.invitedBy.id,
            email: ret.invitedBy.email,
            name: ret.invitedBy.name,
        },
        invitationCode: ret.invitationCode || 0,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllUsers(pageOffSet: number, documentNumber: string | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        phone: e.phone,
        documentImage: {
            thumb: {
                width: e.documentImage.thumb.width || 0,
                height: e.documentImage.thumb.height || 0,
                url: e.documentImage.thumb.url,
            },
            width: e.documentImage.width || 0,
            height: e.documentImage.height || 0,
            url: e.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: e.proofOfAddress.thumb.width || 0,
                height: e.proofOfAddress.thumb.height || 0,
                url: e.proofOfAddress.thumb.url,
            },
            width: e.proofOfAddress.width || 0,
            height: e.proofOfAddress.height || 0,
            url: e.proofOfAddress.url,
        },
        zipcode: e.zipcode,
        city: e.city,
        complementary: e.complementary === null || e.complementary === undefined ? null : e.complementary,
        countryCode: e.countryCode,
        neighborhood: e.neighborhood,
        state: e.state,
        street: e.street,
        streetNumber: e.streetNumber,
        invitedBy: e.invitedBy === null || e.invitedBy === undefined ? null : {
            id: e.invitedBy.id,
            email: e.invitedBy.email,
            name: e.invitedBy.name,
        },
        invitationCode: e.invitationCode || 0,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(e.blockedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllAcceptedUsers(pageOffSet: number, documentNumber: string | null, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllAcceptedUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        phone: e.phone,
        documentImage: {
            thumb: {
                width: e.documentImage.thumb.width || 0,
                height: e.documentImage.thumb.height || 0,
                url: e.documentImage.thumb.url,
            },
            width: e.documentImage.width || 0,
            height: e.documentImage.height || 0,
            url: e.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: e.proofOfAddress.thumb.width || 0,
                height: e.proofOfAddress.thumb.height || 0,
                url: e.proofOfAddress.thumb.url,
            },
            width: e.proofOfAddress.width || 0,
            height: e.proofOfAddress.height || 0,
            url: e.proofOfAddress.url,
        },
        zipcode: e.zipcode,
        city: e.city,
        complementary: e.complementary === null || e.complementary === undefined ? null : e.complementary,
        countryCode: e.countryCode,
        neighborhood: e.neighborhood,
        state: e.state,
        street: e.street,
        streetNumber: e.streetNumber,
        invitedBy: e.invitedBy === null || e.invitedBy === undefined ? null : {
            id: e.invitedBy.id,
            email: e.invitedBy.email,
            name: e.invitedBy.name,
        },
        invitationCode: e.invitationCode || 0,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(e.blockedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getAllInvitedUsers(pageOffSet: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllInvitedUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        phone: e.phone,
        documentImage: {
            thumb: {
                width: e.documentImage.thumb.width || 0,
                height: e.documentImage.thumb.height || 0,
                url: e.documentImage.thumb.url,
            },
            width: e.documentImage.width || 0,
            height: e.documentImage.height || 0,
            url: e.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: e.proofOfAddress.thumb.width || 0,
                height: e.proofOfAddress.thumb.height || 0,
                url: e.proofOfAddress.thumb.url,
            },
            width: e.proofOfAddress.width || 0,
            height: e.proofOfAddress.height || 0,
            url: e.proofOfAddress.url,
        },
        zipcode: e.zipcode,
        city: e.city,
        complementary: e.complementary === null || e.complementary === undefined ? null : e.complementary,
        countryCode: e.countryCode,
        neighborhood: e.neighborhood,
        state: e.state,
        street: e.street,
        streetNumber: e.streetNumber,
        invitedBy: e.invitedBy === null || e.invitedBy === undefined ? null : {
            id: e.invitedBy.id,
            email: e.invitedBy.email,
            name: e.invitedBy.name,
        },
        invitationCode: e.invitationCode || 0,
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(e.blockedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function approveDeposit(id: string, dscAmount: number | null, progress?: (progress: number) => void): Promise<Deposit> {
    const args = {
        id: id,
        dscAmount: dscAmount === null || dscAmount === undefined ? null : dscAmount,
    };
    const ret = await makeRequest({name: "approveDeposit", args, progress});
    return {
        id: ret.id,
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        receiptImage: {
            thumb: {
                width: ret.receiptImage.thumb.width || 0,
                height: ret.receiptImage.thumb.height || 0,
                url: ret.receiptImage.thumb.url,
            },
            width: ret.receiptImage.width || 0,
            height: ret.receiptImage.height || 0,
            url: ret.receiptImage.url,
        },
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        createdAt: new Date(ret.createdAt + "Z"),
        amountApproved: ret.amountApproved,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        amount: ret.amount,
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        depositStatus: ret.depositStatus,
    };
}

export async function refuseDeposit(id: string, progress?: (progress: number) => void): Promise<Deposit> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "refuseDeposit", args, progress});
    return {
        id: ret.id,
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        receiptImage: {
            thumb: {
                width: ret.receiptImage.thumb.width || 0,
                height: ret.receiptImage.thumb.height || 0,
                url: ret.receiptImage.thumb.url,
            },
            width: ret.receiptImage.width || 0,
            height: ret.receiptImage.height || 0,
            url: ret.receiptImage.url,
        },
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        createdAt: new Date(ret.createdAt + "Z"),
        amountApproved: ret.amountApproved,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        amount: ret.amount,
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        depositStatus: ret.depositStatus,
    };
}

export async function getAllApprovedDeposits(documentNumber: string | null, progress?: (progress: number) => void): Promise<Deposit[]> {
    const args = {
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllApprovedDeposits", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        acceptBy: e.acceptBy === null || e.acceptBy === undefined ? null : {
            id: e.acceptBy.id,
            createdAt: new Date(e.acceptBy.createdAt + "Z"),
            adminType: e.acceptBy.adminType,
            name: e.acceptBy.name,
            email: e.acceptBy.email,
            documentNumber: e.acceptBy.documentNumber,
        },
        receiptImage: {
            thumb: {
                width: e.receiptImage.thumb.width || 0,
                height: e.receiptImage.thumb.height || 0,
                url: e.receiptImage.thumb.url,
            },
            width: e.receiptImage.width || 0,
            height: e.receiptImage.height || 0,
            url: e.receiptImage.url,
        },
        priceDscCoin: e.priceDscCoin,
        brlPrice: e.brlPrice,
        createdAt: new Date(e.createdAt + "Z"),
        amountApproved: e.amountApproved,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        amount: e.amount,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        depositStatus: e.depositStatus,
    }));
}

export async function getAllPendingDeposits(documentNumber: string | null, progress?: (progress: number) => void): Promise<Deposit[]> {
    const args = {
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllPendingDeposits", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        acceptBy: e.acceptBy === null || e.acceptBy === undefined ? null : {
            id: e.acceptBy.id,
            createdAt: new Date(e.acceptBy.createdAt + "Z"),
            adminType: e.acceptBy.adminType,
            name: e.acceptBy.name,
            email: e.acceptBy.email,
            documentNumber: e.acceptBy.documentNumber,
        },
        receiptImage: {
            thumb: {
                width: e.receiptImage.thumb.width || 0,
                height: e.receiptImage.thumb.height || 0,
                url: e.receiptImage.thumb.url,
            },
            width: e.receiptImage.width || 0,
            height: e.receiptImage.height || 0,
            url: e.receiptImage.url,
        },
        priceDscCoin: e.priceDscCoin,
        brlPrice: e.brlPrice,
        createdAt: new Date(e.createdAt + "Z"),
        amountApproved: e.amountApproved,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        amount: e.amount,
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        depositStatus: e.depositStatus,
    }));
}

export async function getOneDepositById(depositId: string, progress?: (progress: number) => void): Promise<DepositDetails> {
    const args = {
        depositId: depositId,
    };
    const ret = await makeRequest({name: "getOneDepositById", args, progress});
    return {
        userBankAccount: {
            id: ret.userBankAccount.id,
            bankCode: ret.userBankAccount.bankCode,
            agency: ret.userBankAccount.agency,
            bankName: ret.userBankAccount.bankName,
            agencyDv: ret.userBankAccount.agencyDv === null || ret.userBankAccount.agencyDv === undefined ? null : ret.userBankAccount.agencyDv,
            account: ret.userBankAccount.account,
            accountDv: ret.userBankAccount.accountDv === null || ret.userBankAccount.accountDv === undefined ? null : ret.userBankAccount.accountDv,
            type: ret.userBankAccount.type,
            pix: ret.userBankAccount.pix === null || ret.userBankAccount.pix === undefined ? null : ret.userBankAccount.pix,
            pixType: ret.userBankAccount.pixType === null || ret.userBankAccount.pixType === undefined ? null : ret.userBankAccount.pixType,
            socialReason: ret.userBankAccount.socialReason === null || ret.userBankAccount.socialReason === undefined ? null : ret.userBankAccount.socialReason,
            cnpj: ret.userBankAccount.cnpj === null || ret.userBankAccount.cnpj === undefined ? null : ret.userBankAccount.cnpj,
        },
        adminUserBankAccount: {
            id: ret.adminUserBankAccount.id,
            bankCode: ret.adminUserBankAccount.bankCode,
            agency: ret.adminUserBankAccount.agency,
            bankName: ret.adminUserBankAccount.bankName,
            agencyDv: ret.adminUserBankAccount.agencyDv === null || ret.adminUserBankAccount.agencyDv === undefined ? null : ret.adminUserBankAccount.agencyDv,
            account: ret.adminUserBankAccount.account,
            accountDv: ret.adminUserBankAccount.accountDv === null || ret.adminUserBankAccount.accountDv === undefined ? null : ret.adminUserBankAccount.accountDv,
            type: ret.adminUserBankAccount.type,
            pix: ret.adminUserBankAccount.pix === null || ret.adminUserBankAccount.pix === undefined ? null : ret.adminUserBankAccount.pix,
            pixType: ret.adminUserBankAccount.pixType === null || ret.adminUserBankAccount.pixType === undefined ? null : ret.adminUserBankAccount.pixType,
            socialReason: ret.adminUserBankAccount.socialReason === null || ret.adminUserBankAccount.socialReason === undefined ? null : ret.adminUserBankAccount.socialReason,
            cnpj: ret.adminUserBankAccount.cnpj === null || ret.adminUserBankAccount.cnpj === undefined ? null : ret.adminUserBankAccount.cnpj,
        },
        id: ret.id,
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        receiptImage: {
            thumb: {
                width: ret.receiptImage.thumb.width || 0,
                height: ret.receiptImage.thumb.height || 0,
                url: ret.receiptImage.thumb.url,
            },
            width: ret.receiptImage.width || 0,
            height: ret.receiptImage.height || 0,
            url: ret.receiptImage.url,
        },
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        createdAt: new Date(ret.createdAt + "Z"),
        amountApproved: ret.amountApproved,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        amount: ret.amount,
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        depositStatus: ret.depositStatus,
    };
}

export async function getIndicationText(id: string, progress?: (progress: number) => void): Promise<IndicationText> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getIndicationText", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        text: ret.text,
    };
}

export async function getAllIndicationText(pageOffset: number, progress?: (progress: number) => void): Promise<IndicationText[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllIndicationText", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        text: e.text,
    }));
}

export async function createIndicationText(newIndicationText: NewIndicationText, progress?: (progress: number) => void): Promise<IndicationText> {
    const args = {
        newIndicationText: {
            text: newIndicationText.text,
        },
    };
    const ret = await makeRequest({name: "createIndicationText", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        text: ret.text,
    };
}

export async function editIndicationText(id: string, newAdminUser: NewIndicationText, progress?: (progress: number) => void): Promise<IndicationText> {
    const args = {
        id: id,
        newAdminUser: {
            text: newAdminUser.text,
        },
    };
    const ret = await makeRequest({name: "editIndicationText", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        text: ret.text,
    };
}

export async function deleteIndicationText(id: string, progress?: (progress: number) => void): Promise<IndicationText> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteIndicationText", args, progress});
    return {
        id: ret.id,
        createdAt: new Date(ret.createdAt + "Z"),
        text: ret.text,
    };
}

export async function createPoupanca(NewPoupanca: NewPoupanca, progress?: (progress: number) => void): Promise<Poupanca> {
    const args = {
        NewPoupanca: {
            percentage: NewPoupanca.percentage,
            date: (typeof NewPoupanca.date === "string" && (NewPoupanca.date as any).match(/^[0-9]{4}-[01][0-9]-[0123][0-9]T[012][0-9]:[0123456][0-9]:[0123456][0-9](\.[0-9]{1,6})?Z?$/) ? (NewPoupanca.date as any).replace("Z", "") : NewPoupanca.date.toISOString().replace("Z", "")),
        },
    };
    const ret = await makeRequest({name: "createPoupanca", args, progress});
    return {
        id: ret.id,
        percentage: ret.percentage,
        date: new Date(ret.date + "Z"),
    };
}

export async function getAllPoupanca(pageOffSet: number, progress?: (progress: number) => void): Promise<Poupanca[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllPoupanca", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        percentage: e.percentage,
        date: new Date(e.date + "Z"),
    }));
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getReports(pageOffSet: number, order: ReportOrder | null, progress?: (progress: number) => void): Promise<Report[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
        order: order === null || order === undefined ? null : {
            field: order.field,
            order: order.order,
        },
    };
    const ret = await makeRequest({name: "getReports", args, progress});
    return ret.map((e: any) => ({
        userId: e.userId,
        code: e.code || 0,
        name: e.name,
        state: e.state,
        countOfInvitedUsers: e.countOfInvitedUsers,
        invitedDepositUsers: e.invitedDepositUsers,
        commissionEarnedAmount: e.commissionEarnedAmount,
        commissionTable: e.commissionTable,
    }));
}

export async function setUserCommissionTable(userId: string, newCommissionTable: CommissionTableType, progress?: (progress: number) => void): Promise<CommissionTableType> {
    const args = {
        userId: userId,
        newCommissionTable: newCommissionTable,
    };
    const ret = await makeRequest({name: "setUserCommissionTable", args, progress});
    return ret;
}

export async function getTotalDscCoins(progress?: (progress: number) => void): Promise<TotalDscCoins> {
    const ret = await makeRequest({name: "getTotalDscCoins", args: {}, progress});
    return {
        id: ret.id,
        totalCoins: ret.totalCoins,
        totalUsers: ret.totalUsers || 0,
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getDSCCoinsStatics(progress?: (progress: number) => void): Promise<DSCCoinsStatics> {
    const ret = await makeRequest({name: "getDSCCoinsStatics", args: {}, progress});
    return {
        totalCoins: ret.totalCoins,
        totalUsers: ret.totalUsers || 0,
    };
}

export async function getUserDeleteRequestById(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUserDeleteRequestById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getAllUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function getAllPendingUserDeleteRequests(pageOffSet: number, progress?: (progress: number) => void): Promise<UserDeleteRequest[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllPendingUserDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        acceptedAt: e.acceptedAt === null || e.acceptedAt === undefined ? null : new Date(e.acceptedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
        deletedAt: e.deletedAt === null || e.deletedAt === undefined ? null : new Date(e.deletedAt + "Z"),
    }));
}

export async function acceptUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "acceptUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        phone: ret.phone,
        documentImage: {
            thumb: {
                width: ret.documentImage.thumb.width || 0,
                height: ret.documentImage.thumb.height || 0,
                url: ret.documentImage.thumb.url,
            },
            width: ret.documentImage.width || 0,
            height: ret.documentImage.height || 0,
            url: ret.documentImage.url,
        },
        proofOfAddress: {
            thumb: {
                width: ret.proofOfAddress.thumb.width || 0,
                height: ret.proofOfAddress.thumb.height || 0,
                url: ret.proofOfAddress.thumb.url,
            },
            width: ret.proofOfAddress.width || 0,
            height: ret.proofOfAddress.height || 0,
            url: ret.proofOfAddress.url,
        },
        zipcode: ret.zipcode,
        city: ret.city,
        complementary: ret.complementary === null || ret.complementary === undefined ? null : ret.complementary,
        countryCode: ret.countryCode,
        neighborhood: ret.neighborhood,
        state: ret.state,
        street: ret.street,
        streetNumber: ret.streetNumber,
        invitedBy: ret.invitedBy === null || ret.invitedBy === undefined ? null : {
            id: ret.invitedBy.id,
            email: ret.invitedBy.email,
            name: ret.invitedBy.name,
        },
        invitationCode: ret.invitationCode || 0,
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function refuseUserDeleteRequest(id: string, progress?: (progress: number) => void): Promise<UserDeleteRequest> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "refuseUserDeleteRequest", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        acceptedAt: ret.acceptedAt === null || ret.acceptedAt === undefined ? null : new Date(ret.acceptedAt + "Z"),
        createdAt: new Date(ret.createdAt + "Z"),
        deletedAt: ret.deletedAt === null || ret.deletedAt === undefined ? null : new Date(ret.deletedAt + "Z"),
    };
}

export async function getOneUserTransfer(id: string, progress?: (progress: number) => void): Promise<UserTransfer> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getOneUserTransfer", args, progress});
    return {
        id: ret.id,
        sender: {
            id: ret.sender.id,
            email: ret.sender.email,
            name: ret.sender.name,
            documentNumber: ret.sender.documentNumber === null || ret.sender.documentNumber === undefined ? null : ret.sender.documentNumber,
            phone: ret.sender.phone,
            documentImage: {
                thumb: {
                    width: ret.sender.documentImage.thumb.width || 0,
                    height: ret.sender.documentImage.thumb.height || 0,
                    url: ret.sender.documentImage.thumb.url,
                },
                width: ret.sender.documentImage.width || 0,
                height: ret.sender.documentImage.height || 0,
                url: ret.sender.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.sender.proofOfAddress.thumb.width || 0,
                    height: ret.sender.proofOfAddress.thumb.height || 0,
                    url: ret.sender.proofOfAddress.thumb.url,
                },
                width: ret.sender.proofOfAddress.width || 0,
                height: ret.sender.proofOfAddress.height || 0,
                url: ret.sender.proofOfAddress.url,
            },
            zipcode: ret.sender.zipcode,
            city: ret.sender.city,
            complementary: ret.sender.complementary === null || ret.sender.complementary === undefined ? null : ret.sender.complementary,
            countryCode: ret.sender.countryCode,
            neighborhood: ret.sender.neighborhood,
            state: ret.sender.state,
            street: ret.sender.street,
            streetNumber: ret.sender.streetNumber,
            invitedBy: ret.sender.invitedBy === null || ret.sender.invitedBy === undefined ? null : {
                id: ret.sender.invitedBy.id,
                email: ret.sender.invitedBy.email,
                name: ret.sender.invitedBy.name,
            },
            invitationCode: ret.sender.invitationCode || 0,
            acceptedAt: ret.sender.acceptedAt === null || ret.sender.acceptedAt === undefined ? null : new Date(ret.sender.acceptedAt + "Z"),
            blockedAt: ret.sender.blockedAt === null || ret.sender.blockedAt === undefined ? null : new Date(ret.sender.blockedAt + "Z"),
            createdAt: new Date(ret.sender.createdAt + "Z"),
        },
        receiver: {
            id: ret.receiver.id,
            email: ret.receiver.email,
            name: ret.receiver.name,
            documentNumber: ret.receiver.documentNumber === null || ret.receiver.documentNumber === undefined ? null : ret.receiver.documentNumber,
            phone: ret.receiver.phone,
            documentImage: {
                thumb: {
                    width: ret.receiver.documentImage.thumb.width || 0,
                    height: ret.receiver.documentImage.thumb.height || 0,
                    url: ret.receiver.documentImage.thumb.url,
                },
                width: ret.receiver.documentImage.width || 0,
                height: ret.receiver.documentImage.height || 0,
                url: ret.receiver.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.receiver.proofOfAddress.thumb.width || 0,
                    height: ret.receiver.proofOfAddress.thumb.height || 0,
                    url: ret.receiver.proofOfAddress.thumb.url,
                },
                width: ret.receiver.proofOfAddress.width || 0,
                height: ret.receiver.proofOfAddress.height || 0,
                url: ret.receiver.proofOfAddress.url,
            },
            zipcode: ret.receiver.zipcode,
            city: ret.receiver.city,
            complementary: ret.receiver.complementary === null || ret.receiver.complementary === undefined ? null : ret.receiver.complementary,
            countryCode: ret.receiver.countryCode,
            neighborhood: ret.receiver.neighborhood,
            state: ret.receiver.state,
            street: ret.receiver.street,
            streetNumber: ret.receiver.streetNumber,
            invitedBy: ret.receiver.invitedBy === null || ret.receiver.invitedBy === undefined ? null : {
                id: ret.receiver.invitedBy.id,
                email: ret.receiver.invitedBy.email,
                name: ret.receiver.invitedBy.name,
            },
            invitationCode: ret.receiver.invitationCode || 0,
            acceptedAt: ret.receiver.acceptedAt === null || ret.receiver.acceptedAt === undefined ? null : new Date(ret.receiver.acceptedAt + "Z"),
            blockedAt: ret.receiver.blockedAt === null || ret.receiver.blockedAt === undefined ? null : new Date(ret.receiver.blockedAt + "Z"),
            createdAt: new Date(ret.receiver.createdAt + "Z"),
        },
        valueInUsd: ret.valueInUsd,
        coinQuantity: ret.coinQuantity,
    };
}

export async function getAllUsersTransfers(pageOffSet: number, progress?: (progress: number) => void): Promise<UserTransfer[]> {
    const args = {
        pageOffSet: pageOffSet || 0,
    };
    const ret = await makeRequest({name: "getAllUsersTransfers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        sender: {
            id: e.sender.id,
            email: e.sender.email,
            name: e.sender.name,
            documentNumber: e.sender.documentNumber === null || e.sender.documentNumber === undefined ? null : e.sender.documentNumber,
            phone: e.sender.phone,
            documentImage: {
                thumb: {
                    width: e.sender.documentImage.thumb.width || 0,
                    height: e.sender.documentImage.thumb.height || 0,
                    url: e.sender.documentImage.thumb.url,
                },
                width: e.sender.documentImage.width || 0,
                height: e.sender.documentImage.height || 0,
                url: e.sender.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.sender.proofOfAddress.thumb.width || 0,
                    height: e.sender.proofOfAddress.thumb.height || 0,
                    url: e.sender.proofOfAddress.thumb.url,
                },
                width: e.sender.proofOfAddress.width || 0,
                height: e.sender.proofOfAddress.height || 0,
                url: e.sender.proofOfAddress.url,
            },
            zipcode: e.sender.zipcode,
            city: e.sender.city,
            complementary: e.sender.complementary === null || e.sender.complementary === undefined ? null : e.sender.complementary,
            countryCode: e.sender.countryCode,
            neighborhood: e.sender.neighborhood,
            state: e.sender.state,
            street: e.sender.street,
            streetNumber: e.sender.streetNumber,
            invitedBy: e.sender.invitedBy === null || e.sender.invitedBy === undefined ? null : {
                id: e.sender.invitedBy.id,
                email: e.sender.invitedBy.email,
                name: e.sender.invitedBy.name,
            },
            invitationCode: e.sender.invitationCode || 0,
            acceptedAt: e.sender.acceptedAt === null || e.sender.acceptedAt === undefined ? null : new Date(e.sender.acceptedAt + "Z"),
            blockedAt: e.sender.blockedAt === null || e.sender.blockedAt === undefined ? null : new Date(e.sender.blockedAt + "Z"),
            createdAt: new Date(e.sender.createdAt + "Z"),
        },
        receiver: {
            id: e.receiver.id,
            email: e.receiver.email,
            name: e.receiver.name,
            documentNumber: e.receiver.documentNumber === null || e.receiver.documentNumber === undefined ? null : e.receiver.documentNumber,
            phone: e.receiver.phone,
            documentImage: {
                thumb: {
                    width: e.receiver.documentImage.thumb.width || 0,
                    height: e.receiver.documentImage.thumb.height || 0,
                    url: e.receiver.documentImage.thumb.url,
                },
                width: e.receiver.documentImage.width || 0,
                height: e.receiver.documentImage.height || 0,
                url: e.receiver.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.receiver.proofOfAddress.thumb.width || 0,
                    height: e.receiver.proofOfAddress.thumb.height || 0,
                    url: e.receiver.proofOfAddress.thumb.url,
                },
                width: e.receiver.proofOfAddress.width || 0,
                height: e.receiver.proofOfAddress.height || 0,
                url: e.receiver.proofOfAddress.url,
            },
            zipcode: e.receiver.zipcode,
            city: e.receiver.city,
            complementary: e.receiver.complementary === null || e.receiver.complementary === undefined ? null : e.receiver.complementary,
            countryCode: e.receiver.countryCode,
            neighborhood: e.receiver.neighborhood,
            state: e.receiver.state,
            street: e.receiver.street,
            streetNumber: e.receiver.streetNumber,
            invitedBy: e.receiver.invitedBy === null || e.receiver.invitedBy === undefined ? null : {
                id: e.receiver.invitedBy.id,
                email: e.receiver.invitedBy.email,
                name: e.receiver.invitedBy.name,
            },
            invitationCode: e.receiver.invitationCode || 0,
            acceptedAt: e.receiver.acceptedAt === null || e.receiver.acceptedAt === undefined ? null : new Date(e.receiver.acceptedAt + "Z"),
            blockedAt: e.receiver.blockedAt === null || e.receiver.blockedAt === undefined ? null : new Date(e.receiver.blockedAt + "Z"),
            createdAt: new Date(e.receiver.createdAt + "Z"),
        },
        valueInUsd: e.valueInUsd,
        coinQuantity: e.coinQuantity,
    }));
}

export async function getAllUserWalletMovements(progress?: (progress: number) => void): Promise<WalletMovement[]> {
    const ret = await makeRequest({name: "getAllUserWalletMovements", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        movementType: e.movementType,
        operation: e.operation,
        value: e.value,
        description: e.description === null || e.description === undefined ? null : e.description,
        processedAt: e.processedAt === null || e.processedAt === undefined ? null : new Date(e.processedAt + "Z"),
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function getDSCWallet(progress?: (progress: number) => void): Promise<DSCWallet> {
    const ret = await makeRequest({name: "getDSCWallet", args: {}, progress});
    return {
        balance: ret.balance,
        quota: ret.quota,
    };
}

export async function getOutdatedWalletsQuantity(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getOutdatedWalletsQuantity", args: {}, progress});
    return ret || 0;
}

export async function getAllWallets(pageOffset: number, progress?: (progress: number) => void): Promise<Wallet[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllWallets", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        createdAt: new Date(e.createdAt + "Z"),
        balance: e.balance,
        quota: e.quota,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
    }));
}

export async function getAllApprovedWithdrawals(documentNumber: string | null, progress?: (progress: number) => void): Promise<Withdrawal[]> {
    const args = {
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllApprovedWithdrawals", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        acceptBy: e.acceptBy === null || e.acceptBy === undefined ? null : {
            id: e.acceptBy.id,
            createdAt: new Date(e.acceptBy.createdAt + "Z"),
            adminType: e.acceptBy.adminType,
            name: e.acceptBy.name,
            email: e.acceptBy.email,
            documentNumber: e.acceptBy.documentNumber,
        },
        status: e.status,
        bankAccount: {
            id: e.bankAccount.id,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            bankName: e.bankAccount.bankName,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            pix: e.bankAccount.pix === null || e.bankAccount.pix === undefined ? null : e.bankAccount.pix,
            pixType: e.bankAccount.pixType === null || e.bankAccount.pixType === undefined ? null : e.bankAccount.pixType,
            socialReason: e.bankAccount.socialReason === null || e.bankAccount.socialReason === undefined ? null : e.bankAccount.socialReason,
            cnpj: e.bankAccount.cnpj === null || e.bankAccount.cnpj === undefined ? null : e.bankAccount.cnpj,
        },
        createdAt: new Date(e.createdAt + "Z"),
        amount: e.amount,
        priceDscCoin: e.priceDscCoin,
        brlPrice: e.brlPrice,
        brlAmountApproved: e.brlAmountApproved,
    }));
}

export async function getAllPendingWithdrawals(documentNumber: string | null, progress?: (progress: number) => void): Promise<Withdrawal[]> {
    const args = {
        documentNumber: documentNumber === null || documentNumber === undefined ? null : documentNumber,
    };
    const ret = await makeRequest({name: "getAllPendingWithdrawals", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            phone: e.user.phone,
            documentImage: {
                thumb: {
                    width: e.user.documentImage.thumb.width || 0,
                    height: e.user.documentImage.thumb.height || 0,
                    url: e.user.documentImage.thumb.url,
                },
                width: e.user.documentImage.width || 0,
                height: e.user.documentImage.height || 0,
                url: e.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: e.user.proofOfAddress.thumb.width || 0,
                    height: e.user.proofOfAddress.thumb.height || 0,
                    url: e.user.proofOfAddress.thumb.url,
                },
                width: e.user.proofOfAddress.width || 0,
                height: e.user.proofOfAddress.height || 0,
                url: e.user.proofOfAddress.url,
            },
            zipcode: e.user.zipcode,
            city: e.user.city,
            complementary: e.user.complementary === null || e.user.complementary === undefined ? null : e.user.complementary,
            countryCode: e.user.countryCode,
            neighborhood: e.user.neighborhood,
            state: e.user.state,
            street: e.user.street,
            streetNumber: e.user.streetNumber,
            invitedBy: e.user.invitedBy === null || e.user.invitedBy === undefined ? null : {
                id: e.user.invitedBy.id,
                email: e.user.invitedBy.email,
                name: e.user.invitedBy.name,
            },
            invitationCode: e.user.invitationCode || 0,
            acceptedAt: e.user.acceptedAt === null || e.user.acceptedAt === undefined ? null : new Date(e.user.acceptedAt + "Z"),
            blockedAt: e.user.blockedAt === null || e.user.blockedAt === undefined ? null : new Date(e.user.blockedAt + "Z"),
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        approvedAt: e.approvedAt === null || e.approvedAt === undefined ? null : new Date(e.approvedAt + "Z"),
        acceptBy: e.acceptBy === null || e.acceptBy === undefined ? null : {
            id: e.acceptBy.id,
            createdAt: new Date(e.acceptBy.createdAt + "Z"),
            adminType: e.acceptBy.adminType,
            name: e.acceptBy.name,
            email: e.acceptBy.email,
            documentNumber: e.acceptBy.documentNumber,
        },
        status: e.status,
        bankAccount: {
            id: e.bankAccount.id,
            bankCode: e.bankAccount.bankCode,
            agency: e.bankAccount.agency,
            bankName: e.bankAccount.bankName,
            agencyDv: e.bankAccount.agencyDv === null || e.bankAccount.agencyDv === undefined ? null : e.bankAccount.agencyDv,
            account: e.bankAccount.account,
            accountDv: e.bankAccount.accountDv === null || e.bankAccount.accountDv === undefined ? null : e.bankAccount.accountDv,
            type: e.bankAccount.type,
            pix: e.bankAccount.pix === null || e.bankAccount.pix === undefined ? null : e.bankAccount.pix,
            pixType: e.bankAccount.pixType === null || e.bankAccount.pixType === undefined ? null : e.bankAccount.pixType,
            socialReason: e.bankAccount.socialReason === null || e.bankAccount.socialReason === undefined ? null : e.bankAccount.socialReason,
            cnpj: e.bankAccount.cnpj === null || e.bankAccount.cnpj === undefined ? null : e.bankAccount.cnpj,
        },
        createdAt: new Date(e.createdAt + "Z"),
        amount: e.amount,
        priceDscCoin: e.priceDscCoin,
        brlPrice: e.brlPrice,
        brlAmountApproved: e.brlAmountApproved,
    }));
}

export async function getOneWithdrawalById(id: string, progress?: (progress: number) => void): Promise<Withdrawal> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getOneWithdrawalById", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        status: ret.status,
        bankAccount: {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            bankName: ret.bankAccount.bankName,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            pix: ret.bankAccount.pix === null || ret.bankAccount.pix === undefined ? null : ret.bankAccount.pix,
            pixType: ret.bankAccount.pixType === null || ret.bankAccount.pixType === undefined ? null : ret.bankAccount.pixType,
            socialReason: ret.bankAccount.socialReason === null || ret.bankAccount.socialReason === undefined ? null : ret.bankAccount.socialReason,
            cnpj: ret.bankAccount.cnpj === null || ret.bankAccount.cnpj === undefined ? null : ret.bankAccount.cnpj,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        amount: ret.amount,
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        brlAmountApproved: ret.brlAmountApproved,
    };
}

export async function approveWithdrawal(id: string, receiptImage: Image, brlApprovedAmount: number | null, progress?: (progress: number) => void): Promise<Withdrawal> {
    const args = {
        id: id,
        receiptImage: {
            thumb: {
                width: receiptImage.thumb.width || 0,
                height: receiptImage.thumb.height || 0,
                url: receiptImage.thumb.url,
            },
            width: receiptImage.width || 0,
            height: receiptImage.height || 0,
            url: receiptImage.url,
        },
        brlApprovedAmount: brlApprovedAmount === null || brlApprovedAmount === undefined ? null : brlApprovedAmount,
    };
    const ret = await makeRequest({name: "approveWithdrawal", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        status: ret.status,
        bankAccount: {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            bankName: ret.bankAccount.bankName,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            pix: ret.bankAccount.pix === null || ret.bankAccount.pix === undefined ? null : ret.bankAccount.pix,
            pixType: ret.bankAccount.pixType === null || ret.bankAccount.pixType === undefined ? null : ret.bankAccount.pixType,
            socialReason: ret.bankAccount.socialReason === null || ret.bankAccount.socialReason === undefined ? null : ret.bankAccount.socialReason,
            cnpj: ret.bankAccount.cnpj === null || ret.bankAccount.cnpj === undefined ? null : ret.bankAccount.cnpj,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        amount: ret.amount,
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        brlAmountApproved: ret.brlAmountApproved,
    };
}

export async function refuseWithdrawal(id: string, progress?: (progress: number) => void): Promise<Withdrawal> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "refuseWithdrawal", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            phone: ret.user.phone,
            documentImage: {
                thumb: {
                    width: ret.user.documentImage.thumb.width || 0,
                    height: ret.user.documentImage.thumb.height || 0,
                    url: ret.user.documentImage.thumb.url,
                },
                width: ret.user.documentImage.width || 0,
                height: ret.user.documentImage.height || 0,
                url: ret.user.documentImage.url,
            },
            proofOfAddress: {
                thumb: {
                    width: ret.user.proofOfAddress.thumb.width || 0,
                    height: ret.user.proofOfAddress.thumb.height || 0,
                    url: ret.user.proofOfAddress.thumb.url,
                },
                width: ret.user.proofOfAddress.width || 0,
                height: ret.user.proofOfAddress.height || 0,
                url: ret.user.proofOfAddress.url,
            },
            zipcode: ret.user.zipcode,
            city: ret.user.city,
            complementary: ret.user.complementary === null || ret.user.complementary === undefined ? null : ret.user.complementary,
            countryCode: ret.user.countryCode,
            neighborhood: ret.user.neighborhood,
            state: ret.user.state,
            street: ret.user.street,
            streetNumber: ret.user.streetNumber,
            invitedBy: ret.user.invitedBy === null || ret.user.invitedBy === undefined ? null : {
                id: ret.user.invitedBy.id,
                email: ret.user.invitedBy.email,
                name: ret.user.invitedBy.name,
            },
            invitationCode: ret.user.invitationCode || 0,
            acceptedAt: ret.user.acceptedAt === null || ret.user.acceptedAt === undefined ? null : new Date(ret.user.acceptedAt + "Z"),
            blockedAt: ret.user.blockedAt === null || ret.user.blockedAt === undefined ? null : new Date(ret.user.blockedAt + "Z"),
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        approvedAt: ret.approvedAt === null || ret.approvedAt === undefined ? null : new Date(ret.approvedAt + "Z"),
        acceptBy: ret.acceptBy === null || ret.acceptBy === undefined ? null : {
            id: ret.acceptBy.id,
            createdAt: new Date(ret.acceptBy.createdAt + "Z"),
            adminType: ret.acceptBy.adminType,
            name: ret.acceptBy.name,
            email: ret.acceptBy.email,
            documentNumber: ret.acceptBy.documentNumber,
        },
        status: ret.status,
        bankAccount: {
            id: ret.bankAccount.id,
            bankCode: ret.bankAccount.bankCode,
            agency: ret.bankAccount.agency,
            bankName: ret.bankAccount.bankName,
            agencyDv: ret.bankAccount.agencyDv === null || ret.bankAccount.agencyDv === undefined ? null : ret.bankAccount.agencyDv,
            account: ret.bankAccount.account,
            accountDv: ret.bankAccount.accountDv === null || ret.bankAccount.accountDv === undefined ? null : ret.bankAccount.accountDv,
            type: ret.bankAccount.type,
            pix: ret.bankAccount.pix === null || ret.bankAccount.pix === undefined ? null : ret.bankAccount.pix,
            pixType: ret.bankAccount.pixType === null || ret.bankAccount.pixType === undefined ? null : ret.bankAccount.pixType,
            socialReason: ret.bankAccount.socialReason === null || ret.bankAccount.socialReason === undefined ? null : ret.bankAccount.socialReason,
            cnpj: ret.bankAccount.cnpj === null || ret.bankAccount.cnpj === undefined ? null : ret.bankAccount.cnpj,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        amount: ret.amount,
        priceDscCoin: ret.priceDscCoin,
        brlPrice: ret.brlPrice,
        brlAmountApproved: ret.brlAmountApproved,
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
