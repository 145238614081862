import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex, Text } from "@chakra-ui/react";
import Store from "./store";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IParams {
	id: string;
}

const CreateView: React.FC = () => {
	const pageStrings = strings.profitTable.create;

	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store());

	const onSuccess = () => {
		history.goBack();
	};

	const openDialog = () => {
		dialog.showDialog({
			title: modal.titleCreateIndicationText,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: modal.button.confirm,
					onPress: () => onSubmit(),
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSubmit = () => {
		store.createIndicationText(onSuccess);
		dialog.closeDialog();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					isLoading={store.loader.isLoading}
					onClick={openDialog}
				>
					{strings.common.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<Flex justifyContent="center" gap={5} flexDir="column">
				<Text
					color="primary.600"
				>
					{strings.IndicationTexts.create.message}
				</Text>
				<TextInput
					labelText={strings.IndicationTexts.details.text}
					labelProps={{ fontWeight: "bold" }}
					type="text"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("indicationText").error}
					{...store.formShelf.field("indicationText")}
				/>
			</Flex>
		</CentralizedCard>
	);
};

export default observer(CreateView);
