import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf, AttributeShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.Withdrawal>;
	public loader = new LoaderShelf();
	public cpf = new AttributeShelf("");

	public getAllApprovedWithdrawals = () => api.getAllApprovedWithdrawals(this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllApprovedWithdrawals,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
}
