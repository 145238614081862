import * as React from "react";
import API from "~/resources/api";
import strings from "~/resources/strings";
import { DetailsRow, Label, TextInput } from "..";
import format from "../../resources/format";
import { DetailsBank } from "../DetailsBank";
import { DetailsUser } from "../DetailsUser";
import { observer } from "mobx-react-lite";
import { AttributeShelf } from "@startapp/mobx-utils";

interface IProps {
	withdraw: API.Withdrawal;
	amountAttributeShelf?: AttributeShelf<string>;
	isLoading?: boolean;
}

export const DetailsWithdraw: React.FC<IProps> = observer((props) => {
	const {withdraw, amountAttributeShelf, isLoading} = props;
	const commonStrings = strings.common;

	return (
		<>
			<Label fontWeight="bold" fontSize="2xl" marginTop={5}>
				{commonStrings.infoWithdraw}
			</Label>
			<DetailsRow
				label={commonStrings.fields.labelDepositAmount(withdraw.acceptBy)}
				value={format.currencyForBR(withdraw.brlPrice)}
			/>
			<DetailsRow
				label={commonStrings.fields.labelDSCAmount(!!withdraw.acceptBy)}
				value={format.currencyForUSD(withdraw.priceDscCoin)}
			/>
			<DetailsRow
				label={commonStrings.fields.amount}
				value={withdraw.amount}
			/>
			{
				!amountAttributeShelf && (
					<DetailsRow
						label={commonStrings.fields.customBRLAmountApproved}
						value={format.currencyForBR(withdraw.brlAmountApproved)}
					/>
				)
			}
			{
				amountAttributeShelf && (
					<TextInput
						labelText={strings.common.fields.customBRLAmount}
						labelProps={{ fontWeight: "bold" }}
						inputProps={{ padding: 6 }}
						type="number"
						placeholder={commonStrings.fields.placeHolderAmountToApprove}
						isDisabled={isLoading}
						value={amountAttributeShelf.value}
						onChange={(e) => amountAttributeShelf.setValue(e.target.value)}
					/>
				)
			}
			{withdraw.acceptBy && (
				<>
					<DetailsRow
						label={commonStrings.fields.approvedBy}
						value={withdraw.acceptBy.name}
					/>
					<DetailsRow
						label={commonStrings.fields.acceptedAt}
						value={format.date(withdraw.approvedAt)}
					/>
					<DetailsRow
						label={commonStrings.fields.hour}
						value={format.hour(withdraw.approvedAt)}
					/>
				</>
			)}
			<DetailsRow
				label={commonStrings.fields.status}
				value={API.translateWithdrawalStatus(withdraw.status)}
			/>
			<DetailsRow
				label={commonStrings.fields.createAt}
				value={format.date(withdraw.createdAt)}
			/>
			<DetailsUser
				label={commonStrings.infoUser}
				user={withdraw.user}
			/>
			<DetailsBank
				label={commonStrings.destinyBankAccount}
				bankAccount={withdraw.bankAccount}
			/>
		</>
	);
});
