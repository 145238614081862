import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.Deposit>;
	public loader = new LoaderShelf();
	public cpf = new AttributeShelf("");

	public getAllApprvedDeposits = () => api.getAllApprovedDeposits(this.cpf.value);

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			this.getAllApprvedDeposits,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
}
