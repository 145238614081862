import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import { onFetchError } from "~/resources/fetchError";

const pageStrings  = strings.adminWithdraw.table;

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.AdminUser>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllAdminUsersWithdrawals,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

	public deleteAdminWithdraw = async (id: string) => {
		this.loader.tryStart();
		try {

			const deleteAdminWithdraw= await api.deleteAdminUser(id);
			showSuccessToast(pageStrings.delete(deleteAdminWithdraw.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
