import { makeAutoObservable } from "mobx";
import { PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.User>;

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllInvitedUsers,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}
}
