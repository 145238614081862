import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { CentralizedCard, DetailsRow } from "~/components";
import strings from "~/resources/strings";
import format from "~/resources/format";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {
	const string = strings.common;
	const pageStrings = strings.IndicationTexts.details;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoToTheIndicationTextsList = () => history.goBack();

	return (
		<>
			<Flex justifyContent="center" flexDirection="column" alignItems="center">
				<>
					<CentralizedCard
						title={{
							text: string.detailsTitle,
							helper: (
								<Tooltip label={strings.common.edit}>
									<IconButton
										variant="icon"
										aria-label={strings.actions.back}
										size="lg"
									/>
								</Tooltip>
							),
						}}
						isLoading={store.FetchModelShelf.initialLoader.isLoading}
					>
						{
							store.FetchModelShelf.model.value && (
								<>
									<DetailsRow
										label={pageStrings.id}
										value={store.FetchModelShelf.model.value.id}
									/>
									<DetailsRow
										label={string.fields.createAt}
										value={format.date(store.FetchModelShelf.model.value.createdAt)}
									/>
									<DetailsRow
										label={pageStrings.text}
										value={store.FetchModelShelf.model.value.text}
									/>
								</>

							)
						}
					</CentralizedCard>
					<Button
						onClick={() => onGoToTheIndicationTextsList()}
						w={{
							base: "50%",
							md: "15%",
						}}
						mb={5}
						variant="outline"
						leftIcon={
							<ArrowBackIcon />
						}
					>
						{strings.actions.back}
					</Button>
				</>
			</Flex>
		</>
	);
};

export default observer(DetailsView);
