import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Table, TableCellWithActionButtons } from "~/components";
import strings from "~/resources/strings";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Store } from "./store";
import format from "~/resources/format";
import { useHistory } from "react-router-dom";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const pageStrings = strings.adminDepositAndApprovals.table;
	const modal = strings.common.modal;

	const store = useLocalObservable(() => new Store());

	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteAdminDepositAndApprovals = (id: string) => {
		store.deleteAdminDepositAndApproval(id);
		dialog.closeDialog();
	};

	const onGoToCreateAdminDepositAndApprovals = () => history.push("/dashboard/adminMaster/adminDepositAndApprovals/create");
	const onGoToEditAdminDepositAndApprovals = (id: string) => history.push(`/dashboard/adminMaster/adminDepositAndApprovals/edit/${id}`);

	const openDialog = (adminDepositAndApprovals: api.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(adminDepositAndApprovals.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteAdminDepositAndApprovals(adminDepositAndApprovals.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateAdminDepositAndApprovals}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onEdit={() => onGoToEditAdminDepositAndApprovals(item.id)}
							onDelete={() => openDialog(item)}
						/>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<Td>{item.documentNumber}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
