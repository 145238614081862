import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast, showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.User>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllPendingUsers,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		) ;
	}

	public approveOrRefuseUser = async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			await api.approveOrRefuseUser(id, isApprove);
			const user = await api.getUser(id);
			showSuccessToast(strings.pendingUsers.successApproveOrRefuse(user.name, isApprove));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}
