import React from "react";
import { observer } from "mobx-react-lite";

import { theme } from "../themes";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { GlobalStoreProvider } from "~/contexts/useGlobalContext";
import { Auth } from "./Auth";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Recovery from "./Recovery";
import AuthStore from "~/stores/AuthStore";

const App = observer(() => {
	React.useState(() => {
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			localStorage.removeItem(AuthStore.LOCALSTORAGE_KEY);
		});
	});

	return (
		<ChakraProvider theme={theme}>
			<GlobalStoreProvider>
				<BrowserRouter>
					<Switch>
						<Route
							exact
							path="/"
							component={Auth}
						/>
						<Route path="/dashboard" component={Dashboard} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/recovery" component={Recovery} />
						<Route path="/recovery/token/:token" component={Recovery} />
					</Switch>
				</BrowserRouter>
			</GlobalStoreProvider>
		</ChakraProvider>
	);
});

export default App;
