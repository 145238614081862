import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableView from "./Table";
import ApprovedWithdrawalDetails from "./Details";

const ApprovedWithdrawal: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/approvedWithdrawal" component={TableView} />
		<Route path="/dashboard/adminMaster/approvedWithdrawal/details/:id" component={ApprovedWithdrawalDetails} />
	</Switch>
);

export default observer(ApprovedWithdrawal);
