import * as React from "react";
import {
	Box,
	Flex,
	Image,
} from "@chakra-ui/react";
import strings from "~/resources/strings";
import API from "~/resources/api";
import { DetailsRow, Label } from "..";
import imagePlaceholder from "../../../static/pick_image.svg";
import { ModalImage } from "../ModalImage";

interface IProps {
	label?: string;
	user: API.User;
}

export const DetailsUser: React.FC<IProps> = (props) => {
	const { user, label } = props;
	const commonStrings = strings.common;
	return (
		<>
			{label && (
				<Label fontWeight="bold" fontSize="2xl" marginTop={5}>
					{label}
				</Label>
			)}
			<Box>
				<Flex gap={2} alignItems="center">
					<Label fontWeight="bold" marginBottom={1}>
						{commonStrings.fields.photo}
					</Label>
					<ModalImage modalTitle={strings.common.documentPhoto} imageSrc={user.documentImage?.url} />
				</Flex>
				<Image
					width={120}
					height={120}
					backgroundColor="white"
					p={0}
					mt={2}
					src={user.documentImage ? user.documentImage.thumb.url : imagePlaceholder}
					rounded="lg"
				/>
			</Box>
			<Box>
				<Flex gap={2} alignItems="center">
					<Label fontWeight="bold" marginBottom={1}>
						{commonStrings.fields.documentPhoto}
					</Label>
					<ModalImage modalTitle={strings.common.documentProof} imageSrc={user.proofOfAddress?.url} />
				</Flex>
				<Image
					width={120}
					height={120}
					backgroundColor="white"
					p={0}
					src={user.proofOfAddress ? user.proofOfAddress?.url : imagePlaceholder}
					rounded="lg"
				/>
			</Box>
			<DetailsRow
				label={commonStrings.fields.name}
				value={user.name}
			/>
			<DetailsRow
				label={commonStrings.fields.email}
				value={user.email}
			/>
			<DetailsRow
				label={commonStrings.fields.cpf}
				value={user.documentNumber || ""}
			/>
			<DetailsRow
				label={commonStrings.fields.phone}
				value={user.phone}
			/>
			<DetailsRow
				label={commonStrings.fields.city}
				value={`${user.city} - ${user.state}`}
			/>
		</>
	);
};
