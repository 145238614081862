import { makeAutoObservable } from "mobx";
import { PaginatedListShelf, FetchModelShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";

export class Store {
	public DSCCoinsStaticsModel: FetchModelShelf<api.DSCCoinsStatics>;
	public walletList: PaginatedListShelf<api.Wallet>;
	public DSCWallet: FetchModelShelf<api.DSCWallet>;

	public getAllWallets = (page: number) => api.getAllWallets(page);

	constructor() {
		makeAutoObservable(this);
		this.walletList = new PaginatedListShelf(
			this.getAllWallets,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
		this.DSCCoinsStaticsModel = new FetchModelShelf(
			"",
			() => api.getDSCCoinsStatics(),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
		this.DSCWallet = new FetchModelShelf(
			"",
			() => api.getDSCWallet(),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}
}
