import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Create from "./Create";
import CreateHistory from "./CreateHistory";

const ProfitTable: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/profit" component={Table} />
		<Route exact path="/dashboard/adminMaster/profit/create" component={Create} />
		<Route exact path="/dashboard/adminMaster/profit/createHistory" component={CreateHistory} />
	</Switch>
);
export default observer(ProfitTable);
