import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import { onFetchError } from "~/resources/fetchError";
import strings from "~/resources/strings";

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.IndicationText>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllIndicationText,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
	}

	public deleteIndicationText = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteIndicationText(id);
			showSuccessToast(strings.IndicationTexts.table.delete);
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
