import { makeAutoObservable } from "mobx";
import { AttributeShelf, FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";
import { onFetchError } from "~/resources/fetchError";
import api from "~/resources/api";
import strings from "~/resources/strings";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";

export class Store {
	public amountToApprove: AttributeShelf<string>;
	public fetchModelShelf: FetchModelShelf<api.DepositDetails>;
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(id, ()=> api.getOneDepositById(id),
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);
		this.amountToApprove = new AttributeShelf("");
	}

	public approveOrRefuseDeposit = async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			if (isApprove) {
				await api.approveDeposit(id, +this.amountToApprove.value || null);
			} else {
				await api.refuseDeposit(id);
			}
			showSuccessToast(strings.pendingDeposits.table.successApproveOrRefuse(isApprove));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
