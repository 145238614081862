import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CentralizedCard, DetailsRow, DetailsUser } from "~/components";
import {
	Button,
	Flex,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";
import format from "~/resources/format";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.userTransfer.details;
	const buttonBack = strings.actions.back;

	const { id } = useParams<IParams>();

	const store = useLocalObservable(() => new Store(id));

	const history = useHistory();

	const onGoBack = () => history.goBack();

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<>
				{store.fetchModelShelf.model.value && (
					<>
						<CentralizedCard
							title={{
								text:pageStrings.title,
							}}
							isLoading={store.fetchModelShelf.initialLoader.isLoading}
						>
							<DetailsRow
								label={commonStrings.fields.value}
								value={format.currencyForBR(store.fetchModelShelf.fetchedModel.valueInUsd)}
							/>
						</CentralizedCard>
						<CentralizedCard
							title={{
								text:pageStrings.titleDetailsOfSender,
							}}
							isLoading={store.fetchModelShelf.initialLoader.isLoading}
						>
							<DetailsUser
								user={store.fetchModelShelf.fetchedModel.sender}
							/>
						</CentralizedCard>
						<CentralizedCard
							title={{
								text:pageStrings.titleDetailsOfSender,
							}}
							isLoading={store.fetchModelShelf.initialLoader.isLoading}
						>
							<DetailsUser
								user={store.fetchModelShelf.fetchedModel.receiver}
							/>
						</CentralizedCard>
					</>
				)}
			</>
			<Button
				onClick={() => onGoBack()}
				w={{
					base: "50%",
					md: "15%",
				}}
				mb={5}
				variant="outline"
				leftIcon={
					<ArrowBackIcon />
				}
			>
				{buttonBack}
			</Button>
		</Flex>
	);
};

export default observer(Details);
