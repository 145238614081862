import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableView from "./Table";
import DetailsUserRequestDelete from "./Details";


const DeletionRequests: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/deletionRequests" component={TableView} />
		<Route exact path="/dashboard/adminMaster/deletionRequests/details/:id" component={DetailsUserRequestDelete} />
	</Switch>
);
export default observer(DeletionRequests);
