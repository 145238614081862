import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { CentralizedCard, DetailsUser } from "~/components";
import strings from "~/resources/strings";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {
	const string = strings.common;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoToTheInvitedUsersList = () => history.goBack();

	return (
		<>
			<Flex justifyContent="center" flexDirection="column" alignItems="center">
				<>
					{store.FetchModelShelf.model.value && (
						<CentralizedCard
							title={{
								text: string.detailsTitle,
								helper: (
									<Tooltip label={strings.common.edit}>
										<IconButton
											variant="icon"
											aria-label={strings.actions.back}
											size="lg"
										/>
									</Tooltip>
								),
							}}
							isLoading={store.FetchModelShelf.initialLoader.isLoading}
						>
							{store.FetchModelShelf.model.value &&
								<DetailsUser user={store.FetchModelShelf.fetchedModel} />}
						</CentralizedCard>
					)}
					<Button
						onClick={() => onGoToTheInvitedUsersList()}
						w={{
							base: "50%",
							md: "15%",
						}}
						mb={5}
						variant="outline"
						leftIcon={
							<ArrowBackIcon />
						}
					>
						{strings.actions.back}
					</Button>
				</>
			</Flex>
		</>
	);
};

export default observer(DetailsView);
