import React from "react";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";

import { useGlobalStore } from "~/contexts/useGlobalContext";

import { Table, TableCellWithActionButtons } from "~/components";

import api from "~/resources/api";
import strings from "~/resources/strings";

import { Store } from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.bankAccounts.table;
	const modal = strings.common.modal;

	const store = useLocalObservable(() => new Store());
	global.console.log(store.paginetedListShelf.items);
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteBankAccount = (id: string) => {
		store.deleteBankAccount(id);
		dialog.closeDialog();
	};

	const onGoToCreateBankAccount = () => history.push("bankAccounts/create");
	const onGoToTheEdit = (id: string) => history.push(`bankAccounts/edit/${id}`);

	const openDialog = (bankAccount: api.BankAccount) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(bankAccount.account),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteBankAccount(bankAccount.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				onAdd={onGoToCreateBankAccount}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onDelete={() => openDialog(item)}
							onEdit={() => onGoToTheEdit(item.id)}
						/>
						<Td>{item.agency}</Td>
						<Td>{item.agencyDv}</Td>
						<Td>{item.bankName}</Td>
						<Td>{api.translateBankAccountType(item.type)}</Td>
						<Td>{item.account}</Td>
						<Td>{item.accountDv}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
