const green = {
	50: "#EDF8ED",
	100: "#CBEBCC",
	200: "#AADFAB",
	300: "#89D28B",
	400: "#68C56A",
	500: "#46B949",
	600: "#358b37",
	700: "#2A6F2C",
	800: "#145427",
	900: "#0E250F",
};

const gray = {
	50: "#F4F4F4",
	100: "#E5E5E5",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#969696",
	500: "#666666",
	600: "#5E5E5E",
	700: "#4D4D4D",
	800: "#333333",
	900: "#1A1A1A",
};

export const colors = {
	green,
	primary: green,
	secondary: gray,
};
