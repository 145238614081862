import React from "react";
import { observer } from "mobx-react-lite";
import {
	Center,
	IconButton,
	Image,
	Tooltip,
	Text,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import imagePicker from "../../../static/logo.svg";
import { Loading } from "../Loading";
import strings from "../../resources/strings";

export interface IProps {
	pickImage?: () => void;
	src: string | null;
	loading?: boolean;
	onDelete?: () => void;
	label?: string;
}
export const ImagePicker: React.FC<IProps> = observer((props) => {
	const {
		pickImage,
		src,
		loading,
		onDelete,
		label,
	} = props;

	const componentStrings = strings.components.imagePicker;

	return (
		<>
			{label && <Text fontSize="md" fontWeight="bold">{label}</Text>}
			<Center
				width={120}
				height={120}
				position="relative"
				overflow="hidden"
			>
				{(src && onDelete)&& (
					<IconButton
						position="absolute"
						variant="icon"
						left={1}
						top={1}
						colorScheme="secondary"
						aria-label="Edit"
						size="sm"
						icon={<DeleteIcon w={25} h={25} />}
						onClick={onDelete}
					/>
				)}
				{(src)&& (
					<IconButton
						position="absolute"
						variant="icon"
						right={1}
						top={1}
						colorScheme="secondary"
						aria-label="Edit"
						size="sm"
						icon={<EditIcon w={25} h={25} />}
					/>
				)}
				<Tooltip label={componentStrings.selectAnImage}>
					<Image
						h="100%"
						p={0}
						m={0}
						src={src ? src : imagePicker}
						onClick={pickImage}
						fallbackSrc={imagePicker}
						rounded="lg"
						cursor="pointer"
						__css={{ filter: loading && "brightness(0.5)" }}
					/>
				</Tooltip>
				{loading && (
					<Loading
						position="absolute"
					/>
				)}
			</Center>
		</>
	);
});
