import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { MainLayout } from "~/layout";
import Admin from "./AdminMaster";
import AdminDepositAndApprovals from "./AdminDepositAndApprovals";
import AdminWithdraw from "./AdminWithdraw";
import useMainRoutes from "~/hooks/useMainRoutes";
import { useAuthRoute } from "~/hooks/useAuthRoute";

const Dashboard: React.FC = () => {
	const location = useLocation();
	const redirectRoute = useMainRoutes();
	const history = useHistory();

	const onSuccess = () => {
		if (location.pathname === "/dashboard") {
			history.push(redirectRoute[0].path || "/login");
		}
	};

	useAuthRoute(onSuccess);

	return (
		<MainLayout>
			<Switch>
				<Route path="/dashboard/adminMaster" component={Admin} />
				<Route path="/dashboard/adminDepositAndApprovals" component={AdminDepositAndApprovals} />
				<Route path="/dashboard/adminWithdraw" component={AdminWithdraw} />
			</Switch>
		</MainLayout>
	);
};
export default observer(Dashboard);
