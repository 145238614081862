import { makeAutoObservable } from "mobx";
import { LoaderShelf, PaginatedListShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

const pageStrings  = strings.adminUserTable.table;

export class Store {

	public paginetedListShelf: PaginatedListShelf<api.AdminUser>;
	public loader = new LoaderShelf();

	constructor() {
		makeAutoObservable(this);
		this.paginetedListShelf = new PaginatedListShelf(
			api.getAllAdminUsersMasters,
			{
				fetchOnConstructor: true,
			},
		) ;
		this.paginetedListShelf.fetchPage(0);
	}

	public onFetchError = (e) => {
		const error = Errors.treatError(e);
		showErrorToast(error.message);
	};

	public deleteAdminUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const deleteAdminUser = await api.deleteAdminUser(id);
			showSuccessToast(pageStrings.delete(deleteAdminUser.name));
			this.paginetedListShelf.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
