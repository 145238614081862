import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import Create from "./Create";

const TableApprovedDepositsMaster: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/marginUSD" component={Table} />
		<Route exact path="/dashboard/adminMaster/marginUSD/create" component={Create} />
	</Switch>
);
export default observer(TableApprovedDepositsMaster);
