import React from "react";
import { observer } from "mobx-react-lite";
import { Button, HStack } from "@chakra-ui/react";

import { TextInput } from "~/components";
import strings from "~/resources/strings";
import { showErrorToast } from "~/resources/toast";

interface IProps {
	onClick: () => void;
	setValue: (string) => void;
	value: string;
	isLoading: boolean;
}

export const CpfFilter: React.FC<IProps> = observer((props) => {
	const {
		onClick,
		setValue,
		value,
		isLoading,
	} = props;
	const componentStrings = strings.components.cpfFilter;

	const onSearchClick = () => {
		if (value.includes("_")) {
			showErrorToast(componentStrings.incompleteCpf);
		} else {
			onClick();
		}
	};

	return (
		<HStack
			alignItems="flex-end"
		>
			<TextInput
				maxW={250}
				labelText={componentStrings.cpfFilter}
				labelProps={{ fontWeight: "bold", color: "primary.500" }}
				type="text"
				isDisabled={isLoading}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				bgColor="white"
				mask="999.999.999-99"
				_hover={{
					bgColor: "white",
				}}
			/>
			<Button
				onClick={onSearchClick}
			>
				{componentStrings.searchButton}
			</Button>
		</HStack>
	);
});
