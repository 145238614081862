import React from "react";
import { Store } from "./store";
import { useParams, useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	Button,
	Text,
	Flex,
} from "@chakra-ui/react";
import { CentralizedCard, ApproveOrRefuseButton, DepositDetails } from "~/components";
import strings from "~/resources/strings";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageString = strings.pendingDeposits.details;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoToThePendingDepositsList = () => history.goBack();

	return (
		<Flex justifyContent="center" flexDirection="column" alignItems="center">
			<CentralizedCard
				title={{
					text: strings.common.detailsTitle,
				}}
				isLoading={store.fetchModelShelf.initialLoader.isLoading}
			>
				{store.fetchModelShelf.model.value ? (
					<>
						<DepositDetails
							deposit={store.fetchModelShelf.model.value}
							amountAttributeShelf={store.amountToApprove}
							isLoading={store.loader.isLoading}
						/>
						<ApproveOrRefuseButton
							approve={store.approveOrRefuseDeposit}
							refuse={store.approveOrRefuseDeposit}
							id={store.fetchModelShelf.fetchedModel.id}
						/>
					</>
				) : (
					store.fetchModelShelf.initialLoader.isLoaded && (
						<Text
							color="primary.500"
							fontSize="xl"
							fontWeight="bold"
							my={5}
						>
							{pageString.notFound}
						</Text>
					)
				)}
			</CentralizedCard>
			<Button
				onClick={() => onGoToThePendingDepositsList()}
				w={{
					base: "50%",
					md: "15%",
				}}
				mb={5}
				variant="outline"
				leftIcon={
					<ArrowBackIcon />
				}
			>
				{strings.actions.back}
			</Button>
		</Flex>
	);
};

export default observer(Details);
