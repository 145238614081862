import React from "react";
import { observer } from "mobx-react-lite";
import {
	BoxProps,
	Flex,
	Heading,
	Text,
} from "@chakra-ui/react";
import strings from "~/resources/strings";

interface ICardProps extends BoxProps {
	quota: string;
	balance: string;
}

export const CardComissionInfo: React.FC<ICardProps> = observer((props) => {
	const { quota, balance } = props;

	return (
		<Flex
			direction="column"
			w={{
				base: "80%",
				md: "50%",
				lg: "40%",
			}}
			mt={10}
			mx={{
				base: "auto",
				md: "unset",
			}}
		>
			<Heading
				w="100%"
				maxW="100%"
				variant="secondary"
				size="md"
				color="primary.500"
				mb={3}
			>
				{strings.quotasTable.cards.titleCard}
			</Heading>
			<Flex
				bg="white"
				rounded="xl"
				direction="column"
				boxShadow="xl"
			>
				<Flex
					borderBottom="2px solid"
					borderColor="primary.500"
					p={3}
				>
					<Text
						w="50%"
						align="center"
						color="primary.500"
						fontWeight="bold"
					>
						{strings.quotasTable.cards.balance}
					</Text>
					<Text
						w="50%"
						align="center"
						color="primary.500"
						fontWeight="bold"
					>
						{strings.quotasTable.cards.quotas}
					</Text>
				</Flex>
				<Flex
					p={3}
					justifyContent="space-around"
				>
					<Text w="50%" align="center">{balance}</Text>
					<Text w="50%" align="center">{strings.format.formatQuota(quota)}</Text>
				</Flex>
			</Flex>
		</Flex>
	);
});
