import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableView from "./Table";
import DetailsView from "./Details";


const InvitedUsers: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/invitedUsers" component={TableView} />
		<Route exact path="/dashboard/adminMaster/invitedUsers/details/:id" component={DetailsView} />
	</Switch>
);
export default observer(InvitedUsers);
