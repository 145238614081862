import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";
import API from "~/resources/api";

const strings = new LocalizedStrings({
	ptBR: {
		removeCharactersExceptLetterNumber: (character: string) => character.replace(/[^a-zA-Z0-9]/g, ""),
		enum: {
			BankAccountType: {
				contaCorrente: "Conta Corrente",
				contaCorrenteConjunta: "Conta Corrente Conjunta",
				contaPoupanca: "Conta Poupança",
				contaPoupancaConjunta: "Conta Poupança Conjunta",
			},
			PixType: {
				Phone: "Celular",
				email: "Email",
				cpf: "Documento (CPF / CNPJ)",
				random: "Aleatória",
			},
			DepositStatus: {
				approved: "Aprovado",
				pending: "Pendente",
				refused: "Recusado",
			},
			WithdrawalStatus: {
				approved: "Aprovado",
				pending: "Pendente",
				refused: "Recusado",
			},
			PeriodFilter: {
				fiveDays: "5 dias atrás",
				fifteenDays: "15 dias atrás",
				oneMonth: "1 Mês atrás",
				sixMonths: "6 Meses atrás",
				oneYear: "1 Ano atrás",
				twoYears: "2 Anos atrás",
				fiveYears: "5 Anos atrás",
				tenYears: "10 Anos atrás",
			},
			CommissionTableType: {
				typeOne: "Tabela 1",
				typeTwo: "Tabela 2",
				typeThree: "Tabela 3",
			},
		},
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			cpfFilter: {
				cpfFilter: "Filtrar por CPF",
				clearFilter: "Limpar Filtro",
				searchButton: "Buscar",
				incompleteCpf: "Digite o CPF completo para realizarmos a busca",
			},
			detailsWithdraw: {
				askedBy: "Solicitado por:",
				createdAt: "Data solicitação:",
				amount: "Quantidade de DSC coin",
				price: "Preço do DSC Coin",
				totalValue: "Valor total R$ (BRL)",
			},
			detailsDeposit: {
				userBankData: "Dados da conta bancária do usuário:",
				adminBankData: "Dados da conta bancária de destino",
			},
		},
		nav: {
			home: "Home",
			adminWithdrawUsers: "Usuários Admins Saque",
			adminDepositAndApprovalsUsers: "Usuários Admins Depósitos e Aprovações",
			pendingDeposits: "Depósitos Pendentes",
			adminMaster: "Usuários Admins Master",
			approvedWithdrawal: "Saques aprovados",
			deposits: "Depósitos aprovados",
			bankAccounts: "Contas Bancárias",
			users: "Usuários",
			invitedUsers: "Usuários indicados",
			pendingUsers: "Usuários Pendentes",
			pendingWithdraws: "Saques Pendentes",
			marginUSD: "Margem USD",
			deletionRequests: "Pedidos de exclusão",
			quotas: "Cotas das Moedas",
			profit: "Lucro da moeda",
			userTransfer: "Transferência entre usuários",
			logout: "Sair",
			indicationTexts: "Textos de indicação",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			select: "Selecionar",
			back: "Voltar",
			add: "Adicionar",
			send: "Enviar",
			confirm: "Confirmar",
			refuse: "Recusar",
			approve: "Aprovar",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "dd/MM/yyyy",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
			selectImage:"É necessário selecionar uma imagem para o banner",
			currentUserIsNotSuperAdmin: "Sem Permissão para acessar rota. Não é super admin. Faça um novo login.",
			currentUserIsNotDepositAndApprovalsAdmin: "Sem Permissão para acessar rota. Não é um admin deposito e aprovações. Faça um novo login.",
			currentUserIsNotWithdrawAdmin: "Sem Permissão para acessar rota. Não é um admin saque. Faça um novo login.",
			receiptImageNotAdded: "Um Comprovante precisa ser adicionado.",
		},
		common: {
			yes: "Sim",
			no: "Não",
			noResutls: "Sem Resultados",
			delete: "Clique para deletar",
			disable: "Clique para desabilitar",
			enable: "Clique para habilitar",
			details: "Clique para ver mais informações",
			approve: "Clique para aprovar ou recusar",
			edit: "Clique para editar",
			cancel: "Cancelar",
			finish: "Finalizar",
			confirm: "Confirmar",
			detailsTitle: "Detalhes",
			editTitle: "Editar",
			send: "Enviar",
			successChangeType: "Alterado com Sucesso",
			documentPhoto: "Foto:",
			documentProof: "Comprovante:",
			setDocumentProof: "Anexar Comprovante: *",
			documentProofNoResult: "Comprovante não encontrado",
			close: "Fechar",
			buttons: {
				confirmButton: (isEdit?: boolean) => isEdit ? "Editar" : "Enviar",
				backButton: "Voltar",
			},
			empty: "Vazio",
			infoDeposit: "Informações do Deposito:",
			infoWithdraw: "Informações do Saque:",
			infoUser: "Informações do Usuaŕio:",
			originBankAccount: "Conta Bancária de Origem:",
			destinyBankAccount: "Conta Bancária de Destino:",
			fields: {
				socialReason: "Razão Social:",
				cnpj: "CNPJ:",
				name: "Nome:",
				email: "E-mail:",
				password: "Senha:",
				cpf: "CPF:",
				phone: "Celular:",
				address: "Endereço:",
				disable: "Desabilitar:",
				approvedBy: "Aprovado por:",
				photo: "Foto:",
				mobilePhoto:"Foto Mobile:",
				account: "Conta:",
				typeAccount: "Tipo da Conta:",
				accountDv: "Dígito da Conta:",
				agency: "Agência:",
				agencyDv: "Dígito da Agência:",
				bankCode: "Código do Banco:",
				bankName: "Banco:",
				documentPhoto:"Comprovante de residência:",
				receiptImage: "Comprovante de transferência:",
				value: "Valor:",
				createAt: "Criado em:",
				returnedValue: "Valor retornado",
				percentage: "Porcentagem",
				acceptedAt: "Aceito em:",
				acceptedAtHour: "Horário:",
				status: "Situação:",
				approvedValue: "Valor aprovado:",
				pendingValue: "Valor a ser aprovado:",
				approvedAt: "Data de confirmação:",
				hour: "Horário:",
				city: "Cidade",
				entranceValue: "Valor de Entrada",
				concatData: (left: string, right: string | null) =>
					right ? `${left} - ${right}` : left,
				pix: "Pix:",
				pixType: "Tipo da Chave Pix:",
				labelDepositAmount: (accepter: API.AdminUser |null) =>
					accepter ? "Valor aprovado:": "Valor à ser aprovado:",
				labelDSCAmount: (accepted: boolean) =>
					accepted ? "Valor em USD aprovado:": "Valor USD à ser aprovado:",
				currentPrice: "Valor Atual:",
				buyPrice: "Preço de compra:",
				sellPrice: "Preço de venda:",
				sender: "Remetente:",
				receiver: "Destinatário:",
				amount: "Quantidade DSC Coin:",
				amountApproved: "Quantidade DSC Coin Aprovada:",
				customAmount: "Quantidade DSC Coin Corrigida (opcional):",
				customBRLAmount: "Quantidade em Reais Atualizada Corrigida (opcional):",
				customBRLAmountApproved: "Quantidade em Reais Atualizada Corrigida",
				placeHolderAmountToApprove: "Valor em centavos",
			},
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			modal: {
				title: "Deletar",
				titleBlock: (isBlock: boolean) => isBlock ? "Desbloquear" : "Bloquear",
				titleApproveOrRefuse: "Aprovar ou Recusar",
				titleRefuse: "Deseja excluir pedido?",
				titleApprove: "Deseja confirmar exclusão?",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				descriptionRemove: (itemName: string) => `Tem certeza que deseja aceitar o pedido de exclusão de ${itemName}?`,
				descriptionCancelRequest: (itemName: string) => `Recusar pedido exclusão de ${itemName}?`,
				descriptionBlock: (itemName: string, isBlock: Date | null) => isBlock ? `Tem certeza que deseja desbloquear ${itemName}?` : `Tem certeza que deseja bloquear ${itemName}?`,
				descriptionApproveOrRefuse: (ItemName: string) => `Deseja aprovar ou recusar ${ItemName}?`,
				descriptionApproveOrRefuseUser: (approve: boolean | null) => approve ? "tem certeza que deseja aprovar ?" : "tem certeza que deseja recusar ?",
				statusUser: (isUserBlocked: boolean) => `O usuário ${isUserBlocked ? "foi bloqueado" : "foi desbloqueado"}`,
				titleUpdateMarginUSD: "Deseja atualizar margem USD?",
				titleCreateProfit: "Deseja adicionar lucro da moeda ?",
				titleCreatePoupanca: "Deseja adicionar rendimento da poupança?",
				titleCreateIndicationText: "Deseja adicionar texto de indicação ?",
				button: {
					approve: "Aprovar",
					refuse: "Recusar",
					remove: "Remover",
					delete: "Deletar",
					cancel: "Cancelar",
					block: "Bloquear",
					disable: "Bloquear",
					enable: "Desbloquear",
					confirm: "Confirmar",
				},
			},
		},
		home: {
			message: (userName: string) => `Bem vindo ao Dashboard,  ${userName}!`,
		},
		adminUserTable: {
			table: {
				title: "Lista de administradores",
				header: ["", "Nome do usuário", "Email", "CPF", "Data de criação"],
				delete: (title: string) => `Administrador ${title} foi deletado!`,
				totalText: "Administrador cadastrados",
				addButtonText: "Adicionar novo administrador",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Editar" : "Cadastrar usuário administrador",
				success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
			},
		},
		usersTable: {
			table: {
				title: "Lista de usuários",
				header: ["", "Nome do usuário", "CPF", "Email", "Data de criação"],
				delete: (user: string) => `Usuario ${user} foi deletado!`,
				disable: (title: string) => `Usuário ${title} foi bloqueado!`,
				enable: (title: string) => `Usuário ${title} foi desbloqueado!`,
				totalText: "Usuários cadastrados",
				successCpfFilter: "Usuário encontrado",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Editar" : "Cadastrar usuário administrador",
				success: (isEdit?: boolean) => isEdit ? "Administrador editado com sucesso" : "Administrador criado com sucesso",
			},
		},
		IndicationTexts: {
			table: {
				title: "Lista de textos de indicação",
				header: ["", "Id", "Data de Criação", "Texto"],
				delete: "Texto deletado com sucesso!",
			},
			details: {
				id: "Id:",
				text: "Texto de indicação:",
			},
			create: {
				success: "Texto de indicação adicionado com sucesso!",
				message: "Adicionar o {LINK} (exatamente como está escrito) no texto para ser substituído pelo link de compartilhamento",
			},
		},
		invitedUsersTable: {
			table: {
				title: "Lista de usuários Indicados",
				header: ["", "Nome do usuário", "CPF", "Email", "Indicado por", "Data de criação"],
			},
		},
		quotasTable: {
			table: {
				title: "Lista de Cotas",
				header: ["Nome do usuário", "Moedas", "Cotas"],
			},
			cards: {
				totalCoins: "Quantidade Moedas Totais",
				totalUsers: "Usuarios com Moedas",
				balance: "Saldo",
				quotas: "Cota",
				titleCard: "Dados de comissão do DSC",
			},
		},
		reportTable: {
			table: {
				title: "Relatórios DSC Indica",
				header: ["Numero do Cliente", "Nome", "Estado", "Indicados com Cadastro","Indicados que Depositaram", "Comissão", "Tabela de Comissão"],
				titleModal: "Alterar Tabela de Comissao",
			},
		},
		profitTable: {
			table: {
				title: "Tabela de Lucros",
				header: ["Criado em", "RIG", "Valor de entrada", "Variação de lucro", "Valor retornado"],
				addHistory: "Adicionar Lucros Passados",
				addProfit: "Adicionar e Calcular Lucro",
			},
			create: {
				title: "Adicionar Lucro da Moeda",
				success: "Lucro adicionado com sucesso",
				message: "*Somente é possivel adicionar lucro da moeda quando o de recalculo de cotas de movimentações for concluída",
				messageHistory: "Adicionar lucros passados não faz nenhum tipo de calculo.",
			},
		},
		poupanca: {
			table: {
				title: "Tabela da Poupança",
				header: ["Data", "Porcentagem"],
				addPoupanca: "Adicionar Poupança",
			},
			create: {
				title: "Adicionar Poupança",
				success: "Poupança adicionada com sucesso",
			},
		},
		pendingDeposits: {
			table: {
				title: "Lista de depósitos pendentes",
				header: ["", "Usuário", "CPF", "Quantidade", "Valor", "Criado em"],
				modalDescription: "Deseja aprovar ou recusar esse deposito?",
				successApproveOrRefuse: (isApprove: boolean) => `Deposito${isApprove ? " aprovado" : " recusado"} com sucesso`,
			},
			details: {
				userName: "Usuário do depósito:",
				userDocument: "CPF do usuário:",
				approvedValue: "Valor aprovador:",
				notFound: "Houve um erro, depósito não encontrado",
			},
		},
		pendingWithdraw: {
			table: {
				title: "Lista de saques pendentes",
				headers: ["", "Usuário", "CPF", "Quantidade", "Valor", "criado em"],
				modalDescription: "Deseja aprovar ou recusar esse saque?",
				successApproveOrRefuse: (isApprove: boolean) => `Saque${isApprove ? " aprovado" : " recusado"} com sucesso`,
			},
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			success: (userName: string) => `Bem vindo ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			email: "Email",
			token: "Token",
			validToken: "Token válido!",
			confirmTokenButton: "Confirmar Token",
			tokenButton: "Já possui um token?",
			noTokenButton: "Não possuo token",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
		},
		adminDepositAndApprovals: {
			table: {
				title: "Tabela de Administradores de Depósitos e Aprovações",
				headers: ["", "Nome", "E-mail", "CPF", "Data de Criação"],
				delete: (adminDepositAndApprovalsName: string) => `Administrador de Depósitos e Aprovações ${adminDepositAndApprovalsName} foi deletado!`,
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit
					? "Edição de Administrador de Depósitos e Aprovações" : "Cadastro de Novo Administrador de Depósitos e Aprovações",
				success: (isEdit?: boolean) => isEdit
					? "Administrador de Depósitos e Aprovações editado com sucesso" : "Administrador de Depósitos e Aprovações criado com sucesso",
			},
		},
		deposits: {
			table: {
				title: "Lista de depósitos aprovados",
				headers: ["", "Usuário", "CPF", "Quantidade Aprovada", "Valor", "Aprovado em"],
			},
		},
		adminWithdraw: {
			table: {
				title: "Tabela de Administradores de Saque",
				headers: ["", "Nome", "E-mail", "CPF", "Data de Criação"],
				delete: (adminWithdrawName: string) => `Saque do Administrador ${adminWithdrawName} foi deletado!`,
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Edição de Administrador de Saque" : "Cadastro de Novo Administrador de Saque",
				success: (isEdit?: boolean) => isEdit ? "Administrador de Saque editado com sucesso" : "Administrador de Saque criado com sucesso",
			},
		},
		bankAccounts: {
			table: {
				title: "Lista de Contas Bancárias",
				header: ["", "Agência", "Dígito da Agência", "Banco", "Tipo", "Conta", "Dígito da Conta"],
				delete: (bankAccount: string) => `Conta bancária ${bankAccount} foi deletada!`,
				totalText: "Conta bancária cadastrada com sucesso",
				addButtonText: "Adicionar nova conta bancária",
			},
			createOrEdit: {
				title: (isEdit?: boolean) => isEdit ? "Editar" : "Cadastrar nova conta bancária",
				success: (isEdit?: boolean) => isEdit ? "Conta bancária editada com sucesso" : "Conta bancária criada com sucesso",
			},
			banksCode: [
				{
					code: "001",
					name: "Banco do Brasil S.A.",
				},
				{
					code: "003",
					name: "Banco da Amazônia S.A.",
				},
				{
					code: "004",
					name: "Banco do Nordeste do Brasil S.A.",
				},
				{
					code: "007",
					name: "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
				},
				{
					code: "010",
					name: "Credicoamo",
				},
				{
					code: "011",
					name: "C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)",
				},
				{
					code: "012",
					name: "Banco Inbursa S.A.",
				},
				{
					code: "014",
					name: "Natixis Brasil S.A. Banco Múltiplo",
				},
				{
					code: "016",
					name: "Ccm Desp Trâns Sc E Rs",
				},
				{
					code: "017",
					name: "BNY Mellon Banco S.A.",
				},
				{
					code: "018",
					name: "Banco Tricury S.A.",
				},
				{
					code: "021",
					name: "BANESTES S.A. Banco do Estado do Espírito Santo",
				},
				{
					code: "024",
					name: "Banco BANDEPE S.A.",
				},
				{
					code: "025",
					name: "Banco Alfa S.A.",
				},
				{
					code: "029",
					name: "Banco Itaú Consignado S.A.",
				},
				{
					code: "033",
					name: "Banco Santander (Brasil) S.A.",
				},
				{
					code: "036",
					name: "Banco Bradesco BBI S.A.",
				},
				{
					code: "037",
					name: "Banco do Estado do Pará S.A.",
				},
				{
					code: "040",
					name: "Banco Cargill S.A.",
				},
				{
					code: "041",
					name: "Banco do Estado do Rio Grande do Sul S.A.",
				},
				{
					code: "047",
					name: "Banco do Estado de Sergipe S.A.",
				},
				{
					code: "051",
					name: "Banco de Desenvolvimento do Espírito Santo S.A.",
				},
				{
					code: "060",
					name: "Confidence Cc S.A",
				},
				{
					code: "062",
					name: "Hipercard Banco Múltiplo S.A.",
				},
				{
					code: "063",
					name: "Banco Bradescard S.A.",
				},
				{
					code: "064",
					name: "Goldman Sachs do Brasil Banco Múltiplo S.A.",
				},
				{
					code: "065",
					name: "Banco Andbank (Brasil) S.A.",
				},
				{
					code: "066",
					name: "Banco Morgan Stanley S.A.",
				},
				{
					code: "069",
					name: "Banco Crefisa S.A.",
				},
				{
					code: "070",
					name: "BRB – Banco de Brasília S.A.",
				},
				{
					code: "074",
					name: "Banco J. Safra S.A.",
				},
				{
					code: "075",
					name: "Banco ABN AMRO S.A",
				},
				{
					code: "076",
					name: "Banco KDB S.A.",
				},
				{
					code: "077",
					name: "Banco Inter S.A.",
				},
				{
					code: "078",
					name: "Haitong Banco de Investimento do Brasil S.A.",
				},
				{
					code: "079",
					name: "Picpay Bank",
				},
				{
					code: "080",
					name: "B&T Cc Ltda",
				},
				{
					code: "081",
					name: "BancoSeguro S.A.",
				},
				{
					code: "082",
					name: "Banco Topázio S.A.",
				},
				{
					code: "083",
					name: "Banco da China Brasil S.A.",
				},
				{
					code: "084",
					name: "Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências",
				},
				{
					code: "085",
					name: "Coop Central Ailos",
				},
				{
					code: "089",
					name: "Ccr Reg Mogiana",
				},
				{
					code: "091",
					name: "Unicred Central Rs",
				},
				{
					code: "092",
					name: "Brickell S.A. Crédito, Financiamento e Investimento",
				},
				{
					code: "093",
					name: "Pólocred Scmepp Ltda",
				},
				{
					code: "094",
					name: "Banco Finaxis S.A.",
				},
				{
					code: "095",
					name: "Travelex Banco de Câmbio S.A.",
				},
				{
					code: "096",
					name: "Banco B3 S.A.",
				},
				{
					code: "097",
					name: "Cooperativa Central de Crédito Noroeste Brasileiro Ltda.",
				},
				{
					code: "098",
					name: "Credialiança Ccr",
				},
				{
					code: "099",
					name: "UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.",
				},
				{
					code: "100",
					name: "Planner Corretora De Valores S.A",
				},
				{
					code: "101",
					name: "Renascenca Dtvm Ltda",
				},
				{
					code: "102",
					name: "Xp Investimentos S.A",
				},
				{
					code: "104",
					name: "Caixa Econômica Federal",
				},
				{
					code: "105",
					name: "Lecca Crédito, Financiamento e Investimento S/A",
				},
				{
					code: "107",
					name: "Banco BOCOM BBM S.A.",
				},
				{
					code: "108",
					name: "Portocred S.A",
				},
				{
					code: "111",
					name: "Banco Oliveira Trust Dtvm S.A",
				},
				{
					code: "113",
					name: "Neon Corretora de Títulos e Valores",
				},
				{
					code: "114",
					name: "Central Cooperativa De Crédito No Estado Do Espírito Santo",
				},
				{
					code: "117",
					name: "Advanced Cc Ltda",
				},
				{
					code: "118",
					name: "Standard Chartered Bank (Brasil) S/A–Bco Invest.",
				},
				{
					code: "119",
					name: "Banco Western Union do Brasil S.A.",
				},
				{
					code: "120",
					name: "Banco Rodobens S.A.",
				},
				{
					code: "121",
					name: "Banco Agibank S.A.",
				},
				{
					code: "121",
					name: "Banco Agibank S.A.",
				},
				{
					code: "122",
					name: "Banco Bradesco BERJ S.A.",
				},
				{
					code: "124",
					name: "Banco Woori Bank do Brasil S.A.",
				},
				{
					code: "125",
					name: "Banco Genial S.A.",
				},
				{
					code: "126",
					name: "BR Partners Banco de Investimento S.A.",
				},
				{
					code: "127",
					name: "Codepe Cvc S.A",
				},
				{
					code: "128",
					name: "MS Bank S.A. Banco de Câmbio",
				},
				{
					code: "129",
					name: "UBS Brasil Banco de Investimento S.A.",
				},
				{
					code: "130",
					name: "Caruana Scfi",
				},
				{
					code: "131",
					name: "Tullett Prebon Brasil Cvc Ltda",
				},
				{
					code: "132",
					name: "ICBC do Brasil Banco Múltiplo S.A.",
				},
				{
					code: "133",
					name: "Cresol Confederação",
				},
				{
					code: "134",
					name: "Bgc Liquidez Dtvm Ltda",
				},
				{
					code: "136",
					name: "Unicred Cooperativa",
				},
				{
					code: "137",
					name: "Multimoney Cc Ltda",
				},
				{
					code: "138",
					name: "Get Money Cc Ltda",
				},
				{
					code: "139",
					name: "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
				},
				{
					code: "140",
					name: "Easynvest – Título Cv S.A",
				},
				{
					code: "142",
					name: "Broker Brasil Cc Ltda",
				},
				{
					code: "143",
					name: "Treviso Corretora de Câmbio S.A.",
				},
				{
					code: "144",
					name: "BEXS Banco de Câmbio S.A.",
				},
				{
					code: "145",
					name: "Levycam Ccv Ltda",
				},
				{
					code: "146",
					name: "Guitta Corretora de Câmbio Ltda",
				},
				{
					code: "149",
					name: "Facta S.A. Cfi",
				},
				{
					code: "157",
					name: "Icap Do Brasil Ctvm Ltda",
				},
				{
					code: "159",
					name: "Casa Credito S.A",
				},
				{
					code: "163",
					name: "Commerzbank Brasil S.A. – Banco Múltiplo",
				},
				{
					code: "169",
					name: "Banco Olé Bonsucesso Consignado S.A.",
				},
				{
					code: "172",
					name: "Albatross Ccv S.A",
				},
				{
					code: "173",
					name: "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
				},
				{
					code: "174",
					name: "Pernambucanas Financ S.A",
				},
				{
					code: "177",
					name: "Guide Investimentos S.A. Corretora de Valores",
				},
				{
					code: "180",
					name: "Cm Capital Markets Cctvm Ltda",
				},
				{
					code: "182",
					name: "Dacasa Financeira S/A",
				},
				{
					code: "183",
					name: "Socred S.A",
				},
				{
					code: "184",
					name: "Banco Itaú BBA S.A.",
				},
				{
					code: "188",
					name: "Ativa Investimentos S.A",
				},
				{
					code: "189",
					name: "HS Financeira S/A Crédito, Financiamento e Investimentos",
				},
				{
					code: "190",
					name: "Servicoop",
				},
				{
					code: "191",
					name: "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
				},
				{
					code: "194",
					name: "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda",
				},
				{
					code: "196",
					name: "Banco Fair Corretora de Câmbio S.A",
				},
				{
					code: "197",
					name: "Stone Pagamentos S.A",
				},
				{
					code: "204",
					name: "Banco Bradesco Cartões S.A.",
				},
				{
					code: "208",
					name: "Banco BTG Pactual S.A.",
				},
				{
					code: "212",
					name: "Banco Original S.A.",
				},
				{
					code: "213",
					name: "Banco Arbi S.A.",
				},
				{
					code: "217",
					name: "Banco John Deere S.A.",
				},
				{
					code: "218",
					name: "Banco BS2 S.A.",
				},
				{
					code: "222",
					name: "Banco Credit Agricole Brasil S.A.",
				},
				{
					code: "224",
					name: "Banco Fibra S.A.",
				},
				{
					code: "233",
					name: "Banco Cifra S.A.",
				},
				{
					code: "237",
					name: "Banco Bradesco S.A.",
				},
				{
					code: "241",
					name: "Banco Clássico S.A.",
				},
				{
					code: "243",
					name: "Banco Máxima S.A.",
				},
				{
					code: "246",
					name: "Banco ABC Brasil S.A.",
				},
				{
					code: "249",
					name: "Banco Investcred Unibanco S.A.",
				},
				{
					code: "250",
					name: "BCV – Banco de Crédito e Varejo S.A.",
				},
				{
					code: "253",
					name: "Bexs Corretora de Câmbio S/A",
				},
				{
					code: "254",
					name: "Paraná Banco S.A.",
				},
				{
					code: "260",
					name: "Nu Pagamentos S.A (Nubank)",
				},
				{
					code: "265",
					name: "Banco Fator S.A.",
				},
				{
					code: "266",
					name: "Banco Cédula S.A.",
				},
				{
					code: "268",
					name: "Barigui Companhia Hipotecária",
				},
				{
					code: "269",
					name: "HSBC Brasil S.A. – Banco de Investimento",
				},
				{
					code: "270",
					name: "Sagitur Corretora de Câmbio Ltda.",
				},
				{
					code: "271",
					name: "IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.",
				},
				{
					code: "273",
					name: "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel",
				},
				{
					code: "276",
					name: "Senff S.A. – Crédito, Financiamento e Investimento",
				},
				{
					code: "278",
					name: "Genial Investimentos Corretora de Valores Mobiliários S.A.",
				},
				{
					code: "279",
					name: "Cooperativa de Crédito Rural de Primavera Do Leste",
				},
				{
					code: "280",
					name: "Avista S.A. Crédito, Financiamento e Investimento",
				},
				{
					code: "283",
					name: "Rb Capital Investimentos Dtvm Ltda",
				},
				{
					code: "285",
					name: "Frente Corretora de Câmbio Ltda.",
				},
				{
					code: "286",
					name: "Cooperativa de Crédito Rural De Ouro",
				},
				{
					code: "288",
					name: "Carol Distribuidora de Títulos e Valor Mobiliários Ltda",
				},
				{
					code: "290",
					name: "Pagseguro Internet S.A",
				},
				{
					code: "292",
					name: "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
				},
				{
					code: "293",
					name: "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
				},
				{
					code: "298",
					name: "Vips Cc Ltda",
				},
				{
					code: "299",
					name: "Sorocred Crédito, Financiamento e Investimento S.A.",
				},
				{
					code: "300",
					name: "Banco de La Nacion Argentina",
				},
				{
					code: "301",
					name: "Bpp Instituição De Pagamentos S.A",
				},
				{
					code: "307",
					name: "Terra Investimentos Distribuidora de Titulos",
				},
				{
					code: "310",
					name: "Vortx Distribuidora de Títulos e Valores Mobiliários Ltda",
				},
				{
					code: "313",
					name: "Amazônia Corretora de Câmbio Ltda",
				},
				{
					code: "318",
					name: "Banco BMG S.A.",
				},
				{
					code: "320",
					name: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
				},
				{
					code: "323",
					name: "Mercado Pago – Conta Do Mercado Livre",
				},
				{
					code: "325",
					name: "Orama Dtvm S.A.",
				},
				{
					code: "326",
					name: "Parati – Crédito Financiamento e Investimento S.A.",
				},
				{
					code: "332",
					name: "Acesso Solucoes Pagamentos S.A.",
				},
				{
					code: "335",
					name: "Banco Digio S.A",
				},
				{
					code: "336",
					name: "Banco C6 S.A – C6 Bank",
				},
				{
					code: "340",
					name: "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
				},
				{
					code: "341",
					name: "Itaú Unibanco S.A.",
				},
				{
					code: "348",
					name: "Banco Xp S/A",
				},
				{
					code: "349",
					name: "Al5 S.A.",
				},
				{
					code: "352",
					name: "Toto Ctvm S.A.",
				},
				{
					code: "366",
					name: "Banco Société Générale Brasil S.A.",
				},
				{
					code: "368",
					name: "Banco Carrefour",
				},
				{
					code: "370",
					name: "Banco Mizuho do Brasil S.A.",
				},
				{
					code: "376",
					name: "Banco J. P. Morgan S.A.",
				},
				{
					code: "380",
					name: "Picpay Servicos S.A.",
				},
				{
					code: "389",
					name: "Banco Mercantil de Investimentos S.A.",
				},
				{
					code: "389",
					name: "Banco Mercantil do Brasil S.A.",
				},
				{
					code: "394",
					name: "Banco Bradesco Financiamentos S.A.",
				},
				{
					code: "399",
					name: "Kirton Bank S.A. – Banco Múltiplo",
				},
				{
					code: "406",
					name: "Acrredito",
				},
				{
					code: "412",
					name: "Banco Capital S.A.",
				},
				{
					code: "422",
					name: "Banco Safra S.A.",
				},
				{
					code: "456",
					name: "Banco MUFG Brasil S.A.",
				},
				{
					code: "461",
					name: "Asass Ip S.A.",
				},
				{
					code: "463",
					name: "Azumi Distribuidora de Títulos e Valores",
				},
				{
					code: "464",
					name: "Banco Sumitomo Mitsui Brasileiro S.A.",
				},
				{
					code: "473",
					name: "Banco Caixa Geral – Brasil S.A.",
				},
				{
					code: "477",
					name: "CitibankN.A.",
				},
				{
					code: "479",
					name: "Banco ItauBank S.A",
				},
				{
					code: "487",
					name: "Deutsche Bank S.A. – Banco Alemão",
				},
				{
					code: "488",
					name: "JPMorgan Chase Bank, National Association",
				},
				{
					code: "492",
					name: "ING BankN.V.",
				},
				{
					code: "494",
					name: "Banco de La Republica Oriental del Uruguay",
				},
				{
					code: "495",
					name: "Banco de La Provincia de Buenos Aires",
				},
				{
					code: "505",
					name: "Banco Credit Suisse (Brasil) S.A.",
				},
				{
					code: "508",
					name: "Avenue Secutities Dtvm Ltda",
				},
				{
					code: "545",
					name: "Senso Ccvm S.A",
				},
				{
					code: "600",
					name: "Banco Luso Brasileiro S.A.",
				},
				{
					code: "604",
					name: "Banco Industrial do Brasil S.A.",
				},
				{
					code: "610",
					name: "Banco VR S.A.",
				},
				{
					code: "611",
					name: "Banco Paulista S.A.",
				},
				{
					code: "612",
					name: "Banco Guanabara S.A.",
				},
				{
					code: "613",
					name: "Omni Banco S.A.",
				},
				{
					code: "613",
					name: "Omni Banco S.A.",
				},
				{
					code: "623",
					name: "Banco PAN S.A.",
				},
				{
					code: "626",
					name: "Banco Ficsa S.A.",
				},
				{
					code: "630",
					name: "Banco Smartbank S.A.",
				},
				{
					code: "633",
					name: "Banco Rendimento S.A.",
				},
				{
					code: "634",
					name: "Banco Triângulo S.A.",
				},
				{
					code: "637",
					name: "Banco Sofisa S.A.",
				},
				{
					code: "641",
					name: "Banco Alvorada S.A.",
				},
				{
					code: "643",
					name: "Banco Pine S.A.",
				},
				{
					code: "652",
					name: "Itaú Unibanco Holding S.A.",
				},
				{
					code: "653",
					name: "Banco Indusval S.A.",
				},
				{
					code: "654",
					name: "Banco A.J.Renner S.A.",
				},
				{
					code: "655",
					name: "Banco Votorantim S.A.",
				},
				{
					code: "658",
					name: "Banco Porto Real de Investimentos S.A.",
				},
				{
					code: "707",
					name: "Banco Daycoval S.A.",
				},
				{
					code: "707",
					name: "Banco Daycoval S.A.",
				},
				{
					code: "712",
					name: "Banco Ourinvest S.A.",
				},
				{
					code: "720",
					name: "Banco Maxinvest S.A.",
				},
				{
					code: "739",
					name: "Banco Cetelem S.A.",
				},
				{
					code: "741",
					name: "Banco Ribeirão Preto S.A.",
				},
				{
					code: "743",
					name: "Banco Semear S.A.",
				},
				{
					code: "745",
					name: "Banco Citibank S.A.",
				},
				{
					code: "746",
					name: "Banco Modal S.A.",
				},
				{
					code: "747",
					name: "Banco Rabobank International Brasil S.A.",
				},
				{
					code: "748",
					name: "Banco Cooperativo Sicredi S.A.",
				},
				{
					code: "748",
					name: "Banco Cooperativo Sicredi S.A.",
				},
				{
					code: "751",
					name: "Scotiabank Brasil S.A. Banco Múltiplo",
				},
				{
					code: "752",
					name: "Banco BNP Paribas Brasil S.A.",
				},
				{
					code: "753",
					name: "Novo Banco Continental S.A. – Banco Múltiplo",
				},
				{
					code: "754",
					name: "Banco Sistema S.A.",
				},
				{
					code: "755",
					name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
				},
				{
					code: "756",
					name: "Banco Cooperativo do Brasil S.A. – BANCOOB",
				},
				{
					code: "757",
					name: "Banco KEB HANA do Brasil S.A.",
				},
				{
					code: "085-x",
					name: "Cooperativa Central de Crédito Urbano-CECRED",
				},
				{
					code: "087-6",
					name: "Cooperativa Central de Economia e Crédito Mútuo das Unicreds de Santa Catarina e Paraná",
				},
				{
					code: "089-2",
					name: "Cooperativa de Crédito Rural da Região da Mogiana",
				},
				{
					code: "090-2",
					name: "Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS",
				},
				{
					code: "091-4",
					name: "Unicred Central do Rio Grande do Sul",
				},
				{
					code: "098-1",
					name: "CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL",
				},
				{
					code: "114-7",
					name: "Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.",
				},
				{
					code: "995",
					name: "Abbc - Associação Brasileira de Bancos",
				},
				{
					code: "992",
					name: "Abbi",
				},
			],
		},
		pendingUsers: {
			successApproveOrRefuse: (itemName: string, isApprove: boolean) => `${itemName}${isApprove ? " aprovado" : " recusado"} com sucesso`,
			table: {
				title: "Tabela de usuários pendentes",
				headers: ["", "Nome", "E-mail", "CPF", "Data de Criação"],
				delete: (adminWithdrawName: string) => `Saque do Administrador ${adminWithdrawName} foi deletado!`,
			},
		},
		deletionRequests: {
			successDelete: (name: string, isApproved: boolean) => `${isApproved ? `${name} excluído com sucesso!` : `${name} pedido recusado com sucesso!` }`,
			table: {
				title: "Tabela de pedidos de exclusão",
				headers: ["", "Nome", "E-mail", "CPF", "Data de Criação"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
			},
		},
		approvedWithdrawal: {
			table: {
				title: "Lista de saques aprovados",
				headers: ["", "Usuário", "CPF", "Quantidade", "Valor", "Aprovado em"],
			},
		},
		marginUSD: {
			table: {
				title: "Histórico da Margem USD",
				headers: ["Preço Atual", "Preço de Compra", "Preço de Venda", "Data de Criação"],
			},
			create: {
				title: "Atualizar Margem USD",
				success: "Margem USD atualizada com sucesso",
			},
		},
		userTransfer: {
			table: {
				title: "Lista de Transferência Entre Usuários",
				header: ["","Quantidade", "Valor", "Remetente", "Destinário"],
			},
			details: {
				title: "Detalhes da Transferência",
				titleDetailsOfSender: "Detalhes do Remetente",
				titleDetailsOfReceiver: "Detalhes do Destinatário",
			},
		},
		format,
	},

});

export default strings;
