import React from "react";
import { observer } from "mobx-react-lite";
import {
	Button,
	Image,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	ModalHeader,
} from "@chakra-ui/react";
import { ViewIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";

interface IProps {
	imageSrc: string | undefined;
	modalTitle: string;
}

export const ModalImage: React.FC<IProps> = observer((props) => {
	const { imageSrc, modalTitle } = props;
	const { isOpen, onOpen, onClose } = useDisclosure();
	const commonStrings = strings.common;

	return (
		<>
			<Button
				w="40px"
				height="30px"
				onClick={onOpen}
				variant="outline"
			><ViewIcon />
			</Button>
			<Modal size="5xl"isOpen={isOpen} onClose={onClose}>
				<ModalOverlay bg='blackAlpha.300' backdropFilter='blur(5px) hue-rotate(90deg)' />
				<ModalContent>
					<ModalHeader>{modalTitle}</ModalHeader>
					<ModalCloseButton />
					<ModalBody padding={5}>
						<Image
							backgroundColor="white"
							src={imageSrc ? imageSrc : "imagePlaceholder"}
							alt={commonStrings.documentProofNoResult}
							rounded="lg"
							_hover={{transform:"scale(1.02)", transition:".2s"}}
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='blue' mr={3} onClick={onClose}>
							{commonStrings.close}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});
