import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import {  showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import { onFetchError } from "~/resources/fetchError";

const pageStrings = strings.poupanca;

export default class Store {
	public formShelf = new FormShelf({
		percentage: "",
	});
	public date = new AttributeShelf<Date>(new Date());
	public loader = new LoaderShelf();

	constructor(){
		makeAutoObservable(this);
	}

	public createPoupanca = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.formShelf.getValues();
			await api.createPoupanca({
				percentage: Number(data.percentage),
				date: this.date.value,
			});
			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			onFetchError(e);
		} finally {
			this.loader.end();
		}
	};
}
