import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableView from "./Table";
import Details from "./Details";

const UserTransfer: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/adminMaster/userTransfer" component={TableView} />
		<Route path="/dashboard/adminMaster/userTransfer/details/:id" component={Details} />
	</Switch>
);

export default observer(UserTransfer);
