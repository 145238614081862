import { makeAutoObservable } from "mobx";
import { FormShelf } from "@startapp/mobx-utils/src/web";
import { AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.profitTable;

export default class Store {
	public formShelf = new FormShelf({
		returnedValue: "",
	});
	public outdatedWallets =  new AttributeShelf(0);
	public loader = new LoaderShelf();

	constructor(){
		this.fetchOutdatedWallets();
		makeAutoObservable(this);
	}

	public setInitValues = (coin: api.NewCoinProfit) => {
		this.formShelf = new FormShelf({
			returnedValue: coin.returnedValue.toString(),
		});
	};

	public createProfit = async (onSuccess: () => void) => {
		this.loader.start();
		try {
			const data = this.formShelf.getValues();
			await api.createCoinProfit({
				returnedValue: Number(data.returnedValue),
			});
			showSuccessToast(pageStrings.create.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
	public fetchOutdatedWallets = async() => {
		this.loader.tryStart();
		try {
			const outdatedWallets = await api.getOutdatedWalletsQuantity();
			this.outdatedWallets.setValue(outdatedWallets);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
