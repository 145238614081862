import React from "react";
import { Store } from "./store";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons, CpfFilter } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";

const TableView: React.FC = () => {

	const pageStrings = strings.approvedWithdrawal.table;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToDetailsApprovedWithdrawal = (id: string) => history.push(`approvedWithdrawal/details/${id}`);

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.headers}
				headerFilter={
					<CpfFilter
						onClick={() => store.paginetedListShelf.fetchPage(0)}
						setValue={store.cpf.setValue}
						value={store.cpf.value}
						isLoading={store.loader.isLoading}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onView={() => onGoToDetailsApprovedWithdrawal(item.id)}
						/>
						<Td>{item.user.name}</Td>
						<Td>{item.user.documentNumber}</Td>
						<Td>{item.amount}</Td>
						<Td>{strings.format.currencyForBR(item.brlPrice)}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
