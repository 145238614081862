import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Button, Flex, Text } from "@chakra-ui/react";
import Store from "./store";
import { CentralizedCard, TextInput } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IParams {
	id: string;
}

const Create: React.FC = () => {
	const commonStrings = strings.common;
	const pageStrings = strings.profitTable.create;

	const { dialog } = useGlobalStore();
	const modal = strings.common.modal;

	const { id } = useParams<IParams>();
	const history = useHistory();

	const store = useLocalObservable(() => new Store());

	const onSuccess = () => {
		history.goBack();
	};

	const openDialog = () => {
		dialog.showDialog({
			title: modal.titleCreateProfit,
			closeOnOverlayClick: true,
			buttons: [
				{
					title: modal.button.confirm,
					onPress: () => onSubmit(),
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onSubmit = () => {
		store.createProfit(onSuccess);
		dialog.closeDialog();
	};

	return (
		<CentralizedCard
			title={{
				text: pageStrings.title,
			}}
			button={(
				<Button
					minW={{ base: "100%", md: 280 }}
					size="lg"
					mt={10}
					disabled={!!store.outdatedWallets.value}
					isLoading={store.loader.isLoading}
					onClick={openDialog}
				>
					{strings.common.buttons.confirmButton(!!id)}
				</Button>
			)}
		>
			<Flex justifyContent="center" gap={5} flexDir="column">
				<TextInput
					labelText={commonStrings.fields.returnedValue}
					labelProps={{ fontWeight: "bold" }}
					type="number"
					isDisabled={store.loader.isLoading}
					errorText={store.formShelf.field("returnedValue").error}
					{...store.formShelf.field("returnedValue")}
				/>
			</Flex>
			{!!store.outdatedWallets.value &&
				<Text
					fontSize={15}
					mt={2}
				>
					{pageStrings.message}
				</Text>}
		</CentralizedCard>
	);
};

export default observer(Create);
