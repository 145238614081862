import React from "react";
import { Store } from "./store";
import { useHistory } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { Table, TableCellWithActionButtons, CpfFilter } from "~/components";
import format from "~/resources/format";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import API from "~/resources/api";

const TableView: React.FC = () => {
	const pageStrings = strings.usersTable.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToUserDetails = (id: string) => history.push(`users/userDetails/${id}`);
	const { dialog } = useGlobalStore();

	const openDialogBlock = (user: API.User) => {
		dialog.showDialog({
			title: user.blockedAt ?  modal.button.enable :  modal.button.disable,
			closeOnOverlayClick: true,
			description: modal.descriptionBlock(user.name, user.blockedAt),
			buttons: [
				{
					title: user.blockedAt ?  modal.button.enable :  modal.button.disable,
					onPress: () => {
						store.changeUserBlockStatus(user.id, user.blockedAt);
						dialog.closeDialog();
					},
					buttonProps: { bg: user.blockedAt ? "red.500" : "green.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};


	const onDeleteUser = (id: string) => {
		store.deleteUser(id);
		dialog.closeDialog();
	};

	const openDialogDelete = (user: api.User) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(user.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteUser(user.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex flexDir="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store.paginetedListShelf.items}
				loading={store.paginetedListShelf.loader.isLoading}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<CpfFilter
						onClick={() => store.paginetedListShelf.fetchPage(0)}
						setValue={store.cpf.setValue}
						value={store.cpf.value}
						isLoading={store.loader.isLoading}
					/>
				}
				renderRow={(item, index) => (
					<Tr key={index}>
						<TableCellWithActionButtons
							onClick={() => onGoToUserDetails(item.id)}
							onDelete={() => openDialogDelete(item)}
							isBlocked={!!item.blockedAt}
							onBlock={() => openDialogBlock(item)}
						/>
						<Td>{item.name}</Td>
						<Td>{item.email}</Td>
						<Td>{item.documentNumber}</Td>
						<Td>{format.date(item.createdAt)}</Td>
					</Tr>
				)}
				emptyMessage={strings.common.noResutls}
				currentPage={store.paginetedListShelf.page}
				prevPage={store.paginetedListShelf.previousPage}
				nextPage={store.paginetedListShelf.nextPage}
				hasNextPage={store.paginetedListShelf.hasNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
