import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams, useHistory } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
	Button,
	IconButton,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import { Store } from "./store";
import { CentralizedCard, DetailsUser } from "~/components";
import strings from "~/resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IParams {
	id: string;
}

const DetailsUserRequestDelete: React.FC = () => {
	const stringButton = strings.actions;
	const string = strings.common;
	const modal = strings.common.modal;
	const { id } = useParams<IParams>();
	const { dialog } = useGlobalStore();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();
	const onGoToTheUsersList = () => history.goBack();

	const onApprove = () => {
		store.user.setValue(store.FetchModelShelf.fetchedModel.user.name);
		store.acceptDeleteUser(id);
		history.goBack();
		dialog.closeDialog();
	};
	const onRefuse = () => {
		store.user.setValue(store.FetchModelShelf.fetchedModel.user.name);
		store.refuseDeleteUser(id);
		history.goBack();
		dialog.closeDialog();
	};

	const dialogToApproveOrRefuse = (userName: string, approveOrRefuse: boolean) => {
		dialog.showDialog({
			title: approveOrRefuse ? modal.titleApprove : modal.titleRefuse,
			closeOnOverlayClick: true,
			description: approveOrRefuse ? modal.descriptionRemove(userName) : modal.descriptionCancelRequest(userName),
			buttons: [
				{
					title: modal.button.confirm,
					onPress: approveOrRefuse ? () => onApprove() : () => onRefuse(),
					buttonProps: { bg: "green.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					buttonProps: { bg: "red.500", _hover: { bgColor: "red.700" } },
				},
			],
		});
	};

	return (
		<>
			<Flex justifyContent="center" flexDirection="column" alignItems="center">
				<>
					{store.FetchModelShelf.model.value && (
						<CentralizedCard
							title={{
								text: string.detailsTitle,
								helper: (
									<Tooltip label={strings.common.edit}>
										<IconButton
											variant="icon"
											aria-label={strings.actions.back}
											size="lg"
										/>
									</Tooltip>
								),
							}}
							isLoading={store.FetchModelShelf.initialLoader.isLoading}
						>
							{store.FetchModelShelf.model.value &&
								<DetailsUser user={store.FetchModelShelf.fetchedModel.user} />}
							<Flex gap={3} justifyContent="center">
								<Button
									onClick={() => dialogToApproveOrRefuse(
										store.FetchModelShelf.fetchedModel.user.name,
										true)}
								>
									{stringButton.approve}
								</Button>
								<Button
									bgColor="red.700"
									onClick={() => dialogToApproveOrRefuse(
										store.FetchModelShelf.fetchedModel.user.name,
										false)}
								>
									{stringButton.refuse}
								</Button>
							</Flex>
						</CentralizedCard>
					)}
					<Button
						onClick={() => onGoToTheUsersList()}
						w={{
							base: "50%",
							md: "15%",
						}}
						mb={5}
						variant="outline"
						leftIcon={
							<ArrowBackIcon />
						}
					>
						{strings.actions.back}
					</Button>
				</>
			</Flex>
		</>
	);
};

export default observer(DetailsUserRequestDelete);
