import { makeAutoObservable } from "mobx";
import { FetchModelShelf, LoaderShelf } from "@startapp/mobx-utils";

import api from "~/resources/api";
import { onFetchError } from "~/resources/fetchError";
import { showSuccessToast, showErrorToast } from "~/resources/toast";
import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";

export default class Store {

	public fetchModelShelf: FetchModelShelf<api.User>;
	public loader = new LoaderShelf();

	constructor(id: string) {
		makeAutoObservable(this);
		this.fetchModelShelf = new FetchModelShelf(
			id,
			api.getUser,
			{
				fetchOnConstructor: true,
				onFetchError,
			},
		);

	}

	public approveOrRefuseUser = async(id: string, isApprove: boolean) => {
		this.loader.tryStart();
		try {
			await api.approveOrRefuseUser(id, isApprove);
			const user = await api.getUser(id);
			showSuccessToast(strings.pendingUsers.successApproveOrRefuse(user.name, isApprove));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
